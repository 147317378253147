import React, { useEffect, useState } from 'react';
import { MdFavorite } from 'react-icons/md';
import { Link, useNavigate } from 'react-router-dom';
import { Box, Step, StepLabel, Stepper, styled, SvgIcon, Typography, useMediaQuery, useTheme } from '@mui/material';

import settings from '@/constants/constants';
import { usePortalPartnerBrand } from '@/hooks/portal-partner-brand';
import { getCreditData } from '@/services/data.service';
import { Button } from '@/ui-library';

import '../../scss/paymentSuccess.scss';

const PaymentSuccessContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: '100%',
  flexGrow: 1,
  '& > div': {
    [theme.breakpoints.up('md')]: {
      width: '85%',
      height: 'auto',
      border: `1px solid ${theme.palette.grey[300]}`,
      borderRadius: '6px',
    },
    [theme.breakpoints.up('lg')]: {
      width: '70%',
    },
    [theme.breakpoints.up('xl')]: {
      width: '60%',
    },
    width: '100%',
    height: '100%',
    display: 'grid',
    gridTemplateRows: 'auto',
    gridTemplateColumns: '2fr',
    backgroundColor: theme.palette.grey[50],
    padding: '2rem',
  },
}));

const HeaderContainer = styled(Box)(({ theme }) => ({
  [theme.breakpoints.up('sm')]: {
    flexDirection: 'row-reverse',
  },
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  gridColumn: 'span 2',
  order: 0,
}));

const HeaderText = styled(Typography)(({ theme }) => ({
  fontSize: '1.86rem',
  lineHeight: '2.53rem',
  display: 'flex',
  alignItems: 'center',
  gap: '.5rem',
  fontWeight: '700',
  margin: '1.33rem 0',
  [theme.breakpoints.up('sm')]: {
    fontSize: '2.4rem',
    lineHeight: '3.07rem',
    margin: '1.06rem 0',
  },
}));

const PoweredByContainer = styled(Box)(({ theme }) => ({
  [theme.breakpoints.up('sm')]: {
    marginBottom: 0,
  },
  justifyContent: 'flex-end',
  display: 'flex',
}));

const PoweredByContent = styled(Box)`
  display: flex;
  flex-direction: column;
`;

const OrderStatusText = styled(Box)`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  grid-column: span 2;
  order: 1;

  & * {
    line-height: 24px;
    letter-spacing: 0.15px;
  }
`;

const BankTransferDetails = styled(Box)(({ theme }) => ({
  display: 'flex',
  width: 'fit-content',
  flexDirection: 'column',
  marginTop: '2.66rem',
  background: theme.palette.grey[200],
  padding: '0.8rem',
  border: `1px solid ${theme.palette.grey[300]}`,
  borderRadius: '0.4rem',
  order: 1,
}));

const OrderProgressContainer = styled(Box)(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    margin: '5.33rem 0',
  },
  margin: '2.13rem 0',
  gridColumn: 'span 2',
  order: 2,
}));

const ReferAFriendContainer = styled(Box)(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    order: 3,
  },
  display: 'flex',
  background: theme.palette.info.main,
  padding: '6px 16px',
  borderRadius: '4px',
  gridColumn: 'span 2',
  height: 'fit-content',
  order: 4,
}));

const ReferAFriendInner = styled(Box)(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    alignItems: 'center',
  },
  display: 'flex',
  padding: '8px 0px',
  alignItems: 'flex-start',
  gap: '4px',
}));

const ButtonContainer = styled(Box)(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    marginTop: '24px',
  },
  marginTop: 0,
  display: 'flex',
  justifyContent: 'space-between',
  height: 'fit-content',
  gridColumn: 'span 1',
}));

const ActionsContainerLeft = styled(ButtonContainer)(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    order: 4,
  },
  order: 3,
}));

const ActionsContainerRight = styled(ButtonContainer)`
  order: 4;
`;

const LoggedOutTextCTA = styled(Typography)`
  & a {
    font-weight: 700;
    text-decoration: underline;
  }
`;

interface Props {
  patientName?: string;
  patientEmail?: string;
  orderCode: string;
  transactionType: number;
  bankTransferReference?: string;
  loggedIn: boolean;
  onBoardKey?: string;
}

function OrderConfirmed({
  patientName,
  patientEmail,
  orderCode,
  transactionType,
  bankTransferReference,
  loggedIn,
  onBoardKey,
}: Props) {
  const navigate = useNavigate();
  const theme = useTheme();
  const isLargeView = useMediaQuery(theme.breakpoints.up('md'));
  const { Logo } = usePortalPartnerBrand();

  const [referralActive, setReferralActive] = useState(false);

  const redirectOrdersPage = () => {
    navigate('/patient/orders');
  };

  const redirectLandingPage = () => {
    navigate('/patient/refer-friend');
  };

  const redirectOrdersViaLogin = () => {
    navigate('/login/patient', { state: { from: { pathname: '/patient/orders' } } });
  };

  useEffect(() => {
    if (loggedIn) {
      getCreditData().then((res) => {
        if (res.data.patientReferralCode.patientReferralDetails.patientReferralCode?.active) {
          setReferralActive(true);
        }
      });
    }
  }, []);

  const isOnboarding = !loggedIn && onBoardKey;
  const loggedOutExistingPatient = !loggedIn && !onBoardKey;
  const isBankTransfer = transactionType === settings.transactionType.BANK;

  const progressSteps = isBankTransfer
    ? ['Order placed', 'Bank transfer received', 'Order processed', 'Order shipped']
    : ['Order placed', 'Processing order', 'Order shipped', 'Order delivered'];

  return (
    <PaymentSuccessContainer>
      <div>
        <HeaderContainer>
          <PoweredByContainer>
            <PoweredByContent>
              Powered by
              <Logo
                fillColor="currentColor"
                sx={{
                  width: '90px',
                  height: '18px',
                }}
              />
            </PoweredByContent>
          </PoweredByContainer>
          <HeaderText variant="h4" gutterBottom>
            {isBankTransfer ? (
              'Your order is pending payment.'
            ) : (
              <>
                Thank you {patientName}!<br />
                Your order is confirmed.
              </>
            )}
          </HeaderText>
        </HeaderContainer>

        <OrderStatusText>
          <Typography variant="body1" fontWeight="700" data-testid="order-code">
            Order: {orderCode}
          </Typography>
          {isOnboarding && (
            <LoggedOutTextCTA variant="body1">
              <Link
                to={{
                  pathname: '/login/patient',
                  search: `?onBoardKey=${onBoardKey}`,
                }}
              >
                Activate account
              </Link>{' '}
              to view order details
            </LoggedOutTextCTA>
          )}
          {loggedOutExistingPatient && (
            <LoggedOutTextCTA variant="body1">
              <Link to="/login/patient" data-testid="sign-in-link">
                Sign in
              </Link>{' '}
              to view order details
            </LoggedOutTextCTA>
          )}
          <Typography variant="body1" data-testid="order-placed-content">
            {isBankTransfer ? (
              <>
                Your order is placed and pending payment. Once we receive the bank transfer we will get your order
                ready. An order receipt with payment instructions via bank transfer has been sent to{' '}
                <b>{patientEmail}</b>.
              </>
            ) : (
              <>
                Your order is placed, and we're getting it ready. An order receipt has been sent to{' '}
                <b>{patientEmail}</b>
              </>
            )}
          </Typography>
        </OrderStatusText>

        {isBankTransfer && (
          <BankTransferDetails>
            <Typography variant="h6" sx={{ fontSize: '1.067rem', fontWeight: '700', marginBottom: '0.8rem' }}>
              Bank transfer details
            </Typography>
            <Typography variant="body1" paragraph data-testid="bank-details">
              Your reference number: <b>{bankTransferReference}</b>
              <br />
              BSB: <b>{settings.bankAccount.bsb}</b>
              <br />
              Account number: <b>{settings.bankAccount.accountNumber}</b>
              <br />
              Account name: <b>{settings.bankAccount.name}</b>
              <br />
            </Typography>
          </BankTransferDetails>
        )}

        <OrderProgressContainer>
          <Stepper orientation={isLargeView ? 'horizontal' : 'vertical'} activeStep={0}>
            {progressSteps.map((label, index) => (
              <Step
                key={label}
                completed={index === 0}
                sx={{
                  ...(index === 0 && {
                    '& .MuiStepLabel-root .Mui-completed svg': {
                      color: `${theme.palette.primary.main}`,
                    },
                  }),
                }}
              >
                <StepLabel>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>
        </OrderProgressContainer>

        {referralActive && (
          <ReferAFriendContainer>
            <ReferAFriendInner>
              <SvgIcon
                component={MdFavorite}
                sx={{
                  color: theme.palette.info.dark,
                  size: '22px',
                  marginTop: isLargeView ? '0' : '4px',
                }}
              />
              <Typography
                sx={{
                  marginTop: '0.25rem',
                  marginLeft: '1rem',
                }}
                data-testid="referral-banner-content"
              >
                Share your experience by referring a friend and earn $50 off your next order!
              </Typography>
            </ReferAFriendInner>
          </ReferAFriendContainer>
        )}
        <ActionsContainerLeft>
          {loggedIn && (
            <Button color="primary" onClick={redirectOrdersPage}>
              Back to Orders
            </Button>
          )}
        </ActionsContainerLeft>
        <ActionsContainerRight>
          {referralActive && (
            <Button color="primary" variant="outlined" onClick={redirectLandingPage}>
              Refer a friend
            </Button>
          )}
          {isOnboarding && (
            <Button color="primary" href={`/login/patient?onBoardKey=${onBoardKey}`}>
              Activate account
            </Button>
          )}
          {loggedOutExistingPatient && (
            <Button color="primary" onClick={redirectOrdersViaLogin} data-testid="sign-in-button">
              Sign in
            </Button>
          )}
        </ActionsContainerRight>
      </div>
    </PaymentSuccessContainer>
  );
}

export default OrderConfirmed;
