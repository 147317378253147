import { useEffect, useState } from 'react';
import { Box, styled, Typography } from '@mui/material';
import axios from 'axios';

import settings from '../../constants/constants';
import { AuthService } from '../../services/authentication.service';

import ResourceCard from './ResourceCard';
import TopBanner from './TopBanner';

interface LiaisonInfo {
  name: string;
  phone: string;
  email: string;
}

const mlInit: LiaisonInfo = {
  name: '...',
  phone: '...',
  email: '...',
};

const CardContainer = styled(Box)`
  margin-top: 1rem;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 1rem;
`;

const Container = styled(Box)(({ theme }) => ({
  margin: theme.spacing(-6, -2, 0, -2),
}));

const CustomiseBox = styled(Box)(({ theme }) => ({
  textAlign: 'center',
  padding: theme.spacing(4),
}));

const CustomTypography = styled(Typography)(({ theme }) => ({
  margin: theme.spacing(1),
}));

const { url } = settings;

function Resources() {
  const [mlInfo, setMLInfo] = useState<LiaisonInfo>(mlInit);
  const userId = AuthService.getUser()?.user?.id;
  const role = AuthService.getUser()?.user?.role_id;

  useEffect(() => {
    if (role === 2) {
      axios.get(`${url}/liaison/getByGP/${userId}`).then((resp) => {
        setMLInfo(resp.data);
      });
    }
  }, [role, userId]);

  return (
    <Container>
      <TopBanner pText="" h1Text="Resources" />
      {role === 2 && (
        <CustomiseBox>
          <Typography>Your dedicated Account Manager is available to support you:</Typography>
          <CustomTypography>N: {mlInfo.name}</CustomTypography>
          <CustomTypography>P: {mlInfo.phone}</CustomTypography>
          <CustomTypography>E: {mlInfo.email}</CustomTypography>
        </CustomiseBox>
      )}
      <CardContainer>
        <ResourceCard
          title="Research"
          body="Learn more about how medical cannabis is used to treat a range of conditions"
          link="/resources/research"
        />
        <ResourceCard
          title="Virtual Roundtable Recordings"
          body="View our library of previous virtual roundtable recordings by experienced doctors and professionals"
          link="/resources/cpd-sessions"
        />
        <ResourceCard
          title="Product Overview"
          body="Explore our full Circle product offering"
          link="/resources/products"
        />
      </CardContainer>
    </Container>
  );
}

export default Resources;
