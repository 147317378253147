import { useEffect } from 'react';
import { AlertTitle } from '@mui/material';
import { useSnackbar } from 'notistack';

import { ErrorDisplay, useErrorManagement } from '@/context/ErrorManagement';

export function ManagedErrorToasts() {
  // Since this is going to be called within a page, we set keepFromPrevious to true,
  // to prevent it from clearing out the errors and overriding the page's behaviour
  const errorManagement = useErrorManagement({
    keepFromPrevious: true,
  });

  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    const callback = (error: ErrorDisplay) => {
      enqueueSnackbar({
        variant: 'error',
        persist: error.persist,
        message: (
          <div>
            <AlertTitle>{error.title}</AlertTitle>
            <div>{error.body}</div>
          </div>
        ),
      });
    };

    errorManagement.addOnErrorAddedListener('ManagedErrorToasts', callback);
    return () => {
      errorManagement.removeOnErrorAddedListener('ManagedErrorToasts');
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return null;
}

export default ManagedErrorToasts;
