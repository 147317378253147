import React, { useCallback } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import PersonIcon from '@mui/icons-material/Person';
import { Avatar, Box, Link, Menu, MenuItem, styled, Typography } from '@mui/material';

import USER_ROLES from '@/constants/userRoles';

import sagedLogo from '../saged_v2.png';

interface AdminMenuV2Props {
  roleId: number;
  firstName: string;
  gpCode: string;
}

const Container = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  marginRight: '8rem',
  [theme.breakpoints.down('md')]: {
    flexDirection: 'column',
    alignItems: 'baseline',
  },
}));

const AvatarBox = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'roleId',
})<{ roleId: number }>(({ theme, roleId }) => ({
  display: 'flex',
  alignItems: 'center',
  cursor: 'pointer',
  [theme.breakpoints.down('md')]: {
    marginTop: roleId === USER_ROLES.pharmacist ? '2.5rem' : '0',
  },
}));

const CustomAvatar = styled(Avatar, {
  shouldForwardProp: (prop) => prop !== 'roleId',
})<{ roleId: number }>(({ theme, roleId }) => ({
  backgroundColor: theme.palette.common.white,
  marginLeft: roleId === USER_ROLES.pharmacist ? theme.spacing(0) : theme.spacing(5),
}));

const Name = styled(Typography)(({ theme }) => ({
  color: theme.palette.common.white,
  marginLeft: theme.spacing(3),
}));

const GpCode = styled(Typography)(({ theme }) => ({
  color: theme.palette.common.white,
  marginLeft: theme.spacing(2),
}));

const SagedLink = styled(Link)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: '8px',
  color: theme.palette.common.white,
  textDecoration: 'none',
  marginRight: '-4rem',
  '& img': {
    width: '35%',
    transform: 'translateY(-1px)',
  },
  '&:hover': {
    color: theme.palette.common.white,
    textDecoration: 'none',
  },
  [theme.breakpoints.down('md')]: {
    marginLeft: '1.5rem',
    marginTop: '-2.9rem',
  },
}));
function AdminMenuV2({ roleId, firstName, gpCode }: AdminMenuV2Props) {
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);

  const handleClick = useCallback((event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  }, []);

  const handleClose = useCallback(() => {
    setAnchorEl(null);
  }, []);

  const getPasswordLink = () => {
    switch (roleId) {
      case USER_ROLES.patient:
        return '/password/patient';
      case USER_ROLES.doctor:
        return '/password/doctor';
      default:
        return '/password/admin';
    }
  };
  const shouldRenderSagedLink = roleId === USER_ROLES.pharmacist;

  return (
    <Container>
      {shouldRenderSagedLink && (
        <SagedLink href="https://saged.com.au/" target="_blank" rel="noopener" underline="none">
          <span>Learn with</span>
          <img src={sagedLogo} alt="Saged" />
        </SagedLink>
      )}
      <AvatarBox onClick={handleClick} roleId={roleId}>
        <CustomAvatar roleId={roleId}>
          <PersonIcon sx={{ color: 'secondary.main', fontSize: 25 }} />
        </CustomAvatar>
        <Name variant="body1">{firstName}</Name>
        {roleId === USER_ROLES.doctor && <GpCode variant="body2">{gpCode}</GpCode>}
      </AvatarBox>
      <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleClose} disableScrollLock>
        <MenuItem component={RouterLink} to="/profile-detail">
          Profile
        </MenuItem>
        <MenuItem component={RouterLink} to={getPasswordLink()}>
          Password
        </MenuItem>
        <MenuItem component={RouterLink} to="/logout">
          Logout
        </MenuItem>
      </Menu>
    </Container>
  );
}

export default AdminMenuV2;
