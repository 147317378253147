import React from 'react';
import { createRoot } from 'react-dom/client';
import { ErrorBoundary } from 'react-error-boundary';
import axios from 'axios';

import { LaunchDarklyProvider } from './components/featureFlags/FFLoadingStateProvider';
import withFeatureFlagProvider from './utils/withFeatureFlagProvider';
import App from './App';
import ErrorFallback from './ErrorFallback';

axios.defaults.headers.post['Content-Type'] = 'application/json';

function AppWrapper() {
  return (
    <ErrorBoundary FallbackComponent={ErrorFallback}>
      <App />
    </ErrorBoundary>
  );
}

const LDApp = withFeatureFlagProvider(() => (
  <LaunchDarklyProvider>
    <AppWrapper />
  </LaunchDarklyProvider>
));

const root = createRoot(document.getElementById('root')!);

root.render(<LDApp />);
