import { useMemo } from 'react';

import { ProductTileProps } from '@/components/products/components/ProductTile/ProductTile';
import { ProductFormulation } from '@/types';
import { getWhiteLabelProductImageUrl } from '@/utils/getProductImageUrl';

import { useFormulationFilter } from '../products/use-formulation-filter';

import { ProductDto } from './api';
import useQueryPrescriptions from './useQueryPrescriptions';

const getProductCardProps = (product: ProductDto): ProductTileProps => ({
  name: product.name,
  imageUrl: getWhiteLabelProductImageUrl({ whiteLabelImageUrl: product.formulation?.whiteLabelImageUrl }),
  cbdStrength: product.cbdStrengthForDisplay ?? undefined,
  thcStrength: product.thcStrengthForDisplay ?? undefined,
  formulation: product.formulation?.name,
  strainName: product.strain?.name,
  strainKey: product.strain?.shopify_key,
  isConcession: product.isConcession ?? undefined,
});

export function useMedicineInformation() {
  const { isLoading, isError, isSuccess, data: prescriptionsData } = useQueryPrescriptions();

  const treatmentData = useMemo(() => {
    const productPerId = new Map(
      (prescriptionsData ?? []).flatMap((prescription) =>
        prescription.prescriptionProducts.map((prescriptionProduct) => [
          prescriptionProduct.productId,
          prescriptionProduct.product,
        ]),
      ),
    );

    const uniqueProducts = Array.from(productPerId.values()).filter((p): p is NonNullable<ProductDto> => p !== null);
    const uniqueFormulations = Array.from(new Set(uniqueProducts.map((product) => product.formulation?.name))).filter(
      (f): f is NonNullable<ProductFormulation> => Boolean(f),
    );

    return {
      products: uniqueProducts,
      formulations: uniqueFormulations,
    };
  }, [prescriptionsData]);

  const { filterFormulation, getFormulationFilterProps } = useFormulationFilter({
    availableIds: treatmentData.formulations,
  });

  const filteredProducts = treatmentData.products.filter((product) => filterFormulation(product.formulation?.name));

  return {
    query: { isLoading, isError, isSuccess },
    treatmentData,
    filteredProducts,
    getProductCardProps,
    getFormulationFilterProps,
  };
}

export default useMedicineInformation;
