import React from 'react';
import { Typography } from '@mui/material';

import PageContainer from '@/components/common/PageContainer';
import ManagedErrorToasts from '@/components/error/ManagedErrorToasts';
import PharmacyUpdateHistoryTable from '@/components/pharmacy/PharmacyUpdateHistoryTable';

function PharmacyUpdateHistory() {
  return (
    <PageContainer>
      <ManagedErrorToasts />
      <Typography component="h2" variant="h5">
        Pharmacy Update History
      </Typography>
      <PharmacyUpdateHistoryTable />
    </PageContainer>
  );
}

export default PharmacyUpdateHistory;
