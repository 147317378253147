import { useMutation } from 'react-query';
import { useLDClient } from 'launchdarkly-react-client-sdk';

import { AuthService } from '@/services/authentication.service';
import { changeUser } from '@/services/braze.service';
import { UserResponse } from '@/types/user.types';
import identifyUserForLaunchDarkly from '@/utils/identifyUserForLaunchDarkly';
import { Logger } from '@/utils/logger';

interface PharmacistLoginFormData {
  email: string;
  password: string;
  remember: boolean;
  loginType: string;
}

const logger = new Logger('PharmacistSignInForm');

function usePharmacistLogin() {
  const ldClient = useLDClient();

  const mutation = useMutation(
    async (data: PharmacistLoginFormData) => {
      const userResponse: UserResponse = await AuthService.login(
        data.email.toLowerCase().trim(),
        data.password,
        data.remember,
        data.loginType,
      );

      if (ldClient && userResponse?.user) {
        await identifyUserForLaunchDarkly(ldClient, userResponse.user);
        changeUser(userResponse.user.email);
      }
    },
    {
      onError: (err) => {
        logger.error(err);
      },
      onSuccess: () => {
        window.location.replace('/pharmacist/orders');
      },
    },
  );

  return {
    login: mutation.mutate,
    isLoading: mutation.isLoading,
  };
}

export default usePharmacistLogin;
