import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { Typography } from '@mui/material';
import Button from '../../components/common/button';
import CircleLoader from '../../components/common/circleLoader';
import { getOrderStatus } from '../../components/patient/patientOrderTile';
import settings from '../../constants/constants';
import { FF_ENABLE_PATIENT_ORDERS_WHITE_LABEL_IMAGES } from '@/constants/featureFlags';
import useFeatureFlags from '@/hooks/useFeatureFlags';
import { PatientService } from '@/services/patient.service';
import Mixpanel from '../../services/mixpanel.service';
import getProductImageUrl, { getWhiteLabelProductImageUrl } from '@/utils/getProductImageUrl';
import MontuBrandWrapper from '@/components/common/MontuBrandWrapper';
import { datadogRum } from '@datadog/browser-rum';
import ProductWhiteLabelImage from '@/components/products/productDetailsV2/ProductWhiteLabelImage';
import { useNavigate, useParams } from 'react-router-dom';

const OrderDetails = (props) => {
  const params = useParams();
  const navigate = useNavigate();
  const [data, setData] = useState({
    orderId: params.orderId,
    orders: [],
    loading: true,
    products: [],
  });

  const getOrderProducts = (orders, products = []) => {
    for (const eachOrder of orders) {
      for (const eachOrderProduct of eachOrder.OrderProducts) {
        products.push(eachOrderProduct.product_id);
      }
    }
    return [...new Set(products)];
  };

  useEffect(() => {
    PatientService.getPatientOrderDetails(params.orderId).then((resp) => {
      if (resp.data && resp.data.length <= 0) {
        toast("Order Doesn't Exist", { toastId: 'no-order', type: 'error' });
        navigate('/patient/orders');
        return;
      }
      setData({
        orderId: params.orderId,
        orders: resp.data,
        loading: false,
        products: getOrderProducts(resp.data),
      });
    });
  }, [params.orderId]);

  if (!data.loading && data.orders.length === 0) {
    // No Orders found for the request.
    navigate('/*');
  }

  const orderShippedStatusList = [settings.orderStatus.SHIPPED, settings.orderStatus.COMPLETED];

  const isDischarge = Boolean(data?.orders[0]?.Patient?.is_discharge);

  const { flags } = useFeatureFlags();

  const getTrackingUrl = (order) => {
    switch (order.shipment_carrier) {
      case settings.shipmentCarrier.ausPost:
        return `${settings.ausPostTrackingUrl}${order.shipment_tracking_id}`;
      case settings.shipmentCarrier.shippit:
        return `${settings.shippitTrackingUrl}${order.shipment_tracking_id}`;
      default:
        return '#';
    }
  };

  return (
    <MontuBrandWrapper header="Order Details">
      <div className="w-80 mx-auto patient-orders-page mt-3" data-dd-privacy="allow">
        {data.loading ? (
          <CircleLoader />
        ) : (
          <div>
            <h5>
              Order ID<span className="mob-d-none">:</span>{' '}
              {data.orders.length > 0 && data.orders[0].order_code.split('-')[0]}
            </h5>
          </div>
        )}
        <div className="mt-2 d-flex justify-content-center" style={{ flexWrap: 'wrap' }}>
          {data.orders.map((order, orderIdx) => (
            <div key={`order-detail-shipment-${orderIdx}`} className="patient-order-tile fit-content m-3 p-3">
              <div className="row">
                <div className={order.shipment_tracking_id ? 'col-xs-12 col-sm-12 col-md-6 col-lg-6' : 'col-12'}>
                  <p className="order-code">
                    {order.order_code + '  '}
                    <Typography variant="string">
                      {order.delivered &&
                        'Delivered on ' + moment(new Date(order.delivered_date)).format('MMMM DD, YYYY')}
                      {!order.delivered &&
                        orderShippedStatusList.includes(order.order_status_id) &&
                        'Shipped at ' + moment(order.dispensed_date || order.shipped_date).format('MMMM DD, YYYY')}
                      {!orderShippedStatusList.includes(order.order_status_id) && getOrderStatus(order)}
                    </Typography>
                  </p>
                </div>
                {order.shipment_tracking_id && (
                  <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                    <p className="float-xs-left float-sm-right float-md-right float-lg-right">
                      <span className="mr-1">Tracking No:</span>
                      <a
                        href={getTrackingUrl(order)}
                        className="tracking-id"
                        target={'_blank'}
                        rel="noopener noreferrer"
                      >
                        {order.shipment_tracking_id}
                      </a>
                    </p>
                  </div>
                )}
              </div>

              <div className="col-mob-row justify-content-center" style={{ flexWrap: 'wrap' }}>
                {order.OrderProducts.map((prdt, prdtIdx) => {
                  const imageUrl = getWhiteLabelProductImageUrl({
                    whiteLabelImageUrl: prdt.Product.ProductFormulation?.whitelabel_image_url,
                  });
                  return (
                    <div
                      key={`order-detail-${orderIdx}-product-${prdtIdx}`}
                      className="m-3 order-detail-tile"
                      onClick={() => {
                        navigate(`/product-detail/${prdt.product_id}`);
                      }}
                    >
                      <div className="text-center">
                        {flags[FF_ENABLE_PATIENT_ORDERS_WHITE_LABEL_IMAGES] ? (
                          <ProductWhiteLabelImage whiteLabelImageUrl={imageUrl} />
                        ) : (
                          <img
                            src={getProductImageUrl(prdt.Product.image_url, prdt.Product.ProductImages)}
                            className="img-fluid"
                          />
                        )}
                      </div>
                      <div className="ml-2 light-font">
                        <p className="mb-1">{prdt.Product.name}</p>
                      </div>
                      <div className="ml-2 light-font">
                        <p className="mb-0">Quantity: {prdt.quantity}</p>
                        <p>Cost: ${isNaN(prdt.price) ? prdt.Product.price * prdt.quantity : prdt.price}</p>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          ))}
        </div>
        {!data.loading && (
          <div className="d-flex justify-content-center placeOrderBtn">
            <button className="btn btn-cancel" onClick={() => navigate('/patient/orders')}>
              BACK
            </button>
            <Button
              {...(!data.loading && { type: 'submit' })}
              text="REORDER"
              className="btn btn-sec ml-5"
              loading={data.loading}
              onClick={() => {
                datadogRum.addAction('order-reorder-button-click', {
                  products: data.products,
                });
                Mixpanel('Reorder from Orders page pressed');
                return navigate(
                  {
                    pathname: '/patient/refill',
                  },
                  {
                    state: { preLoadProducts: data.products },
                  },
                );
              }}
              disabled={isDischarge}
            />
          </div>
        )}
      </div>
    </MontuBrandWrapper>
  );
};

export default OrderDetails;
