import React, { useCallback } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Box, Card, CardContent, Checkbox, Link, styled, Typography } from '@mui/material';
import moment from 'moment';

import { ProductFormulation, ProductStrain, ProductStrainKey } from '@/types';
import { ClickableTooltip } from '@/ui-library/tooltip/ClickableTooltip';

import ConcessionChip from '../components/ConcessionChip/ConcessionChip';
import FormulationChip from '../components/FormulationChip/FormulationChip';
import KeyValueChip from '../components/KeyValueChip/KeyValueChip';
import StrainChip from '../components/StrainChip/StrainChip';

import RefillDoseOption from './RefillDoseOption';
import { formatPrice } from './util';

export interface ProductInfo {
  productName: string;
  id: number;
  supplier: string;
  formulation: ProductFormulation;
  strainName: ProductStrain | null;
  strainKey: ProductStrainKey | null;
  thcForDisplay: string;
  cbdForDisplay: string;
  price: number;
  size: string | null;
  isConcession: boolean;
}

export interface PrescriptionInfo {
  maxRepeats: number;
  expiry: string;
  maxQuantity: number;
  interval: number;
  productSchedule?: {
    id: number;
    name: string;
  } | null;
}

export interface ProductRefillCardProps {
  product: ProductInfo;
  prescription: PrescriptionInfo;
  quantity: number;
  setQuantity: (newQty: number) => void;
}

const StyledCard = styled(Card)(({ theme }) => ({
  border: '2px solid',
  borderRadius: theme.shape.borderRadius,
  borderColor: theme.palette.action.disabledBackground,
  marginBottom: theme.spacing(3),
}));

const TooltipBreak = styled(Box)`
  margin-top: 1rem;
`;

const CardLayout = styled(CardContent)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(2),
  padding: theme.spacing(4),
  '&:last-child': {
    paddingBottom: theme.spacing(4),
  },
}));

const CardHeader = styled(Box)`
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
`;

const CardHeaderTitles = styled(Box)`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  flex: 1;
  align-items: flex-start;
`;

const CardHeaderControls = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const CardChipContainer = styled(Box)`
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
  flex-wrap: wrap;
`;

const CardBodyScriptDetails = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  marginBottom: theme.spacing(2),
}));

const DosageOptions = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(2),
}));

export function ProductRefillCard(props: ProductRefillCardProps) {
  const { quantity, setQuantity, prescription, product } = props;

  const onCheckboxChanged = useCallback((_: React.ChangeEvent, checked: boolean) => {
    if (checked) {
      setQuantity(prescription.maxQuantity);
    } else {
      setQuantity(0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Generate an array of integers counting down from maxQuantity to 1;
  const displayedDosageAmounts = new Array(prescription.maxQuantity)
    .fill('')
    .map((_, index) => index + 1)
    .reverse();

  return (
    <StyledCard elevation={0}>
      <CardLayout>
        <CardHeader>
          <CardHeaderTitles>
            <Link
              variant="subtitle2"
              component={RouterLink}
              to={`/customer/product-detail/${product.id}`}
              sx={{ fontWeight: 600 }}
            >
              {product.productName}
            </Link>
            <Typography variant="body2">{product.supplier}</Typography>
            <Typography variant="body2">
              {product.size ? `${product.size} | ` : ''}RRP&nbsp;
              <Typography variant="body2" component="span" sx={{ fontWeight: 600 }}>
                {formatPrice(product.price)}
              </Typography>
            </Typography>
          </CardHeaderTitles>
          <CardHeaderControls>
            <Checkbox checked={quantity > 0} onChange={onCheckboxChanged} aria-label={`Order ${product.productName}`} />
          </CardHeaderControls>
        </CardHeader>
        {quantity <= 0 ? null : (
          <>
            <CardChipContainer>
              <FormulationChip formulation={product.formulation} />
              {Boolean(product.strainName) && Boolean(product.strainKey) && (
                <StrainChip
                  strainName={product.strainName as ProductStrain}
                  strainKey={product.strainKey as ProductStrainKey}
                />
              )}
              {Boolean(product.isConcession) && <ConcessionChip />}
            </CardChipContainer>
            <CardChipContainer>
              {Boolean(product.thcForDisplay) && <KeyValueChip name="THC:" value={product.thcForDisplay} />}
              {Boolean(product.cbdForDisplay) && <KeyValueChip name="CBD:" value={product.cbdForDisplay} />}
            </CardChipContainer>
            <Box>
              <CardBodyScriptDetails>
                <ClickableTooltip
                  arrow
                  title={
                    <Typography data-testid="repeats-tooltip">
                      <Typography component="span" variant="caption">
                        {`You can only re-order a repeat prescription for your medication after a ${prescription.interval}-day waiting period to prevent over-ordering.`}
                      </Typography>
                      <TooltipBreak>
                        <Typography component="span" variant="caption">
                          <strong>Repeats</strong>: Each repeat contains {prescription.maxQuantity} units
                        </Typography>
                      </TooltipBreak>
                    </Typography>
                  }
                >
                  <Typography data-testid="repeats-tag" variant="body2">
                    <u>Repeats:</u> <strong>{prescription.maxRepeats}</strong>
                  </Typography>
                </ClickableTooltip>
                {prescription.expiry && (
                  <Typography variant="body2">
                    Script expiry: <strong>{moment(prescription.expiry).format('MMM DD, YYYY')}</strong>
                  </Typography>
                )}
              </CardBodyScriptDetails>
              <DosageOptions>
                {displayedDosageAmounts.map((amount) => (
                  <RefillDoseOption
                    key={`${amount}/${prescription.maxQuantity}`}
                    quantity={amount}
                    setQuantity={setQuantity}
                    maxQuantity={prescription.maxQuantity}
                    prescriptionPeriodDays={14}
                    pricePerUnit={product.price}
                    isSelected={amount === quantity}
                  />
                ))}
              </DosageOptions>
            </Box>
          </>
        )}
      </CardLayout>
    </StyledCard>
  );
}

export default ProductRefillCard;
