/* eslint-disable no-undefined */
import React, { Component } from 'react';
import Select from 'react-select';
import { toast } from 'react-toastify';
import SimpleReactValidator from '@crystaldelta/simple-react-validator';
import CircleLoader from '../common/circleLoader';
import FormInput from '../common/FormInput';
import Button from '../common/button';
import { AuthService } from '../../services/authentication.service';
import { OrderService } from '../../services/order.service';
import { getCountries, getStates } from '../../services/data.service';
import { Logger } from '@/utils/logger';
import { validateParcelLocker } from '@/pages/profile/utils';
import withRouter from '@/utils/withRouter';

const logger = new Logger('patientOnboard');
export class patientOnboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      fetched: this.props.params.id ? true : false,
      content: this.props.params.id === undefined ? true : false,
      id: this.props.params.id,
      firstName: '',
      lastName: '',
      email: '',
      phone: '',
      address: '',
      sub_address: '',
      country: 13,
      state: '',
      city: '',
      defaultState: { value: '', label: 'Select State' },
      defaultCountry: { value: 'australia', label: 'Australia' },
      postal_code: '',
      general_practitioner_id: this.props.params.gpId,
      countries: [],
      states: [],
      sas: null,
      downloading: false,
      visibility: '',
      newPharmacyName: '',
      newPharmacyAddress: '',
      pharmacy_name: null,
      pharmacy: null,
      pharmacies: [{ value: 'Choose Pharmacy', label: 'Choose Pharmacy' }],
    };
    //copy for state
    this.initialState = this.state;
    this.validator = new SimpleReactValidator();
  }
  async componentDidMount() {
    document.title = 'Onboard Patient - Montu Group | Making Medical Cannabis Accessible';
    const { country } = this.state;
    const countryList = await getCountries();
    if (countryList) {
      this.setState({
        countries: countryList,
        content: false,
      });
    }
    const stateList = await getStates(country);
    if (stateList) {
      this.setState({
        states: stateList,
      });
    }
    OrderService.getPharmacies().then((pharmacies) => {
      let pharList = pharmacies.data.map((pharmacy) => {
        let split_address = pharmacy.address.split('$$$$');
        let address_first = split_address[0];
        return {
          label: pharmacy.name + ' | ' + address_first + ' | ' + pharmacy.city + ' | ' + pharmacy.zip_code,
          value: pharmacy.id,
        };
      });
      this.setState({ pharmacies: pharList });
    });
  }
  handleUserInput = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    this.setState({
      [name]: value,
      pharmacy: null,
      pharmacy_name: 'select...',
    });

    if (name === 'address' && !validateParcelLocker(value)) {
      this.validator.showMessageFor('address');
      this.setState({
        customAddressError: 'Parcel lockers are not allowed. Please enter a residential or PO Box address.',
      });
    } else {
      this.setState({ customAddressError: '' });
    }
  };
  handleClicked = (e) => {
    e.preventDefault();
    this.setState({ isClicked: true, isremove: true, none: '', visibility: 'd-none' });
  };
  handleClick = () => {
    this.setState({ isClicked: false, newPharmacyName: null, newPharmacyAddress: null });
  };
  handleCountry = async (e) => {
    this.setState({
      country: e.value,
    });
    const value = e.value;
    const stateList = await getStates(value);
    this.setState({ states: stateList });
  };
  handleState = async (e) => {
    this.setState({
      state: e.value,
    });
  };
  handlePharmacy = (e) => {
    this.setState({
      pharmacy: e.value,
      pharmacy_name: e.label,
      isClicked: false,
      newPharmacyName: '',
      newPharmacyAddress: '',
    });
  };
  handlePharmChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    this.setState({
      [name]: value,
      pharmacy: null,
      pharmacy_name: 'select...',
    });
  };
  onSubmission = async (e) => {
    e.preventDefault();
    if (!this.validator.allValid()) {
      this.validator.showMessages();
      this.forceUpdate();
      return;
    }
    let sub_address = this.state.sub_address ? this.state.sub_address : '';
    this.setState({ loading: true });
    let user;
    try {
      user = await AuthService.patient(
        this.state.firstName,
        this.state.lastName,
        this.state.email.trim(),
        this.state.phone,
        this.state.address + '$$$$' + sub_address,
        this.state.city,
        this.state.state,
        this.state.country,
        this.state.postal_code,
        this.state.general_practitioner_id,
      );
    } catch (err) {
      toast.error('Email Id already exist, Patient details are not added');
      logger.error('Email Id already exist, Patient details are not added', err);

      this.setState({
        loading: false,
      });
      return 'Error';
    }

    if (user) {
      this.setState({ submitted: true });
      toast.success('Patient successfully added!', {});
      this.setState({
        loading: false,
      });
      this.props.navigate(`/order-patient/${JSON.parse(user.data.success.id)}`);
    } else {
      toast.error('Patient details are not added');
      logger.error('Patient details are not added');
      this.setState({
        loading: false,
      });
      return 'Error';
    }
  };

  render() {
    const {
      loading,
      fetched,
      content,
      firstName,
      lastName,
      email,
      phone,
      address,
      sub_address,
      country,
      defaultCountry,
      countries,
      state,
      postal_code,
      city,
      downloading,
      states,
      defaultState,
      newPharmacyAddress,
      newPharmacyName,
      pharmacy,
      pharmacy_name,
      pharmacies,
    } = this.state;

    let CountryOp = countries.map((country) => {
      return {
        label: country.name,
        value: country.id,
      };
    });
    let StateOp = states.map((state) => {
      return {
        label: state.name,
        value: state.id,
      };
    });
    return (
      <div className="container">
        <h3 id="title">Onboarding Form</h3>
        <h5 className="p-2 ml-3">Patient Details</h5>
        {(this.props.params.id !== undefined ? !fetched : !content) ? (
          <form>
            <div className="col-md-12">
              <div className="form-row">
                <FormInput
                  type="text"
                  name="firstName"
                  label="First Name"
                  className="col-md-6"
                  value={firstName}
                  onChange={this.handleUserInput}
                  error={this.validator.message('first name', firstName, 'required')}
                />
                <FormInput
                  type="text"
                  name="lastName"
                  label="Last Name"
                  className="col-md-6"
                  value={lastName}
                  onChange={this.handleUserInput}
                  error={this.validator.message('last name', lastName, 'required')}
                />
              </div>
            </div>
            <div className="col-md-12">
              <div className="form-row">
                <FormInput
                  type="email"
                  name="email"
                  label="Patient Email"
                  value={email}
                  className="col-md-6"
                  onChange={this.handleUserInput}
                  error={this.validator.message('email', email, 'required|email')}
                />
                <FormInput
                  type="text"
                  name="phone"
                  label="Patient Phone"
                  placeholder="eg.0343244556"
                  value={phone}
                  className="col-md-6"
                  onChange={this.handleUserInput}
                  error={this.validator.message('phone-number', phone, 'required')}
                />
              </div>
            </div>
            <div className="col-md-12">
              <div className="form-group ">
                <FormInput
                  type="text"
                  name="address"
                  label={'Patient Address'}
                  value={address}
                  placeholder=""
                  info="Street Address"
                  onChange={this.handleUserInput}
                  error={this.validator.message('address', address, 'required')}
                />
                {this.state.customAddressError && <div className="text-danger">{this.state.customAddressError}</div>}
              </div>
              <div className="form-group ">
                <FormInput
                  type="text"
                  name="sub_address"
                  value={sub_address}
                  info="Address Line 2"
                  onChange={this.handleUserInput}
                />
              </div>
              <div className="form-row">
                <div className="form-group col-md-6 my-auto">
                  <FormInput
                    type="text"
                    name="city"
                    placeholder="City"
                    value={city}
                    info="City"
                    onChange={this.handleUserInput}
                    error={this.validator.message('city', city, 'required')}
                  />
                </div>
                <div className="form-group col-md-6 my-auto">
                  <Select
                    name="state"
                    className={`${this.validator.message('State', state, 'required') ? 'rounded dropdown-border' : ''}`}
                    defaultValue={defaultState}
                    onChange={this.handleState}
                    isSearchable={true}
                    placeholder="Select State"
                    options={StateOp}
                  />
                  {this.validator.message('State', state, 'required')}
                  <small className="form-text text-muted ml-1">State / Province / Region</small>
                </div>
              </div>
              <div className="form-row">
                <div className="form-group col-md-6 my-auto">
                  <FormInput
                    type="text"
                    name="postal_code"
                    placeholder="Postcode"
                    value={postal_code}
                    info="PostCode"
                    onChange={this.handleUserInput}
                    error={this.validator.message('zip-code', postal_code, 'required')}
                  />
                </div>
                <div className="form-group col-md-6 my-auto">
                  <Select
                    name="country"
                    defaultValue={defaultCountry}
                    onChange={this.handleCountry}
                    isSearchable={true}
                    placeholder="Select Country"
                    options={CountryOp}
                  />
                  {this.validator.message('Country', country, 'required')}
                  <small className="form-text text-muted ml-1">Country</small>
                </div>
                {this.props.params.id ? (
                  <div className="form-group p-1 col-md-12 pr-1">
                    <label>Trusted Partner Pharmacies (Type to search)</label>
                    <Select
                      value={{ label: pharmacy_name, value: pharmacy }}
                      name="pharmacy"
                      onChange={this.handlePharmacy}
                      isSearchable={true}
                      options={pharmacies}
                    />
                  </div>
                ) : (
                  ''
                )}
              </div>
              {this.props.params.id ? (
                <div className="pt-2">
                  {!this.state.isClicked && !newPharmacyName && !newPharmacyAddress ? (
                    <React.Fragment>
                      <h5 className="add-option mb-0" onClick={this.handleClicked}>
                        + Add Patient's Preferred Pharmacy
                      </h5>
                      <small className="pl-4 form-text text-primary">
                        (if not currently part of our trusted network)
                      </small>
                    </React.Fragment>
                  ) : (
                    <div>
                      <Button
                        text="x"
                        type="button"
                        className="btn btn-primary btn-md mr-2 float-right"
                        onClick={this.handleClick}
                      />
                      <h5 className="mx-auto pl-2" id="hintHeader">
                        Preferred Pharmacy details
                      </h5>
                      <div className="row">
                        <div className="col-md-12">
                          <div className="form-row form-group">
                            <FormInput
                              type="text"
                              className="col-md-6"
                              name="newPharmacyName"
                              label="Preferred Pharmacy Name"
                              defaultValue={newPharmacyName}
                              onChange={this.handleUserInput}
                            />
                            <FormInput
                              type="text"
                              className="col-md-6"
                              name="newPharmacyAddress"
                              label="Preferred Pharmacy Address"
                              defaultValue={newPharmacyAddress}
                              onChange={this.handleUserInput}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              ) : (
                ''
              )}

              {this.props.params.id ? (
                <React.Fragment>
                  <p className="mt-3">Approvals </p>
                  <div className="mb-1 d-flex flex-wrap d-inline-block">
                    <div className="m-1 p-2 sas-border">
                      <p className="text-gray">Approval Pending</p>
                    </div>
                  </div>
                  <React.Fragment>
                    {downloading ? (
                      <small className="text-muted">Please wait while the file is downloading...</small>
                    ) : (
                      <small className="text-muted">Click the links to download the approval file</small>
                    )}
                  </React.Fragment>
                </React.Fragment>
              ) : null}
              <Button
                text="Prescription Details"
                type="submit"
                loading={loading}
                className="btn btn-primary btn-md ml-3 float-right"
                onClick={this.onSubmission}
              />
            </div>
          </form>
        ) : (
          <CircleLoader />
        )}
      </div>
    );
  }
}
export default withRouter(patientOnboard);
