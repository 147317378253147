import { mapToProductRefillCardPrescription } from '@/pages/umeds/order-medication/util';
import { ProductFormulations } from '@/types';

import { OrderProduct, PatientRefillResponse, PrescribedProduct } from './types';

/** @deprecated - This should come from the backend */
export const getProductFilterTypeFromFormulationId = (id: number) => Object.values(ProductFormulations)[id - 1];

/** @deprecated - Non prescribable products should be derived differently */
export const getDeviceFormulationId = (): number => 5;

export const FORMULATION_ID_DEVICE = getDeviceFormulationId(); // Devices (e.g. vapes)

function sortByOrderProducts(orderProducts: OrderProduct[]) {
  return (a: PrescribedProduct, b: PrescribedProduct) => {
    const productBSortWeight = orderProducts.some((op) => op.id === b.id) ? 1 : 0;
    const productASortWeight = orderProducts.some((op) => op.id === a.id) ? 1 : 0;
    return productBSortWeight - productASortWeight || a.id - b.id;
  };
}

const isProductOutOfStock = (product: PrescribedProduct) =>
  Boolean(product.is_out_of_stock || product.reasoning_toggle);

const isProductDiscontinued = (product: PrescribedProduct) => Boolean(product.is_generative_scripting);

const canPrefillProduct = (product: PrescribedProduct | null | undefined) =>
  Boolean(
    product && !(product.remaining_units === 0 || isProductOutOfStock(product) || isProductDiscontinued(product)),
  );

/**
 * Transforms the products array into three lists: non device prescription products, device prescription products, device non prescription products.
 * Each of these lists is then also also sorted
 * 1. Non device prescription products are sorted by all products found in orderProducts first, then products not found in orderProducts, then products that are interval locked and lastly products that are out of stock. Any product with no remaining units or where the prescription is expired is removed from this list.
 * 2. Device prescription products are sorted by all products found in orderProducts first, then products not found in orderProducts. This list does not include all devices avaiable to the patient only those that were prescribed.
 * 3. Device non prescription products are sorted by all products found in orderProducts first, then products not found in orderProducts. All out of stock products in this list are removed.
 * All three lists are then concatenated together and returned, in the order of non device prescription products, device prescription products, device non prescription products.
 * Diagram explaining this logic https://montugroup.atlassian.net/wiki/spaces/Eng/pages/191397901/Logic+for+displaying+products+on+V3
 */
export const transformProductList = (products: PrescribedProduct[], orderProducts: OrderProduct[]) => {
  // All non device prescription products sorted by those that are in orderProducts first
  const nonDevicePrescriptionProducts = products
    .filter(
      (product) =>
        product.formulation_id !== FORMULATION_ID_DEVICE && product.remaining_units > 0 && product.is_expired === false,
    )
    .sort(sortByOrderProducts(orderProducts))
    .sort((a, b) => (a.isProductLocked ? 1 : 0) - (b.isProductLocked ? 1 : 0))
    .sort((a, b) => (isProductOutOfStock(a) ? 1 : 0) - (isProductOutOfStock(b) ? 1 : 0));

  // All device prescription products
  const devicePrescriptionProducts = products
    .filter((product) => product.formulation_id === FORMULATION_ID_DEVICE && !!product.prescription_id)
    .sort(sortByOrderProducts(orderProducts))
    .sort((a, b) => (b.campaign_discount_price ? 1 : 0) - (a.campaign_discount_price ? 1 : 0));

  // All device non prescription products
  const deviceNonPrescriptionProducts = products
    .filter(
      (product) =>
        product.formulation_id === FORMULATION_ID_DEVICE && !product.prescription_id && !isProductOutOfStock(product),
    )
    .sort(sortByOrderProducts(orderProducts))
    .sort((a, b) => (b.campaign_discount_price ? 1 : 0) - (a.campaign_discount_price ? 1 : 0));

  return [...nonDevicePrescriptionProducts, ...devicePrescriptionProducts, ...deviceNonPrescriptionProducts];
};

const calculateInitialQuantity = (orderProduct: OrderProduct, maxQuantity: number, isDevice = false) =>
  // For devices, default to one.
  // For medication, the initial quantity should be the max the patient allowed
  // If their remaining quantity from a previous script is somehow more than their max from a new script, pick the lowest option.
  isDevice ? 1 : Math.min(orderProduct.quantity, maxQuantity);

export const deriveMaxAllowedQuantity = (availableProduct: PrescribedProduct) => {
  if (availableProduct.formulation_id === FORMULATION_ID_DEVICE) {
    return 10;
  }

  const remainingUnits = Math.max(availableProduct.remaining_units, 0);

  return Math.min(remainingUnits, availableProduct.quantity_base_order);
};

/**
 Determine the quantity of each product to populate the cart with on page load.
*/
export const generateInitialQuantities = (orderData: PatientRefillResponse, preLoadProducts: number[]) => {
  const productQuantityMap: Map<number, number> = new Map([]); // ProductID => Quantity

  const sortedProducts = transformProductList(orderData.products, orderData.orderProducts || []);

  // If a list of products has been explicitly provided, use those.
  // Otherwise, fallback to using Order associated with this Refill session.
  if (preLoadProducts && preLoadProducts.length > 0) {
    preLoadProducts.forEach((productId) => {
      const product = sortedProducts.find((x) => x.id === productId);
      const canPrefill = product ? canPrefillProduct(product) : false;
      if (canPrefill && product) {
        const prescription = mapToProductRefillCardPrescription(product);
        const maxQuantity = product.formulation_id === FORMULATION_ID_DEVICE ? 1 : prescription.maxQuantity;
        productQuantityMap.set(productId, maxQuantity);
      }
    });
  } else {
    orderData.orderProducts.forEach((orderProduct) => {
      const product = sortedProducts.find((prod) => prod.id === orderProduct.id);
      const canPopulate = product ? canPrefillProduct(product) : false;
      if (canPopulate) {
        const isDevice = product?.formulation_id === FORMULATION_ID_DEVICE;
        const quantity = calculateInitialQuantity(orderProduct, deriveMaxAllowedQuantity(product!), isDevice);
        productQuantityMap.set(orderProduct.id, quantity);
      }
    });
  }

  return productQuantityMap;
};

// eslint-disable-next-line no-undefined
export const nullToUndefined = (value: any) => (value === null ? undefined : value);

export const remapResponseRemainingUnits = (responseData: object): PatientRefillResponse => {
  let products: PrescribedProduct[] = [];

  if (typeof responseData === 'object' && 'products' in responseData && Array.isArray(responseData.products)) {
    products = (responseData.products as unknown[]).map((product) => {
      let remainingUnits = 0;
      if (
        product !== null &&
        typeof product === 'object' &&
        'remainingUnits' in product &&
        typeof product.remainingUnits === 'number'
      ) {
        remainingUnits = product.remainingUnits;
      }

      if (
        product !== null &&
        typeof product === 'object' &&
        'remaining_units' in product &&
        typeof product.remaining_units === 'number'
      ) {
        remainingUnits = product.remaining_units;
      }

      return {
        ...(product as PrescribedProduct),
        remaining_units: remainingUnits,
      };
    });
  }

  return {
    ...responseData,
    products,
  } as PatientRefillResponse;
};

// These methods are exported purely for testing; Do not reference directly in production code.
// eslint-disable-next-line no-underscore-dangle
export const _testing = {
  isProductOutOfStock,
  canPrefillProduct,
  deriveMaxAllowedQuantity,
  calculateInitialQuantity,
};
