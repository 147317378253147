import { useQuery } from 'react-query';
import { ConsultationResponseDto } from '@montugroup/pms-api-contracts';

import { getTsPatientPortalClient } from '@/api/patient-portal.client';

interface UseConsultationsParams {
  when: 'completed' | 'upcoming';
  page?: {
    before?: string;
    after?: string;
    size: number;
  };
}

const client = getTsPatientPortalClient();

const useGetConsultations = ({ when, page }: UseConsultationsParams) => {
  const { data, isLoading, error, isError, isSuccess, refetch } = useQuery({
    queryKey: ['consultations', when, page],
    queryFn: async () => {
      const response = await client.getConsultations({
        query: { when, page },
      });
      if (response.status !== 200) {
        throw new Error((response.body as { error: { message: string } }).error.message);
      }

      return response.body.data;
    },
    staleTime: 1000 * 60 * 1, // 1 min
  });

  const consultData: ConsultationResponseDto[] = data || [];

  return { consultData, isLoading, error, isError, isSuccess, refetch };
};

export default useGetConsultations;
