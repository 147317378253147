import { Chip, ChipProps } from '@mui/material';

import ProductStrainsMap from '@/constants/products/productStrains';
import { ProductStrain, ProductStrainKey } from '@/types';

interface StrainChipProps {
  strainName: ProductStrain;
  strainKey: ProductStrainKey;
  size?: ChipProps['size'];
}

function StrainChip(props: StrainChipProps) {
  const { strainName, strainKey, size } = props;
  const { icon: Icon, color } = ProductStrainsMap[strainKey];

  return (
    <Chip
      size={size}
      variant="outlined"
      label={strainName}
      icon={<Icon color="inherit" sx={{ color }} />}
      sx={{ border: `1px solid ${color}` }}
    />
  );
}

export default StrainChip;
