import { Box, Skeleton, styled } from '@mui/material';

import { PageContent, PageSection } from './OrderMedication.styles';

const Container = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'space-between',
  width: '80%',
  margin: 'auto',
  [theme.breakpoints.up('lg')]: {
    width: '100%',
  },
}));

const ProductCard = styled(Box)(({ theme }) => ({
  border: '2px solid',
  borderRadius: theme.shape.borderRadius,
  borderColor: theme.palette.action.disabledBackground,
  marginBottom: theme.spacing(3),
  padding: '0.25rem 1rem',
  flexDirection: 'row',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
}));

function PatientRefillLoadingSkeleton() {
  return (
    <PageContent data-testid="loading-skeleton">
      <PageSection sx={{ gridArea: 'error' }} />
      <PageSection sx={{ gridArea: 'title' }}>
        <Skeleton variant="text" width={250} />
        <Container sx={{ gridArea: 'steps' }}>
          <Skeleton variant="circular" width={24} height={24} sx={{ marginBottom: 2 }} />
          <Skeleton variant="rectangular" width="40%" height={2} sx={{ marginBottom: 2 }} />
          <Skeleton variant="circular" width={24} height={24} sx={{ marginBottom: 2 }} />
          <Skeleton variant="rectangular" width="40%" height={2} sx={{ marginBottom: 2 }} />
          <Skeleton variant="circular" width={24} height={24} sx={{ marginBottom: 2 }} />
        </Container>
      </PageSection>
      <PageSection sx={{ gridArea: 'products' }}>
        <ProductCard>
          <Skeleton variant="text" height={42} width="60%" sx={{ marginTop: '1rem' }} />
          <Skeleton variant="rectangular" height={24} width={24} sx={{ marginTop: '1rem' }} />
        </ProductCard>
        <ProductCard>
          <Skeleton variant="text" height={42} width="60%" sx={{ marginTop: '1rem' }} />
          <Skeleton variant="rectangular" height={24} width={24} sx={{ marginTop: '1rem' }} />
        </ProductCard>
        <ProductCard>
          <Skeleton variant="text" height={42} width="60%" sx={{ marginTop: '1rem' }} />
          <Skeleton variant="rectangular" height={24} width={24} sx={{ marginTop: '1rem' }} />
        </ProductCard>
        <ProductCard>
          <Skeleton variant="text" height={42} width="60%" sx={{ marginTop: '1rem' }} />
          <Skeleton variant="rectangular" height={24} width={24} sx={{ marginTop: '1rem' }} />
        </ProductCard>
        <ProductCard>
          <Skeleton variant="text" height={42} width="60%" sx={{ marginTop: '1rem' }} />
          <Skeleton variant="rectangular" height={24} width={24} sx={{ marginTop: '1rem' }} />
        </ProductCard>
      </PageSection>
      <Box sx={{ gridArea: 'purchase' }}>
        <Skeleton variant="rectangular" height={150} />
        <Skeleton variant="text" height={50} />
      </Box>
    </PageContent>
  );
}

export default PatientRefillLoadingSkeleton;
