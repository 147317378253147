import { useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, PaginationVariant, Table } from '@montugroup/design-system';
import EditIcon from '@mui/icons-material/Edit';
import { Box, styled } from '@mui/material';
import { ColumnDef } from '@tanstack/react-table';

import TableSearchInput from '@/components/common/TableSearchInput';
import { useErrorManagement } from '@/context/ErrorManagement';
import useGetPatientsForGP from '@/hooks/gp/useGetPatientsForGP';
import useTablePaginationModel from '@/hooks/table/useTablePaginationModel';
import useTableSortingOrder from '@/hooks/table/useTableSortingOrder';
import { Patient } from '@/types';

type PatientTableItem = Pick<Patient, 'id' | 'patient_code' | 'patient_pharmacy_name'> & {
  first_name: string;
  email: string;
  phone: string;
  patient_name: string;
  approval: string;
};

const Wrapper = styled(Box)`
  padding: 0 1rem;
`;

const sortFieldOverrides = {
  patient_name: 'PatientUser.first_name',
  email: 'PatientUser.email',
  approval: 'PatientUser.approval',
} as const;

function PatientsTable() {
  const [filter, setFilter] = useState<string>('');

  const { enqueueError } = useErrorManagement();
  const { page, pageSize, handlePaginationModelChange } = useTablePaginationModel({ page: 0, pageSize: 50 });
  const { sortingOrder, handleSortingOrderChange } = useTableSortingOrder({ sortFieldOverrides });

  const navigate = useNavigate();

  const { data, isLoading, error } = useGetPatientsForGP({
    page,
    pageSize,
    sortingOrder,
    filter,
  });

  useEffect(() => {
    if (error) {
      enqueueError({ title: 'Something went wrong', body: (error as { message: string }).message || '' });
    }
  }, [enqueueError, error]);

  const columns: ColumnDef<PatientTableItem>[] = useMemo(
    () => [
      {
        accessorKey: 'id',
        header: 'id',
        enableSorting: true,
      },
      {
        accessorKey: 'patient_code',
        header: 'Patient ID',
        enableSorting: true,
      },
      {
        accessorKey: 'patient_name',
        header: 'Name',
        enableSorting: true,
      },
      {
        accessorKey: 'email',
        header: 'Email',
        enableSorting: true,
      },
      {
        accessorKey: 'phone',
        header: 'Phone',
        enableSorting: false,
      },
      {
        accessorKey: 'patient_pharmacy_name',
        header: 'Preferred Pharmacy',
        enableSorting: true,
      },
      {
        accessorKey: 'approval',
        header: 'Approval',
        enableSorting: false,
      },
      {
        id: 'edit',
        header: 'Edit',
        enableSorting: false,
        // eslint-disable-next-line react/no-unstable-nested-components
        cell: ({ row }) => (
          <Button variant="text" color="inherit" onClick={() => navigate(`/onboard/${row.original.id}`)}>
            <EditIcon aria-label="edit" sx={{ cursor: 'pointer' }} />
          </Button>
        ),
      },
    ],
    [navigate],
  );

  return (
    <Wrapper>
      <TableSearchInput value={filter} onChange={(value) => setFilter(value)} />
      <Table
        isLoading={isLoading}
        data={data?.patients || []}
        columns={columns}
        columnVisibility={{ id: false }}
        total={data?.count}
        showPagination
        manualPagination
        pageSize={pageSize}
        onPaginationModelChange={handlePaginationModelChange}
        paginationVariant={PaginationVariant.FIXED_PAGE_SIZE}
        onSortingChange={handleSortingOrderChange}
      />
    </Wrapper>
  );
}

export default PatientsTable;
