import React, { createContext, PropsWithChildren, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { QueryObserverResult } from 'react-query/types/core/types';
import { PaginationModel } from '@montugroup/design-system';
import { SortingState } from '@tanstack/react-table';
import { keyBy } from 'lodash';

import useGetPharmacistOrders from '@/hooks/pharmacist/useGetPharmacistOrders';
import useTablePaginationModel from '@/hooks/table/useTablePaginationModel';
import useTableSortingOrder from '@/hooks/table/useTableSortingOrder';
import { getReasonStatusList, processDispensing } from '@/services/pharmacist.service';
import { OrderStatus, PharmacistOrder, PharmacistReasonStatus, ProcessDispenseResult } from '@/types';

const DEFAULT_PAGE_SIZE = 50;
const INITIAL_PAGE = 0;

// eslint-disable-next-line no-shadow
export enum PharmacistOrderTableView {
  OPEN_ORDERS = 'openOrderOverview',
  PROCESSING_DISPENSE = 'processingDispense',
  DISPENSED_ORDERS = 'dispensedOverview',
  ALL_ORDERS = 'allOrderOverview',
}

export type PharmacistOrdersData = {
  activeView: PharmacistOrderTableView;
  setActiveView: (activeView: PharmacistOrderTableView) => void;
  page: number;
  pageSize: number;
  handlePaginationModelChange: (model: PaginationModel) => void;
  handleSortingOrderChange: (sortOrder: SortingState) => void;
  search: string;
  setSearch: (search: string) => void;
  selectedOrders: number[];
  setSelectedOrders: (selectedOrders: number[]) => void;
  orders: PharmacistOrder[];
  count: number;
  loading: boolean;
  unableToDispenseReasons: PharmacistReasonStatus[];
  setProcessingDispense: (loading: boolean) => void;
  refetch: () => Promise<QueryObserverResult | undefined>;
  statusFilter: OrderStatus[];
  setStatusFilter: (statusFilter: OrderStatus[]) => void;
};

// eslint-disable-next-line @typescript-eslint/no-empty-function
const noOp = () => {};

export const PharmacistOrdersContext = createContext<PharmacistOrdersData>({
  activeView: PharmacistOrderTableView.ALL_ORDERS,
  setActiveView: noOp,
  page: INITIAL_PAGE,
  pageSize: DEFAULT_PAGE_SIZE,
  handlePaginationModelChange: noOp,
  handleSortingOrderChange: noOp,
  search: '',
  setSearch: noOp,
  selectedOrders: [],
  setSelectedOrders: noOp,
  orders: [],
  count: 0,
  loading: false,
  unableToDispenseReasons: [],
  setProcessingDispense: noOp,
  statusFilter: [],
  setStatusFilter: noOp,
  // eslint-disable-next-line no-undefined
  refetch: () => Promise.resolve(undefined),
});

const sortFieldOverrides = {
  patient_name: 'Patient.PatientUser.first_name',
  gp_name: 'GeneralPractitioner.Doctor.first_name',
} as const;

export function PharmacistOrdersProvider(props: PropsWithChildren) {
  const { children } = props;
  const [activeView, setActiveView] = useState<PharmacistOrderTableView>(PharmacistOrderTableView.OPEN_ORDERS);
  const {
    page,
    pageSize,
    handlePaginationModelChange: doHandlePaginationModelChange,
  } = useTablePaginationModel({
    page: INITIAL_PAGE,
    pageSize: DEFAULT_PAGE_SIZE,
  });
  const { sortingOrder, handleSortingOrderChange } = useTableSortingOrder({ sortFieldOverrides });
  const [search, setSearch] = useState('');
  const [statusFilter, setStatusFilter] = useState<OrderStatus[]>([]);
  const [selectedOrders, setSelectedOrders] = useState<number[]>([]);
  const [processingDispense, setProcessingDispense] = useState<boolean>(false);
  const [previousCount, setPreviousCount] = useState<number>(0);
  const [unableToDispenseReasons, setUnableToDispenseReasons] = useState<PharmacistReasonStatus[]>([]);

  const { data, refetch, isLoading } = useGetPharmacistOrders({
    page,
    pageSize,
    sortingOrder,
    filter: search,
    activeTab: activeView,
    statusFilter,
  });

  const handlePaginationModelChange = useCallback(
    (model: PaginationModel) => {
      if (model.pageSize !== pageSize) {
        setSelectedOrders([]);
      }
      doHandlePaginationModelChange(model);
    },
    [doHandlePaginationModelChange, pageSize],
  );

  useEffect(() => {
    const fetchReasons = async () => {
      const reasons = await getReasonStatusList();
      const reasonsById = keyBy(reasons.data, 'id');
      const orderedReason = [9, 10, 5, 11, 1, 8, 7];
      setUnableToDispenseReasons(
        orderedReason.map((reasonId) => reasonsById[reasonId]).filter((value) => value !== undefined),
      );
    };
    fetchReasons();
  }, []);

  // count gets set to undefined whenever we make a request, which breaks pagination in PharmacistOrdersTable
  // this persists the previous count which we subsequently use if we're waiting for a response from the above request (isLoading === true)
  useEffect(() => {
    if (!isLoading) {
      setPreviousCount(data?.count || 0);
    }
  }, [data?.count, isLoading]);

  // See above - if pending request (isLoading) we want to use the previous count as it'll be undefined until we get a response
  const getCountValue = useCallback(() => {
    if (!data?.count && isLoading) {
      return previousCount;
    }
    if (data?.count) return data?.count;
    return 0;
  }, [data?.count, isLoading, previousCount]);

  const value = useMemo(
    () => ({
      activeView,
      page,
      pageSize,
      orders: data?.orders || [],
      count: getCountValue(),
      selectedOrders,
      loading: processingDispense || isLoading,
      search,
      refetch,
      statusFilter,
      unableToDispenseReasons,
      setActiveView,
      handlePaginationModelChange,
      handleSortingOrderChange,
      setSearch,
      setSelectedOrders,
      setProcessingDispense,
      setStatusFilter,
    }),
    [
      activeView,
      page,
      pageSize,
      data?.orders,
      getCountValue,
      selectedOrders,
      processingDispense,
      isLoading,
      search,
      refetch,
      unableToDispenseReasons,
      handlePaginationModelChange,
      statusFilter,
      setStatusFilter,
    ],
  );

  return <PharmacistOrdersContext.Provider value={value}>{children}</PharmacistOrdersContext.Provider>;
}

export const usePharmacistOrders = () => {
  const {
    activeView,
    page,
    pageSize,
    orders,
    count,
    selectedOrders,
    loading,
    search,
    refetch,
    statusFilter,
    setSelectedOrders,
    setSearch,
    setActiveView,
    handlePaginationModelChange,
    handleSortingOrderChange,
    setProcessingDispense,
    setStatusFilter,
  } = useContext(PharmacistOrdersContext);

  const changeTableView = (view: PharmacistOrderTableView) => {
    setSearch('');
    handlePaginationModelChange({ page: 0, pageSize });
    setActiveView(view);
    setSelectedOrders([]);
    handleSortingOrderChange([]);
  };

  const toggleOrderSelection = (orderId: number) => {
    if (selectedOrders.includes(orderId)) {
      setSelectedOrders(selectedOrders.filter((id) => id !== orderId));
    } else {
      setSelectedOrders([...selectedOrders, orderId]);
    }
  };

  const areAnyOrdersSelected = () => {
    const ordersInPage = orders.map(({ id }) => id);
    const overlappingOrders = selectedOrders.filter((selectedId) => ordersInPage.includes(selectedId));
    return overlappingOrders.length;
  };

  // toggle behaviour:
  // if any orders are checked in the current page, deselects all orders in page
  // else selects all orders in page
  const toggleSelectAllOrders = () => {
    const ordersInPage = orders.map(({ id }) => id);
    if (areAnyOrdersSelected()) {
      setSelectedOrders(selectedOrders.filter((selectedId) => !ordersInPage.includes(selectedId)));
    } else {
      setSelectedOrders([...selectedOrders, ...ordersInPage]);
    }
  };

  const setFilter = (filter: string) => {
    setSearch(filter || '');
    setSelectedOrders([]);
  };

  const dispenseOrders = async (): Promise<ProcessDispenseResult> => {
    setProcessingDispense(true);
    const { data } = await processDispensing(selectedOrders);
    await refetch();
    setProcessingDispense(false);
    setSelectedOrders([]);
    return data;
  };

  return {
    activeView,
    page,
    pageSize,
    orders,
    count,
    selectedOrders,
    search,
    statusFilter,
    loading,
    setFilter,
    changeTableView,
    setSearch,
    toggleOrderSelection,
    areAnyOrdersSelected,
    setSelectedOrders,
    toggleSelectAllOrders,
    handlePaginationModelChange,
    handleSortingOrderChange,
    dispenseOrders,
    setStatusFilter,
  };
};
