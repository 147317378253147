import * as braze from '@braze/web-sdk';
import md5 from 'crypto-js/md5';
import { v4 as uuidv4 } from 'uuid';

import { Logger } from '@/utils/logger';

import settings from '../constants/constants';

import { AuthService } from './authentication.service';
import { getAndFormatMoreUserData, stripDollarSigns } from './mixpanel.service';

const logger = new Logger('braze.service');
// Currently this is coupled to localStorage / AuthService and makes assumptions on that data matching the current
// app user.
// https://www.braze.com/docs/developer_guide/platform_integration_guides/web/analytics/setting_custom_attributes/
function updateUserAttributes() {
  const user = braze.getUser();
  if (!user) {
    return;
  }
  const userData = AuthService.getUser();
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const moreUserData = getAndFormatMoreUserData() as any;
  // eslint-disable-next-line camelcase
  const {
    first_name: firstName,
    email,
    address,
    city,
    state_id: stateId,
    zip_code: zipCode,
    phone,
  } = userData?.user || {};
  user.setFirstName(firstName || '');
  user.setEmail(email || '');
  user.setCustomUserAttribute('address', stripDollarSigns(address));
  user.setHomeCity(city || '');
  user.setCustomUserAttribute('state_id', stateId || '');
  user.setCustomUserAttribute('zip_code', zipCode || '');
  user.setPhoneNumber(phone || '');
  user.setCustomUserAttribute('client_code', moreUserData?.clientCode || '');
  user.setCustomUserAttribute('created_by', moreUserData?.createdBy || '');
  user.setCustomUserAttribute('date_of_birth', moreUserData?.dateOfBirth || ''); // TODO: use `setDateOfBirth`
  user.setCustomUserAttribute('patient_id', moreUserData.id || '');
  user.setCustomUserAttribute('is_discharged', moreUserData?.isDischarged || '');
  user.setCustomUserAttribute('is_new_patient', moreUserData?.isNewPatient || '');
  user.setCustomUserAttribute('patient_code', moreUserData?.patientCode || '');
  user.setCustomUserAttribute('patient_pharmacy_name', moreUserData?.patientPharmacyName || '');
  user.setCustomUserAttribute('patient_pharmacy_address', moreUserData?.patientPharmacyAddress || '');
  user.setCustomUserAttribute('pharmacy_id', moreUserData?.pharmacyId || '');
  user.setCustomUserAttribute('priority', moreUserData?.priority || '');
  user.setCustomUserAttribute('user_id', moreUserData?.userId || '');
  // TODO Enable the below after legal approval
  // user.setCustomUserAttribute('xero_contact_id', moreUserData?.xeroContactId || '');
  // user.setCustomUserAttribute('patient_discounts', moreUserData?.patientDiscounts || []);
  // user.setCustomUserAttribute('prescribed_products', moreUserData?.prescribedProducts || []);
  // user.setCustomUserAttribute('medical_conditions', moreUserData?.medicalConditions || []);
}

type BrazeInitOptions = {
  options?: Omit<braze.InitializationOptions, 'baseUrl'>;
  userEmail?: string;
  contentCardsSubscriber?: (events: braze.ContentCards) => void;
};

export function changeUser(email: string) {
  const identifier = md5(email.toLowerCase().trim()).toString();
  braze.changeUser(identifier);
  updateUserAttributes(); // TODO: Accept user data as input arg
}

export interface BrazeOrder {
  productId: string;
  price: number;
  currencyCode: string;
  quantity: number;
  purchaseProperties: {
    products: {
      product_id: number;
      product_name: string;
      price: number;
      quantity: number;
      remaining_units: number;
      is_out_of_stock: boolean;
      not_addable: boolean;
    }[];
  };
}

export function brazeTrackOrderPurchase(products: [] = []) {
  try {
    if (products.length < 1) {
      return;
    }

    const orderId = uuidv4();
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    products.forEach((p: any) => {
      braze.logPurchase(p['Product Name'] || 'Unknown', p.Price || 0, 'AUD', p.Quantity || 0, {
        order_id: orderId,
        product_id: p['Product ID'] || 0,
        remaining_units: p['Remaining Units'] || 0,
        is_out_of_stock: p['Is Out of Stock'] || false,
        not_addable: p['Is Addable'] || true,
      });
    });
  } catch (e) {
    logger.error('Braze checkout event failed to send', e);
  }
}

// https://www.braze.com/docs/developer_guide/platform_integration_guides/web/initial_sdk_setup/#step-2-initialize-braze
export function init({ options = {}, userEmail, contentCardsSubscriber }: BrazeInitOptions = {}) {
  braze.initialize(settings.brazeApiKey, {
    baseUrl: settings.brazeBaseUrl,
    ...options,
  });
  braze.requestPushPermission();
  braze.automaticallyShowInAppMessages();

  if (userEmail) {
    changeUser(userEmail);
  }

  if (contentCardsSubscriber) {
    braze.subscribeToContentCardsUpdates(contentCardsSubscriber);
    braze.requestContentCardsRefresh();
  }

  braze.openSession();
}

type InitParams = Parameters<typeof init>;

function configureClosure() {
  let isInitialized = false;

  return (...args: InitParams) => {
    if (!isInitialized) {
      init(...args);
      isInitialized = true;
      return;
    }
    if (args[0]?.userEmail) {
      changeUser(args[0].userEmail);
    }
  };
}

export const configure = configureClosure();

export default null;
