import React, { lazy, useEffect, useState } from 'react';
import { Navigate, Route, Routes as ReactRouterDOMRoutes, useLocation } from 'react-router-dom';
import { Experimental_CssVarsProvider as CssVarsProvider } from '@mui/material/styles';
import axios, { AxiosError, AxiosResponse } from 'axios';

import Inventory from '@/components/pharmacist/inventory';
import LegacyPharmacyUpdateHistory from '@/components/pharmacy/legacyPharmacyUpdateHistory';
import Research from '@/components/resources/Research';
import ComingSoon from '@/components/routes/ComingSoon';
import RequireAuth from '@/components/routes/RequireAuth';
import ProfileDetailsContainer from '@/components/user/ProfileDetailsContainer';
import ProfileResetPasswordContainer from '@/components/user/ResetPasswordContainer';
import settings from '@/constants/constants';
import {
  FF_ENABLE_404_AND_500_ERROR_PAGES,
  FF_ENABLE_ORDER_PHARMACY_ALLOCATION,
  FF_ENABLE_PATIENT_ORDERS_PAGE,
  FF_ENABLE_PHARMACY_UPDATE_HISTORY_TS,
  FF_ENABLE_SUPER_ADMIN_INVENTORY,
  FF_ENABLE_TS_GP_PATIENTS,
  FF_ENABLE_VIEW_ALL_PATIENTS_TS_MIGRATION,
  FF_SHOPIFY_REDIRECT_THANK_YOU_PAGE,
} from '@/constants/featureFlags';
import USER_ROLES from '@/constants/userRoles';
import useFeatureFlags from '@/hooks/useFeatureFlags';
import ViewPatients from '@/pages/admin/ViewPatients';
import ForgotPassword from '@/pages/auth/ForgotPassword';
import ResetPassword from '@/pages/auth/ResetPassword';
import SignIn from '@/pages/auth/SignIn';
import PatientConsultation from '@/pages/consultations/PatientConsultation';
import Error404 from '@/pages/error/404';
import GPPatients from '@/pages/gp/GPPatients';
import PatientHelp from '@/pages/help/PatientHelp';
import MedicineInformationContainer from '@/pages/medicine-information/MedicineInformationContainer';
import PatientHome from '@/pages/patient/PatientHome';
import PatientOrderDetails from '@/pages/patient/patientOrderDetails';
import PatientOrders from '@/pages/patient/patientOrders';
import PatientPrescription from '@/pages/patient/PatientPrescription';
import PatientOrdersV2 from '@/pages/patient/umeds_rebrand/PatientOrders';
import PayByInvoice from '@/pages/payment/shopify/payByInvoice';
import PaymentConfirm from '@/pages/payment/shopify/paymentConfirm';
import PaymentSuccessAuth from '@/pages/payment/shopify/paymentSuccessAuth';
import PaymentSuccessUnauth from '@/pages/payment/shopify/paymentSuccessUnauth';
import PharmacistInventory from '@/pages/pharmacist/PharmacistInventory';
import PharmacistOrders from '@/pages/pharmacist/PharmacistOrders';
import PharmacyUpdateHistory from '@/pages/pharmacy/PharmacyUpdateHistory';
import PharmacyAllocationSettings from '@/pages/pharmacy-allocation-settings/PharmacyAllocationSettings';
import PharmacyInventory from '@/pages/pharmacy-inventory/PharmacyInventory';
import ProductDetailsPage from '@/pages/products/portal-partner/ProductDetails';
import ProductCatalogContainer from '@/pages/products/ProductCatalogContainer';
import ProductDetailsPageV2 from '@/pages/products/ProductDetailsPageV2';
import OrderMedicationPage from '@/pages/umeds/order-medication/OrderMedication';
import { AuthService } from '@/services/authentication.service';
import montuTheme, { alternaleafTheme } from '@/theme';
import Brand from '@/utils/brand';

import ApApprovalForm from '../components/ap/apApprovalForm';
import confirmGPBySuperAdmins from '../components/auth/confirmGPAdmin';
import ConfirmUser from '../components/auth/confirmUser';
import SignOut from '../components/auth/SignOut';
import TermsAndPolicy from '../components/auth/TermsAndPolicy';
import ClinicForm from '../components/clinic/clinicForm';
import ClinicView from '../components/clinic/ClinicView';
import Campaign from '../components/gp/campaign';
import GPregister from '../components/gp/gpProfile';
import GpList from '../components/gp/gpView'; // Admin view
import Refillordersuccess from '../components/layout/Refillordersuccess';
import MlForm from '../components/ml/mlForm';
import MlView from '../components/ml/mlView';
import OrderView from '../components/order/orderView';
import AdminOnboardPatient from '../components/patient/adminOnboardPatient';
import PatientList from '../components/patient/patientView'; // Gp view list
import ViewPatient from '../components/patient/viewAllPatient'; // Admin view list
import SingleProductOverview from '../components/pharmacist/singleProductOverview';
import PharmacyAccountSetup from '../components/pharmacy/pharmacyAccountSetup';
import PharmacyReport from '../components/pharmacy/pharmacyReport';
import PharmacySuccess from '../components/pharmacy/PharmacySuccess';
import PharmacyView from '../components/pharmacy/pharmacyView';
import ViewPharmacy from '../components/pharmacy/viewPharmacyReport';
import CPDSessions from '../components/resources/CPDSessions';
import ProductResource from '../components/resources/Products';
import Resourses from '../components/resources/Resources';
import SasApprovalForm from '../components/sas/sasApprovalForm';
import SuppliersOnboarding from '../components/supplier/supplierOnboarding';
import CreditView from '../pages/credit/creditView';
import OrderForm from '../pages/order/orderForm';
import PatientCredits from '../pages/patient/patientCreditView';
import PatientOnboard from '../pages/patient/patientOnboard';
import ReferPatient from '../pages/patient/patientReferFriend';
import EWayOrders from '../pages/payment/eWay/eWayTransaction';
import PaymentCheckout from '../pages/payment/eWay/paymentCheckout';
import ProductDetails from '../pages/products/productDetails';
import Products from '../pages/products/products';
import SupportSelfService from '../pages/supportSelfService/SupportSelfService';
import Mixpanel from '../services/mixpanel.service';

// Importing the interim Alternaleaf rebranding stylesheet here, rather than requiring it in every page being reworked.
// TODO: Once the rebranding is rolled out, the contents in alternalead.scss should be baselined, and then the file + import removed.
import '../scss/alternaleaf.scss';

import HealthCheck from './healthCheck';

const PatientRefillPharmacy = lazy(
  () => import('@/pages/patient/umeds_rebrand/patientRefillPharmacy/PatientRefillPharmacy'),
);

const OrderAllocationTool = lazy(() => import('@/pages/order-allocation-tool/orderAllocationTool'));

const Suppliers = lazy(() => import('@/pages/supplier/Suppliers'));

const UNAUTHORIZED = 401;

axios.interceptors.response.use(
  (response: AxiosResponse) => response,
  (error: AxiosError) => {
    const status = error?.response?.status;
    if (status === UNAUTHORIZED && AuthService.isLoggedIn()) {
      AuthService.logout(true);
    }
    // eslint-disable-next-line no-param-reassign
    delete error.config?.headers?.Authorization;
    // eslint-disable-next-line no-param-reassign
    delete error.response?.config?.headers?.Authorization;
    return Promise.reject(error);
  },
);

// will track any route changes
function useTrackRoutes() {
  const location = useLocation();

  useEffect(() => {
    Mixpanel(location.pathname);
  }, [location]);
}

function Routes() {
  useTrackRoutes();
  const { flags } = useFeatureFlags();

  const location = useLocation();
  const [currentTheme, setCurrentTheme] = useState(Brand.isRebrand() ? alternaleafTheme : montuTheme);
  const enableUmeds404And500Pages = flags[FF_ENABLE_404_AND_500_ERROR_PAGES];

  useEffect(() => {
    setCurrentTheme(Brand.isRebrand() ? alternaleafTheme : montuTheme);
  }, [location]);

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  const NoMatch = enableUmeds404And500Pages ? () => <Error404 /> : () => null;

  // redirect to external url for anyone who has bookmarked/accesses the removed 'patient/credit' page
  if (location.pathname === '/patient/credit') {
    window.location.replace(`${settings.support.site.url}/hc/en-au/articles/34907935744153-How-have-credits-changed`);
    return null;
  }

  return (
    <CssVarsProvider theme={currentTheme}>
      <ReactRouterDOMRoutes>
        <Route path="/" Component={SignIn} />
        <Route path="/login" Component={SignIn} />
        <Route path="/login/:loginType" Component={SignIn} />
        <Route path="/login/:loginType/:onBoardKey" Component={SignIn} />
        <Route path="/forgot-password/:loginType" Component={ForgotPassword} />
        <Route path="/reset-password/:id" Component={ResetPassword} />
        <Route path="/confirm-user/:id" Component={ConfirmUser} />
        <Route path="/health-check" Component={HealthCheck} />
        <Route path="/policy" Component={TermsAndPolicy} />
        <Route path="/pharmacyaccount" element={<PharmacyAccountSetup />} />
        <Route path="/pharmacysuccess" Component={PharmacySuccess} />
        <Route path="/verify-doctor/:id" Component={confirmGPBySuperAdmins} />
        <Route path="/pay-by-invoice/:token" Component={PayByInvoice} />
        <Route path="/payment-confirm/account" element={<Navigate to={{ pathname: '/home' }} replace />} />
        <Route path="/payment-confirm/cart" element={<Navigate to={{ pathname: '/patient/refill' }} replace />} />
        <Route path="/payment-confirm/login" element={<Navigate to={{ pathname: '/patient/login' }} replace />} />
        <Route path="/payment-confirm/logout" element={<Navigate to={{ pathname: '/logout' }} replace />} />
        <Route path="/payment-confirm" Component={PaymentConfirm} />
        <Route path="/payment-success" Component={PaymentSuccessUnauth} />
        <Route
          path="/product-catalog"
          element={
            <RequireAuth
              allowedRoles={[USER_ROLES.superAdmin, USER_ROLES.patient, USER_ROLES.admin, USER_ROLES.ptxSupportPartner]}
            >
              <ProductCatalogContainer />
            </RequireAuth>
          }
        />
        <Route
          path="/medicine-information"
          element={
            <RequireAuth allowedRoles={[USER_ROLES.patient]}>
              <MedicineInformationContainer />
            </RequireAuth>
          }
        />
        <Route
          path="/product-detail/:id"
          element={
            <RequireAuth
              allowedRoles={[USER_ROLES.superAdmin, USER_ROLES.patient, USER_ROLES.admin, USER_ROLES.ptxSupportPartner]}
            >
              <ProductDetailsPageV2 />
            </RequireAuth>
          }
        />
        <Route
          path="customer/product-detail/:id"
          element={
            <RequireAuth allowedRoles={[USER_ROLES.patient]}>
              <ProductDetailsPage />
            </RequireAuth>
          }
        />
        <Route
          path="/patient/orders"
          element={
            <RequireAuth allowedRoles={[USER_ROLES.patient]} displayGlobalContentCard>
              {flags[FF_ENABLE_PATIENT_ORDERS_PAGE] ? <PatientOrdersV2 /> : <PatientOrders />}
            </RequireAuth>
          }
        />
        <Route
          path="/patient/order-detail/:orderId"
          element={
            <RequireAuth allowedRoles={[USER_ROLES.patient]} displayGlobalContentCard>
              <PatientOrderDetails />
            </RequireAuth>
          }
        />
        <Route
          path="/refillordersuccess"
          element={
            <RequireAuth allowedRoles={[USER_ROLES.patient]}>
              <Refillordersuccess />
            </RequireAuth>
          }
        />
        <Route
          path="/home"
          element={
            <RequireAuth allowedRoles={[USER_ROLES.patient]}>
              <PatientHome />
            </RequireAuth>
          }
        />
        <Route
          path="/logout"
          element={
            <RequireAuth
              allowedRoles={[
                USER_ROLES.superAdmin,
                USER_ROLES.doctor,
                USER_ROLES.patient,
                USER_ROLES.ml,
                USER_ROLES.pharmacist,
                USER_ROLES.admin,
                USER_ROLES.ptxSupportPartner,
              ]}
            >
              <SignOut />
            </RequireAuth>
          }
        />
        <Route
          path="/gp-register"
          element={
            <RequireAuth allowedRoles={[USER_ROLES.superAdmin, USER_ROLES.admin]}>
              <GPregister />
            </RequireAuth>
          }
        />
        <Route
          path="/gp-register/:id"
          element={
            <RequireAuth allowedRoles={[USER_ROLES.superAdmin, USER_ROLES.admin]}>
              <GPregister />
            </RequireAuth>
          }
        />
        <Route
          path="/onboard"
          element={
            <RequireAuth
              allowedRoles={[USER_ROLES.superAdmin, USER_ROLES.doctor, USER_ROLES.admin, USER_ROLES.ptxSupportPartner]}
            >
              <PatientOnboard />
            </RequireAuth>
          }
        />
        <Route
          path="/onboard/:id"
          element={
            <RequireAuth
              allowedRoles={[USER_ROLES.superAdmin, USER_ROLES.doctor, USER_ROLES.admin, USER_ROLES.ptxSupportPartner]}
            >
              <PatientOnboard />
            </RequireAuth>
          }
        />
        <Route
          path="/onboard-patient"
          element={
            <RequireAuth
              allowedRoles={[USER_ROLES.superAdmin, USER_ROLES.doctor, USER_ROLES.admin, USER_ROLES.ptxSupportPartner]}
            >
              <AdminOnboardPatient />
            </RequireAuth>
          }
        />
        <Route
          path="/onboard-patient/:gpId"
          element={
            <RequireAuth
              allowedRoles={[USER_ROLES.superAdmin, USER_ROLES.doctor, USER_ROLES.admin, USER_ROLES.ptxSupportPartner]}
            >
              <AdminOnboardPatient />
            </RequireAuth>
          }
        />
        <Route
          path="/order-patient"
          element={
            <RequireAuth
              allowedRoles={[USER_ROLES.superAdmin, USER_ROLES.doctor, USER_ROLES.admin, USER_ROLES.ptxSupportPartner]}
            >
              <OrderForm />
            </RequireAuth>
          }
        />
        <Route
          path="/order-patient/:patientId"
          element={
            <RequireAuth
              allowedRoles={[USER_ROLES.superAdmin, USER_ROLES.doctor, USER_ROLES.admin, USER_ROLES.ptxSupportPartner]}
            >
              <OrderForm />
            </RequireAuth>
          }
        />
        <Route
          path="/order"
          element={
            <RequireAuth
              allowedRoles={[USER_ROLES.superAdmin, USER_ROLES.doctor, USER_ROLES.admin, USER_ROLES.ptxSupportPartner]}
            >
              <OrderForm />
            </RequireAuth>
          }
        />
        <Route
          path="/order/:id"
          element={
            <RequireAuth
              allowedRoles={[USER_ROLES.superAdmin, USER_ROLES.doctor, USER_ROLES.admin, USER_ROLES.ptxSupportPartner]}
            >
              <OrderForm />
            </RequireAuth>
          }
        />
        <Route
          path="/order/:id/:refill"
          element={
            <RequireAuth
              allowedRoles={[USER_ROLES.superAdmin, USER_ROLES.doctor, USER_ROLES.admin, USER_ROLES.ptxSupportPartner]}
            >
              <OrderForm />
            </RequireAuth>
          }
        />
        <Route
          path="/patient/refill"
          element={
            <RequireAuth allowedRoles={[USER_ROLES.patient]} displayGlobalContentCard>
              <OrderMedicationPage />
            </RequireAuth>
          }
        />
        <Route
          path="/patient/refill/:id"
          element={
            <RequireAuth allowedRoles={[USER_ROLES.patient]} displayGlobalContentCard>
              <OrderMedicationPage />
            </RequireAuth>
          }
        />
        <Route
          path="/patient/refill-pharmacy"
          element={
            <RequireAuth allowedRoles={[USER_ROLES.patient]}>
              {flags[FF_ENABLE_ORDER_PHARMACY_ALLOCATION] ? <PatientRefillPharmacy /> : null}
            </RequireAuth>
          }
        />
        <Route
          path="/gp/patients"
          element={
            <RequireAuth allowedRoles={[USER_ROLES.doctor]}>
              {flags[FF_ENABLE_TS_GP_PATIENTS] ? <GPPatients /> : <PatientList />}
            </RequireAuth>
          }
        />
        <Route
          path="/ap-approval/:id"
          element={
            <RequireAuth
              allowedRoles={[
                USER_ROLES.superAdmin,
                USER_ROLES.doctor,
                USER_ROLES.pharmacist,
                USER_ROLES.admin,
                USER_ROLES.ptxSupportPartner,
              ]}
            >
              <ApApprovalForm />
            </RequireAuth>
          }
        />
        <Route
          path="/approval"
          element={
            <RequireAuth allowedRoles={[USER_ROLES.doctor]}>
              <SasApprovalForm />
            </RequireAuth>
          }
        />
        <Route
          path="/approval/:id"
          element={
            <RequireAuth
              allowedRoles={[
                USER_ROLES.superAdmin,
                USER_ROLES.doctor,
                USER_ROLES.pharmacist,
                USER_ROLES.admin,
                USER_ROLES.ptxSupportPartner,
              ]}
            >
              <SasApprovalForm />
            </RequireAuth>
          }
        />
        <Route
          path="/pharmacy-account"
          element={
            <RequireAuth allowedRoles={[USER_ROLES.superAdmin, USER_ROLES.doctor, USER_ROLES.admin]}>
              <PharmacyAccountSetup />
            </RequireAuth>
          }
        />
        <Route
          path="/pharmacy-account/:id"
          element={
            <RequireAuth allowedRoles={[USER_ROLES.superAdmin, USER_ROLES.doctor, USER_ROLES.admin]}>
              <PharmacyAccountSetup />
            </RequireAuth>
          }
        />
        <Route
          path="/doctors/"
          element={
            <RequireAuth
              allowedRoles={[USER_ROLES.superAdmin, USER_ROLES.ml, USER_ROLES.admin, USER_ROLES.ptxSupportPartner]}
            >
              <GpList />
            </RequireAuth>
          }
        />
        <Route
          path="/pharmacy/report/:id"
          element={
            <RequireAuth allowedRoles={[USER_ROLES.superAdmin, USER_ROLES.admin, USER_ROLES.ptxSupportPartner]}>
              <PharmacyReport />
            </RequireAuth>
          }
        />
        {flags[FF_ENABLE_SUPER_ADMIN_INVENTORY] && (
          <Route
            path="/pharmacy/:id/inventory"
            element={
              <RequireAuth allowedRoles={[USER_ROLES.superAdmin]}>
                <Inventory />
              </RequireAuth>
            }
          />
        )}
        <Route
          path="/pharmacy"
          element={
            <RequireAuth allowedRoles={[USER_ROLES.superAdmin, USER_ROLES.admin, USER_ROLES.ptxSupportPartner]}>
              <PharmacyView />
            </RequireAuth>
          }
        />
        <Route
          path="/patients"
          element={
            <RequireAuth allowedRoles={[USER_ROLES.superAdmin, USER_ROLES.admin, USER_ROLES.ptxSupportPartner]}>
              {flags[FF_ENABLE_VIEW_ALL_PATIENTS_TS_MIGRATION] ? <ViewPatients /> : <ViewPatient />}
            </RequireAuth>
          }
        />
        <Route
          path="/password"
          element={
            <RequireAuth
              allowedRoles={[
                USER_ROLES.superAdmin,
                USER_ROLES.doctor,
                USER_ROLES.patient,
                USER_ROLES.ml,
                USER_ROLES.pharmacist,
                USER_ROLES.admin,
              ]}
            >
              <ProfileResetPasswordContainer />
            </RequireAuth>
          }
        />
        <Route
          path="/password/:loginType"
          element={
            <RequireAuth
              allowedRoles={[
                USER_ROLES.superAdmin,
                USER_ROLES.doctor,
                USER_ROLES.patient,
                USER_ROLES.ml,
                USER_ROLES.pharmacist,
                USER_ROLES.admin,
                USER_ROLES.ptxSupportPartner,
              ]}
            >
              <ProfileResetPasswordContainer />
            </RequireAuth>
          }
        />
        <Route
          path="/orders"
          element={
            <RequireAuth
              allowedRoles={[
                USER_ROLES.superAdmin,
                USER_ROLES.doctor,
                USER_ROLES.ml,
                USER_ROLES.admin,
                USER_ROLES.ptxSupportPartner,
              ]}
            >
              <OrderView />
            </RequireAuth>
          }
        />
        <Route
          path="/credits"
          element={
            <RequireAuth allowedRoles={[USER_ROLES.superAdmin, USER_ROLES.admin, USER_ROLES.ptxSupportPartner]}>
              <CreditView />
            </RequireAuth>
          }
        />
        <Route
          path="/patientcredits"
          element={
            <RequireAuth allowedRoles={[USER_ROLES.patient]}>
              <PatientCredits />
            </RequireAuth>
          }
        />
        <Route
          path="/viewclinics"
          element={
            <RequireAuth allowedRoles={[USER_ROLES.superAdmin, USER_ROLES.admin, USER_ROLES.ptxSupportPartner]}>
              <ClinicView />
            </RequireAuth>
          }
        />
        <Route
          path="/editclinic/:id"
          element={
            <RequireAuth allowedRoles={[USER_ROLES.superAdmin, USER_ROLES.admin, USER_ROLES.ptxSupportPartner]}>
              <ClinicForm />
            </RequireAuth>
          }
        />
        <Route
          path="/profile-detail"
          element={
            <RequireAuth
              allowedRoles={[
                USER_ROLES.superAdmin,
                USER_ROLES.doctor,
                USER_ROLES.patient,
                USER_ROLES.ml,
                USER_ROLES.pharmacist,
                USER_ROLES.admin,
                USER_ROLES.ptxSupportPartner,
              ]}
            >
              <ProfileDetailsContainer />
            </RequireAuth>
          }
        />
        <Route
          path="/pharmacy-report"
          element={
            <RequireAuth allowedRoles={[USER_ROLES.superAdmin, USER_ROLES.admin, USER_ROLES.ptxSupportPartner]}>
              <ViewPharmacy />
            </RequireAuth>
          }
        />
        <Route
          path="/bd"
          element={
            <RequireAuth allowedRoles={[USER_ROLES.superAdmin, USER_ROLES.admin, USER_ROLES.ptxSupportPartner]}>
              <MlView />
            </RequireAuth>
          }
        />
        <Route
          path="/bd/onboard/:id"
          element={
            <RequireAuth allowedRoles={[USER_ROLES.superAdmin, USER_ROLES.admin, USER_ROLES.ptxSupportPartner]}>
              <MlForm />
            </RequireAuth>
          }
        />
        <Route
          path="/bd/onboard/"
          element={
            <RequireAuth allowedRoles={[USER_ROLES.superAdmin, USER_ROLES.admin, USER_ROLES.ptxSupportPartner]}>
              <MlForm />
            </RequireAuth>
          }
        />
        <Route
          path="/suppliers"
          element={
            <RequireAuth allowedRoles={[USER_ROLES.superAdmin, USER_ROLES.admin, USER_ROLES.ptxSupportPartner]}>
              <Suppliers />
            </RequireAuth>
          }
        />
        <Route
          path="/suppliers/onboard/:id"
          element={
            <RequireAuth allowedRoles={[USER_ROLES.superAdmin, USER_ROLES.admin, USER_ROLES.ptxSupportPartner]}>
              <SuppliersOnboarding />
            </RequireAuth>
          }
        />
        <Route
          path="/suppliers/onboard/"
          element={
            <RequireAuth allowedRoles={[USER_ROLES.superAdmin, USER_ROLES.admin, USER_ROLES.ptxSupportPartner]}>
              <SuppliersOnboarding />
            </RequireAuth>
          }
        />
        <Route
          path="/resources/research"
          element={
            <RequireAuth
              allowedRoles={[
                USER_ROLES.superAdmin,
                USER_ROLES.doctor,
                USER_ROLES.ml,
                USER_ROLES.admin,
                USER_ROLES.ptxSupportPartner,
              ]}
            >
              <Research />
            </RequireAuth>
          }
        />
        <Route
          path="/resources/cpd-sessions"
          element={
            <RequireAuth
              allowedRoles={[
                USER_ROLES.superAdmin,
                USER_ROLES.doctor,
                USER_ROLES.ml,
                USER_ROLES.admin,
                USER_ROLES.ptxSupportPartner,
              ]}
            >
              <CPDSessions />
            </RequireAuth>
          }
        />
        <Route
          path="/resources/products"
          element={
            <RequireAuth
              allowedRoles={[
                USER_ROLES.superAdmin,
                USER_ROLES.doctor,
                USER_ROLES.ml,
                USER_ROLES.admin,
                USER_ROLES.ptxSupportPartner,
              ]}
            >
              <ProductResource />
            </RequireAuth>
          }
        />
        <Route
          path="/resources"
          element={
            <RequireAuth
              allowedRoles={[
                USER_ROLES.superAdmin,
                USER_ROLES.doctor,
                USER_ROLES.ml,
                USER_ROLES.admin,
                USER_ROLES.ptxSupportPartner,
              ]}
            >
              <Resourses />
            </RequireAuth>
          }
        />
        <Route
          path="/study/"
          element={
            <RequireAuth
              allowedRoles={[USER_ROLES.superAdmin, USER_ROLES.doctor, USER_ROLES.admin, USER_ROLES.ptxSupportPartner]}
            >
              <Campaign />
            </RequireAuth>
          }
        />
        <Route
          path="/study/order/"
          element={
            <RequireAuth allowedRoles={[USER_ROLES.superAdmin, USER_ROLES.doctor]}>
              <OrderForm />
            </RequireAuth>
          }
        />
        <Route
          path="/study/onboard"
          element={
            <RequireAuth allowedRoles={[USER_ROLES.superAdmin, USER_ROLES.doctor]}>
              <PatientOnboard campaign={false} />
            </RequireAuth>
          }
        />
        <Route
          path="/study/onboard/:id"
          element={
            <RequireAuth allowedRoles={[USER_ROLES.superAdmin, USER_ROLES.doctor]}>
              <PatientOnboard campaign={false} />
            </RequireAuth>
          }
        />
        <Route
          path="/products"
          element={
            <RequireAuth allowedRoles={[USER_ROLES.superAdmin, USER_ROLES.admin, USER_ROLES.ptxSupportPartner]}>
              <Products />
            </RequireAuth>
          }
        />
        <Route
          path="/productEdit/:id"
          element={
            <RequireAuth allowedRoles={[USER_ROLES.superAdmin, USER_ROLES.admin, USER_ROLES.ptxSupportPartner]}>
              <ProductDetails />
            </RequireAuth>
          }
        />
        <Route
          path="/comingSoon"
          element={
            <RequireAuth allowedRoles={[USER_ROLES.patient]}>
              <ComingSoon />
            </RequireAuth>
          }
        />
        <Route
          path="/pharmacist/inventory"
          element={
            <RequireAuth allowedRoles={[USER_ROLES.pharmacist]}>
              <PharmacistInventory />
            </RequireAuth>
          }
        />
        <Route
          path="/pharmacist/orders"
          element={
            <RequireAuth allowedRoles={[USER_ROLES.pharmacist]}>
              <PharmacistOrders />
            </RequireAuth>
          }
        />
        <Route
          path="/pharmacist/productOverview/:id"
          element={
            <RequireAuth allowedRoles={[USER_ROLES.pharmacist]}>
              <SingleProductOverview />
            </RequireAuth>
          }
        />
        <Route
          path="/paymentcheckout"
          element={
            <RequireAuth allowedRoles={[USER_ROLES.patient]}>
              <PaymentCheckout />
            </RequireAuth>
          }
        />
        <Route
          path="/patient/payment-success"
          element={
            <RequireAuth allowedRoles={[USER_ROLES.patient]}>
              {flags[FF_SHOPIFY_REDIRECT_THANK_YOU_PAGE] ? (
                <Navigate to="/patient/orders" replace />
              ) : (
                <PaymentSuccessAuth />
              )}
            </RequireAuth>
          }
        />
        <Route
          path="/invoices"
          element={
            <RequireAuth allowedRoles={[USER_ROLES.superAdmin, USER_ROLES.admin, USER_ROLES.ptxSupportPartner]}>
              <EWayOrders />
            </RequireAuth>
          }
        />
        <Route
          path="/patient/prescription"
          element={
            <RequireAuth allowedRoles={[USER_ROLES.patient]}>
              <PatientPrescription />
            </RequireAuth>
          }
        />
        <Route
          path="/patient/consultations"
          element={
            <RequireAuth allowedRoles={[USER_ROLES.patient]}>
              <PatientConsultation />
            </RequireAuth>
          }
        />
        <Route
          path="/patient/refer-friend"
          element={
            <RequireAuth allowedRoles={[USER_ROLES.patient]}>
              <ReferPatient />
            </RequireAuth>
          }
        />
        <Route
          path="/faq"
          element={
            <RequireAuth
              allowedRoles={[USER_ROLES.superAdmin, USER_ROLES.patient, USER_ROLES.admin, USER_ROLES.ptxSupportPartner]}
            >
              <PatientHelp />
            </RequireAuth>
          }
        />
        <Route
          path="/support-self-service"
          element={
            <RequireAuth allowedRoles={[USER_ROLES.superAdmin, USER_ROLES.admin, USER_ROLES.ptxSupportPartner]}>
              <SupportSelfService />
            </RequireAuth>
          }
        />
        <Route
          path="/order-allocation-tool"
          element={
            <RequireAuth allowedRoles={[USER_ROLES.superAdmin, USER_ROLES.admin, USER_ROLES.ptxSupportPartner]}>
              <OrderAllocationTool />
            </RequireAuth>
          }
        />
        <Route
          path="/beta-pharmacy-inventory"
          element={
            <RequireAuth allowedRoles={[USER_ROLES.superAdmin, USER_ROLES.admin, USER_ROLES.ptxSupportPartner]}>
              <PharmacyInventory />
            </RequireAuth>
          }
        />
        <Route
          path="/pharmacy-allocation-settings"
          element={
            <RequireAuth allowedRoles={[USER_ROLES.superAdmin, USER_ROLES.admin, USER_ROLES.ptxSupportPartner]}>
              <PharmacyAllocationSettings />
            </RequireAuth>
          }
        />
        <Route
          path="/updatehistory"
          element={
            <RequireAuth allowedRoles={[USER_ROLES.superAdmin]}>
              {FF_ENABLE_PHARMACY_UPDATE_HISTORY_TS ? <PharmacyUpdateHistory /> : <LegacyPharmacyUpdateHistory />}
            </RequireAuth>
          }
        />
        <Route path="*" Component={NoMatch} />
      </ReactRouterDOMRoutes>
    </CssVarsProvider>
  );
}

export default Routes;
