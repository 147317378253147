import { Container, styled } from '@mui/material';

export const PartnerContainer = styled(Container)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  width: '100%',
  flexDirection: 'row',
  justifyContent: 'space-between',
  paddingLeft: theme.spacing(5),
  paddingRight: theme.spacing(5),
  [`${theme.breakpoints.up('xs')},${theme.breakpoints.up('sm')},${theme.breakpoints.up('md')}`]: {
    paddingLeft: theme.spacing(5),
    paddingRight: theme.spacing(5),
  },
  [`${theme.breakpoints.up('lg')},${theme.breakpoints.up('xl')}`]: {
    paddingLeft: theme.spacing(22),
    paddingRight: theme.spacing(22),
  },
}));

export default null;
