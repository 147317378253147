import { ProductSpectrums, ProductStrainKeys } from '@/types';

import { Supplier } from './detail/types';

export const DEFAULT_NO_VALUE_STR = '-';

const BROAD_SPECTRUM_ID = 1;

// Derives product usage time from flower strain key
export const getProductUsageTime = (productStrainKey?: string | null) => {
  switch (productStrainKey) {
    case ProductStrainKeys.SATIVA:
      return 'Day';
    case ProductStrainKeys.INDICA:
      return 'Night';
    case ProductStrainKeys.HYBRID:
      return 'Both';
    default:
      return DEFAULT_NO_VALUE_STR;
  }
};

// Derives category number from strings like "Category 1: (CBD >= 98%)"
export const getProductCategory = (productIngredientName?: string | null) =>
  productIngredientName ? parseInt(productIngredientName.split(' ')[1], 10) : DEFAULT_NO_VALUE_STR;

// Normalizes strings like "foo\r\nbar\nbaz" and resolves to "foo, bar, baz"
export const getProductTerpeneProfile = (contents?: string | null) =>
  contents ? contents.replaceAll('\r', '').replaceAll(/\n+/g, ', ') : DEFAULT_NO_VALUE_STR;

// Suppliers were originally designed to be one to many but never used that way
export const getProductBrand = (suppliers?: Pick<Supplier, 'supplier_name'>[] | null) =>
  suppliers && suppliers[0] ? suppliers[0].supplier_name : DEFAULT_NO_VALUE_STR;

// Derives spectrum type from id
export const getProductSpectrum = (spectrumId?: number | null) =>
  spectrumId === BROAD_SPECTRUM_ID ? ProductSpectrums.BROAD : ProductSpectrums.FULL;

// Derives product usage steps based on legacy logic
export const getProductHowToUseSteps = (howToUse?: string | null) =>
  (howToUse ?? '')
    .replaceAll('\r', '')
    .replaceAll(/\n+/g, '\n')
    .split('\n')
    .filter((step) => step.length > 0);

// Base set of information about product attributes
export const TOOLTIPS = {
  strain:
    'Strain indicates a plant’s genetic lineage and approximate effects: Indica, relaxing; Sativa, uplifting; Hybrid, in between.',
  // eslint-disable-next-line quotes
  spectrum: "Spectrum denotes the portion of a plant's naturally occurring compounds present in a finished product.",
  dayornight: 'Recommended time of administration, depending on how calming or stimulating a medicine may be.',
  schedule:
    'A national regulatory classification that determines the pathway for patient access to a specific medicine.',
  category:
    'Cannabis medicines are categorised by the amount of CBD or THC present as a portion of the total active compounds.',
  terpeneprofile:
    'Terpenes produce a cannabis flower’s aroma, and act synergistically with cannabinoids to emphasise particular therapeutic effects.',
};
