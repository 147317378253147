import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { toast } from 'react-toastify';
import FilterableTable from 'rc-data-table';
import CircleLoader from '../common/circleLoader';
import Button from '../common/button';
import { AuthService } from '@/services/authentication.service';
import { PharmacyService } from '@/services/pharmacy.service';
import editIcon from '../../assets/images/edit.png';
import settings from '@/constants/constants';
import CustomModal from './../common/CustomModal';
import '../../scss/pharmacyView.scss';
import { Logger } from '@/utils/logger';
import withRouter from '@/utils/withRouter';

const logger = new Logger('pharmacyView');

const url = settings.url;
const API_URL = `${url}/pharmacy`;
const CancelToken = axios.CancelToken;
let cancel;

class MyComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      date: new Date(),
      pageSize: 100,
      count: 0,
      page: 0,
      filter: null,
      sort: { name: 'id', reverse: true },
      loading: true,
      importLoader: false,
      formType: '',
      file: '',
      fileError: '',
      pharmFileName: '',
      orderData: [],
      showOrderUpdatePharmacyModal: false,
      downloading: false,
    };
  }

  async componentDidMount() {
    document.title = 'Pharmacies - Montu Group | Making Medical Cannabis Accessible';
    await this.dataBind();
  }

  dataBind = async () => {
    const { page, pageSize, sort, filter } = this.state;
    if (cancel) {
      cancel();
    }

    axios
      .get(`${settings.url}/pharmacy/search`, {
        cancelToken: new CancelToken(function executor(c) {
          cancel = c;
        }),
        params: {
          limit: pageSize,
          offset: page * pageSize,
          sort: sort.name,
          sortOrder: sort.reverse ? 'DESC' : 'ASC',
          filter,
          ts: new Date().getTime(),
          excelData: false,
        },
      })
      .then((data) => {
        this.setState({ data: data.data.pharmacies, count: data.data.count, loading: false });
      });
  };

  pageChange = async (page, size) => {
    this.setState({ page: page, pageSize: size, loading: true }, function () {
      this.dataBind();
    });
  };

  getSortColumn = (sort) => {
    this.setState({ sort: sort[0], loading: true }, function () {
      this.dataBind();
    });
  };

  onFilter = (filter) => {
    this.setState({ filter, page: 0 }, function () {
      this.dataBind();
    });
  };

  handleChange = (date) => {
    this.setState({
      date: date,
    });
  };

  cancelOrderUpdateModal = () => {
    this.setState({ showOrderUpdatePharmacyModal: false });
  };

  saveOrderUpdateModal = async () => {
    const updateData = this.state.orderData.filter(
      (od) => (od.ePrescription && od.eligible) || (!od.repeated && od.eligible) || od.both,
    );
    this.setState({
      loading: true,
    });
    const response = await axios.post(`${API_URL}/updatePharmacy`, { updateData, fileName: this.state.pharmFileName });
    if (response.status === 200) {
      toast.success('Pharmacies Updated Successfully');
    } else {
      toast.error('Error while updating Pharmacies');
      logger.error('saveOrderUpdateModal - Error while updating Pharmacies');
    }
    this.setState({ showOrderUpdatePharmacyModal: false, loading: false });
  };

  downloadTemplate = async (fileName) => {
    this.setState({ downloading: true });
    await axios({
      url: `${API_URL}/update-history-file-download`,
      method: 'GET',
      params: {
        name: fileName,
      },
      responseType: 'blob',
    }).then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      const downloadPath = fileName;
      link.setAttribute('download', downloadPath);
      document.body.appendChild(link);
      link.click();
    });
    this.setState({ downloading: true });
  };

  uploadfileHandling = async (e) => {
    this.setState({
      loading: false,
    });
    let file = e.target.files[0];
    let fileName = file.name;
    let fileSize = file.size;
    let fileExtension = fileName.split('.')[fileName.split('.').length - 1].toLowerCase();
    if (['xls', 'xlsx', 'csv'].indexOf(fileExtension) === -1) {
      toast.error('This File type is not allowed');
      logger.error('uploadfileHandling - This File type is not allowed', fileExtension);

      return;
    }
    if (fileSize > 5242880) {
      // 5MB max-size
      toast.error('File Size exceeded the Limit');
      logger.error('uploadfileHandling - File Size exceeded the Limit', fileSize);
      return;
    }
    this.setState(
      {
        file: e.target.files[0],
        fileError: '',
      },
      async () => {
        this.setState({
          loading: true,
        });
        let upload = new FormData();
        upload.append('file', this.state.file);
        axios
          .post(`${API_URL}/uploadpharmacy`, upload, {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          })
          .then((res) => {
            this.setState({
              orderData: res.data.data,
              pharmFileName: res.data.fileName,
              loading: false,
              showOrderUpdatePharmacyModal: true,
            });
          });
      },
    );
    toast.success('CSV File Uploaded Successfully');
  };

  fileHandling = async (e) => {
    let file = e.target.files[0];
    let fileName = file.name;
    let fileSize = file.size;
    let fileExtension = fileName.split('.')[fileName.split('.').length - 1].toLowerCase();
    this.setState(
      {
        file: e.target.files[0],
        fileError: '',
      },
      async () => {
        let upload = new FormData();
        upload.append('file', this.state.file);
        await PharmacyService.pharmacyUpload(upload);
        this.props.navigate(0);
      },
    );
    if (['xls', 'xlsx'].indexOf(fileExtension) === -1) {
      this.setState({
        fileError: 'This File type is not allowed',
      });
      toast.error('Pharmacy not uploaded successfully');
      logger.error('fileHandling - Pharmacy not uploaded successfully - his File type is not allowed', fileExtension);
    } else if (fileSize > 5242880) {
      // 5MB max-size
      this.setState({
        fileError: 'File Size exceeded the Limit',
      });
      toast.error('Pharmacy not uploaded successfully');
      logger.error('fileHandling - Pharmacy not uploaded successfully -File Size exceeded the Limit', fileSize);
    } else {
      toast.success('Pharmacy uploaded successfully');
    }
  };
  //export as excel
  downloadFile = async () => {
    const data = await PharmacyService.getPharmacyData(true).then((data) => {
      return data;
    });
    let itemsFormatted = [];
    data.data.pharmacies.forEach((item) => {
      let splitname = item.business_contact_person || '';
      splitname = splitname.split('$$$$');
      let B_first_name = splitname[0];
      let B_last_name = splitname[1];
      let splitPharmacistName = item.pharmacist_name && item.pharmacist_name.split('$$$$');
      let pharm_firstName = splitPharmacistName && splitPharmacistName[0];
      let pharm_lastName = splitPharmacistName && splitPharmacistName[1];
      let splitAddress = item.address.split('$$$$');
      let address_first = splitAddress[0];
      let address_second = splitAddress[1];
      itemsFormatted.push({
        'Pharmacy ID': item.pharmacy_code,
        'Pharmacy Name': item.name.replace(/,/g, ''),
        'Identifier Name': item.identifier_name,
        Phone: item.phone,
        Email: item.email,
        'Created Date': item.created_date,
        Address: address_first,
        'Address Line2': address_second,
        State: item.state,
        City: item.city,
        ZipCode: item.zip_code,
        'Business Contact Person': B_first_name,
        Last: B_last_name,
        'Business Contact Position': item.business_contact_position,
        'Business Contact Phone': item.business_contact_phone,
        'Business Contact Email': item.business_contact_email,
        'Pharmacist First Name': pharm_firstName,
        'Pharmacist Last Name': pharm_lastName,
        'Pharmacist Phone': item.pharmacist_phone,
        'Pharmacist AHPRA Number': item.pharmacist_ahpraNumber,
        'Account Name': item.account_name,
        'Account Number': item.account_number,
        BSB: item.bsb,
        ABN: item.abn,
        'Bank Name': item.bank_name,
        'Authorised Name': item.authorised_first_name,
        'Number of Orders': item.count,
        'Value of Orders(AUD)': item.value,
      });
    });
    let fileTitle = new Date().getTime() + '-montu-pharmacies';
    await axios({
      url: 'excel/pharmacy/download',
      method: 'POST',
      data: itemsFormatted,
      responseType: 'blob',
    }).then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `${fileTitle}.xlsx`);
      document.body.appendChild(link);
      link.click();
    });
  };

  render() {
    const { loading, data, count, sort, pageSize, page, filter } = this.state;
    const fields = [
      { name: 'id', displayName: 'id', sortable: true, visible: false },
      {
        name: 'pharmacy_code',
        displayName: 'Pharmacy ID',
        inputFilterable: true,
        sortable: true,
        visible: true,
        tdClassName: 'sub-text',
        render: (e) => {
          return <Link to={`/pharmacy-account/${e.record.id}`}>{e.record.pharmacy_code}</Link>;
        },
      },
      { name: 'name', displayName: 'Name', inputFilterable: true, sortable: true, tdClassName: 'sub-text' },
      {
        name: 'email',
        displayName: 'Email',
        inputFilterable: true,
        sortable: true,
        tdClassName: 'sub-text',
        sortFieldName: 'business_contact_email',
      },
      { name: 'phone', displayName: 'Phone', inputFilterable: true, sortable: true, tdClassName: 'sub-text' },
      {
        name: 'address',
        displayName: 'Address',
        inputFilterable: true,
        sortable: true,
        tdClassName: 'sub-text',
        render: (e) => {
          return e.value.replace('$$$$', ' ');
        },
      },
      { name: 'city', displayName: 'Suburb', inputFilterable: true, sortable: true, tdClassName: 'sub-text' },
      { name: 'state', displayName: 'State', inputFilterable: true, sortable: true, tdClassName: 'sub-text' },
      { name: 'zip_code', displayName: 'Postcode', inputFilterable: true, sortable: true, tdClassName: 'sub-text' },
      {
        name: 'id',
        displayName: 'Report',
        render: (e) => {
          return (
            <Link to={{ pathname: `/pharmacy/report/${e.value}`, state: e.record.name }} className="btn btn-primary">
              Report
            </Link>
          );
        },
      },
      {
        name: 'id',
        displayName: 'Edit',
        thClassName: 'text-center',
        tdClassName: 'sub-text text-center',
        render: (e) => {
          return (
            <Link to={{ pathname: `/pharmacy-account/${e.value}` }}>
              <img src={editIcon} alt="edit_icon" className="prescriptionIcon" />
            </Link>
          );
        },
      },
    ];

    const orderCustomStyles = {
      content: {
        top: '15%',
      },
    };

    if (this.state.loading) {
      return <CircleLoader />;
    }

    return (
      <div>
        <div className="container-fluid">
          <h3>Pharmacies</h3>
          <div className="float-right">
            {AuthService.isSuperAdmin() ? (
              <div className="btn-group mr-2">
                <button
                  type="button"
                  className="btn btn-info dropdown-toggle"
                  data-bs-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  Pharmacy Update
                </button>
                <div className="dropdown-menu">
                  <label
                    className="dropdown-item mb-0 pharm-dropdown"
                    htmlFor="file-input"
                    onChange={this.uploadfileHandling}
                  >
                    Upload .csv
                    <input id="file-input" name="pharmacy" className="d-none" type="file" />
                  </label>
                  <Link to={{ pathname: '/updatehistory' }} className="dropdown-item pharm-dropdown">
                    View Past Changes
                  </Link>
                  <a
                    className="dropdown-item pharm-dropdown"
                    onClick={() => this.downloadTemplate('Pharmacy Update Template.csv')}
                  >
                    Download Template
                  </a>
                </div>
              </div>
            ) : (
              ''
            )}
            <Link to={{ pathname: '/pharmacy-report' }} className="btn btn-primary mr-2">
              Report
            </Link>
            {AuthService.isSuperAdmin() ? (
              <Button text="Export All" className="btn btn-primary" onClick={this.downloadFile} />
            ) : (
              ''
            )}
            <div className="d-inline" onChange={this.fileHandling}>
              <label className="btn btn-md btn-primary ml-2 mb-0" htmlFor="file-input-pharmacy">
                <span className="px-2">Import</span>
                <input id="file-input-pharmacy" name="pharmacy" className="d-none" type="file" />
              </label>
              <small className="pl-2 text-danger">{this.state.fileError}</small>
            </div>
          </div>
          {loading ? (
            <CircleLoader />
          ) : (
            <FilterableTable
              className="table-responsive"
              data={data}
              fields={fields}
              topPagerVisible={false}
              paginationOnServer={true}
              initialSort={sort.name}
              initialSortDir={!sort.reverse}
              onPageChanged={this.pageChange}
              filterData={this.onFilter}
              serverSort={this.getSortColumn}
              totalRecords={count}
              currentPage={page}
              pageSize={pageSize}
              filterPlaceholder={filter ? filter : 'Search'}
              pageSizes={false}
              maintainPageOnSetData={true}
              noRecordsMessage="There are no record to display"
              noFilteredRecordsMessage="No record match your filters!"
            />
          )}
        </div>

        <CustomModal isOpen={this.state.showOrderUpdatePharmacyModal} style={orderCustomStyles}>
          <>
            <div className="modalheader">
              <h3>Update Order Pharmacy</h3>
            </div>
            <div className="modalbody">
              <table className="table table-bordered table-hover">
                <thead>
                  <tr>
                    <th className="custom-align-center">Order Code</th>
                    <th className="custom-align-center">From Pharmacy</th>
                    <th className="custom-align-center">To Pharmacy</th>
                    <th className="custom-align-center">Repeated Order</th>
                    <th className="custom-align-center">Eligible for Update</th>
                  </tr>
                </thead>
                <tbody>
                  {this.state.orderData.map((or, key) => (
                    <tr
                      key={key}
                      style={
                        !or.ePrescription && or.repeated && !or.both
                          ? { color: 'red' }
                          : !or.eligible
                          ? { color: '#A3B1B8' }
                          : { backgroundColor: 'white' }
                      }
                    >
                      <td className="custom-align-center">{or.orderCode}</td>
                      <td className="custom-align-center">{or.fromPharmacy}</td>
                      <td className="custom-align-center">{or.toPharmacy}</td>
                      <td className="custom-align-center">{or.repeated ? 'Yes' : 'No'}</td>
                      <td className="custom-align-center">{or.eligible ? 'Yes' : 'No'}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            <div className="modalfooter d-flex align-items-center">
              <Button
                text="Update Pharmacies"
                type="submit"
                className="btn btn-primary ml-auto mr-3"
                onClick={this.saveOrderUpdateModal}
              />
              <Button
                text="Cancel"
                type="submit"
                className="btn btn-outline-primary"
                onClick={this.cancelOrderUpdateModal}
              />
            </div>
            <p>
              <b>Color Code Guide:</b>
            </p>
            <ul>
              <b>Black</b>: Order can be reassigned.
            </ul>
            <div className="d-flex">
              <ul style={{ color: 'red' }}>Red</ul>
              <p> : Repeated Paper Script - Order cannot be reassigned.</p>
            </div>
            <div className="d-flex">
              <ul style={{ color: '#A3B1B8' }}>Grey</ul>
              <p> : The Pharmacy selected to update is a Partner Pharmacy - The order cannot be reassigned.</p>
            </div>
          </>
        </CustomModal>
      </div>
    );
  }
}

export default withRouter(MyComponent);
