import { Chip, ChipProps } from '@mui/material';

import ProductFormulationsMap from '@/constants/products/productFormulations';
import { ProductFormulation } from '@/types';

interface FormulationChipProps {
  formulation: ProductFormulation;
  size?: ChipProps['size'];
}

function FormulationChip(props: FormulationChipProps) {
  const { formulation, size } = props;
  const { icon: Icon, displayName, color } = ProductFormulationsMap[formulation];

  return (
    <Chip
      sx={{
        border: `1px solid ${color}`,
      }}
      size={size}
      variant="outlined"
      label={displayName}
      icon={<Icon color="inherit" sx={{ color }} />}
    />
  );
}

export default FormulationChip;
