import { ComponentClass, JSX } from 'react';

import useFeatureFlags from '@/hooks/useFeatureFlags';

export default (Component: ComponentClass<{ [x: string]: any }>) => {
  // NOTE: `[x: string]: any` is required to support legacy jsx class components (with untyped props)
  function ComponentWithFlags(props: JSX.IntrinsicAttributes | { [x: string]: any }) {
    const { flags } = useFeatureFlags();

    return <Component {...props} flags={flags} />;
  }
  return ComponentWithFlags;
};
