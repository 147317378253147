import React from 'react';
import { Link, Typography } from '@mui/material';

import { ActionText, Faq, FaqButton, FaqButtonContainer, FaqContainer } from './OrderHistoryCard.styles';

const umedsFAQButtons = [
  {
    link: 'https://support.umeds.com.au/hc/en-au/articles/10724840280847-Delivery-process',
    text: 'Delivery timeframes',
  },
  {
    link: 'https://support.umeds.com.au/hc/en-au/articles/10724840280847-Delivery-process',
    text: 'Track my order',
  },
  {
    link: 'https://support.umeds.com.au/hc/en-au',
    text: 'Get further assistance',
  },
];

export function FAQButtons() {
  return (
    <FaqContainer>
      <Faq>
        <Typography variant="subtitle1" fontWeight="semibold" sx={(theme) => ({ marginBottom: theme.spacing(6) })}>
          Need help?
        </Typography>
        <FaqButtonContainer>
          {umedsFAQButtons.map((button) => (
            <Link href={button.link} target="_blank">
              <FaqButton variant="outlined" fullWidth>
                <ActionText variant="body1">{button.text}</ActionText>
              </FaqButton>
            </Link>
          ))}
        </FaqButtonContainer>
      </Faq>
    </FaqContainer>
  );
}

export default FAQButtons;
