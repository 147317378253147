import { Link as RouterLink, useLocation } from 'react-router-dom';
import { Box, Button, styled } from '@mui/material';

import { usePortalPartnerBrand } from '@/hooks/portal-partner-brand';

import { PartnerContainer as Container } from './PortalPartner.styles';

const PartnerHeaderContainer = styled(Box)(({ theme }) => ({
  background: theme.palette.primary.main,
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  height: '60px',
}));

function PartnerHeader() {
  const location = useLocation();
  const { Logo: PartnerLogo } = usePortalPartnerBrand();

  return (
    <PartnerHeaderContainer>
      <Container>
        <PartnerLogo />
        {location.pathname !== '/patient/orders' ? (
          <Button variant="contained" color="secondary" component={RouterLink} to="/patient/orders">
            Order history
          </Button>
        ) : null}
      </Container>
    </PartnerHeaderContainer>
  );
}

export default PartnerHeader;
