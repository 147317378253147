import { CreditLineItem } from '@/hooks/patient/refill/types';
import { SelectedProduct } from '@/hooks/patient/refill/useOrderMedicationController';

export function formatPrice(price: number) {
  return new Intl.NumberFormat('en-AU', {
    style: 'currency',
    currency: 'AUD',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  }).format(price);
}

export function getCheckoutData(selectedProducts: SelectedProduct[], creditsDiscounts: CreditLineItem[]) {
  const products =
    selectedProducts
      .filter((p) => p.data.quantity >= 1)
      .map((selectedProduct) => ({
        id: selectedProduct.data.product_id,
        name: selectedProduct.data.product_name,
        price: selectedProduct.data.price,
        quantity: selectedProduct.data.quantity,
        supplier: selectedProduct.data.supplier,
      })) || [];

  const totalNumberOfProducts = products.reduce((total, { quantity }) => total + quantity, 0);

  const discounts = creditsDiscounts
    .filter((creditOrDiscount) => creditOrDiscount.isDiscount)
    .map((discount) => {
      const discountAmount = Math.abs(
        typeof discount.amount === 'number'
          ? Math.round(discount.amount)
          : Math.round(parseFloat(discount.amount)) || 0,
      );

      return {
        id: discount.id,
        amount: discountAmount,
        display: discount.name,
        formattedAmount: discountAmount < 0 ? formatPrice(discountAmount) : `-${formatPrice(discountAmount)}`,
      };
    });

  const productPrice = products.reduce((total, { price, quantity }) => total + price * quantity, 0);
  const discountPrice = discounts.reduce((total, { amount }) => total + amount, 0);
  const subTotalPrice = productPrice;
  const formattedSubTotalPrice = formatPrice(subTotalPrice);
  const totalPrice = productPrice - discountPrice;
  const formattedTotalPrice = formatPrice(totalPrice);

  return {
    products,
    totalNumberOfProducts,
    totalPrice,
    subTotalPrice,
    discounts,
    formattedTotalPrice,
    formattedSubTotalPrice,
  };
}

export default getCheckoutData;
