import { Prescription } from '@/components/patient/prescription/common';
import { IPrescriptions } from '@/pages/patient/PatientPrescription';

export function sortPrescriptionsByCreatedDate(prescriptions: Prescription[]): Prescription[] {
  return prescriptions.sort((a, b) => new Date(b.createdDate).getTime() - new Date(a.createdDate).getTime());
}

function processPrescriptions(prescriptionsMap: Record<string, IPrescriptions>) {
  return Object.values(prescriptionsMap).map((group) => ({
    ...group,
    prescriptions: sortPrescriptionsByCreatedDate(group.prescriptions),
  }));
}

export function transformPrescriptions(data: Prescription[] | undefined) {
  if (!data) return { activePrescriptions: [], inactivePrescriptions: [] };

  const activePrescriptionsMap: Record<string, IPrescriptions> = {};
  const inactivePrescriptionsMap: Record<string, IPrescriptions> = {};

  data.forEach((item) => {
    const isInactive = item.inActive;

    // if a script has at least 1 or more refills and is active, show as active. Otherwise, it has expired, ceased, or no refills left
    const map =
      !isInactive && item.status.toLowerCase() === 'active' ? activePrescriptionsMap : inactivePrescriptionsMap;

    if (!map[item.presciptionCode]) {
      map[item.presciptionCode] = {
        prescriptionCode: item.presciptionCode,
        prescriptions: [],
      };
    }
    map[item.presciptionCode].prescriptions.push(item);
  });

  const activePrescriptions = processPrescriptions(activePrescriptionsMap);
  const inactivePrescriptions = processPrescriptions(inactivePrescriptionsMap);

  return {
    activePrescriptions,
    inactivePrescriptions,
  };
}
