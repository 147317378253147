import { MdOutlineWarningAmber } from 'react-icons/md';
import { Box, Link, styled, Typography } from '@mui/material';

const MessageBox = styled(Box)(({ theme }) => ({
  padding: '6px 16px',
  border: `1px solid ${theme.palette.warning.main}`,
  borderRadius: '4px',
  display: 'flex',
  gap: '1rem',
  marginBottom: '40px',
}));

const IconContainer = styled(Box)(({ theme }) => ({
  height: '22px',
  width: '22px',
  padding: '7px 12px 7px 0px',
  color: theme.palette.warning.main,
}));

const TextContainer = styled(Box)`
  display: flex;
  padding: 8px 0px;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;
  flex: 1 0 0;
`;

function LoginFailure({ error, showSupportText }: { error: string; showSupportText?: boolean }) {
  if (!error) return null;

  return (
    <MessageBox role="alert">
      <IconContainer>
        <MdOutlineWarningAmber size={22} />
      </IconContainer>
      <TextContainer>
        <Typography>
          <b>{error}</b>
          {showSupportText && (
            <span data-testid="support-text">
              {' '}
              Please try our{' '}
              <Link
                href="https://support.alternaleaf.com.au/hc/en-au/articles/10869733610639-Technical-support"
                rel="noopener noreferrer"
                target="_blank"
              >
                troubleshooting guide
              </Link>{' '}
              before contacting support.
            </span>
          )}
        </Typography>
      </TextContainer>
    </MessageBox>
  );
}

export default LoginFailure;
