import React from 'react';
import { Link } from 'react-router-dom';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { Card, CardActions, CardContent, Divider, styled, Typography } from '@mui/material';

const CardContainer = styled(Card)`
  width: 380px;
  height: 300px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const SeeMoreLink = styled(Link)`
  display: flex;
  gap: 0.25rem;
  align-items: center;
`;

type ResourceCardProps = {
  title: string;
  body: string;
  link?: string;
};

function ResourceCard({ title, body, link }: ResourceCardProps) {
  return (
    <CardContainer variant="outlined">
      <CardContent>
        <Typography variant="h5" component="h4">
          {title}
        </Typography>
        <Divider sx={{ margin: '1rem 0' }} />
        <Typography>{body}</Typography>
      </CardContent>
      {link && (
        <CardActions>
          <SeeMoreLink to={link}>
            See more <ArrowForwardIcon fontSize="small" />
          </SeeMoreLink>
        </CardActions>
      )}
    </CardContainer>
  );
}

export default ResourceCard;
