import React from 'react';
import { Box, styled, Typography } from '@mui/material';

const Banner = styled(Box)`
  background-image: url('/assets/images/montu-banner.jpg');
  background-size: cover;
  background-position: center;
  height: 270px;
`;

const BannerTransform = styled(Box)`
  background-color: rgba(0, 0, 0, 0.1);
  width: 100%;
  height: 100%;
`;

const BannerContent = styled(Box)`
  display: flex;
  height: 100%;
  color: white;
  text-align: center;
  flex-direction: column;
  justify-content: center;
`;

const PText = styled(Typography)`
  margin-top: 3rem;
  font-weight: bold;
  margin-bottom: 0.25rem;
`;

type TopBannerProps = {
  pText: string;
  h1Text: string;
};

function TopBanner({ pText, h1Text }: TopBannerProps) {
  return (
    <Banner data-testid="top-banner">
      <BannerTransform>
        <BannerContent>
          <PText>{pText}</PText>
          <Typography variant="h3" component="h1" fontWeight="bold">
            {h1Text}
          </Typography>
        </BannerContent>
      </BannerTransform>
    </Banner>
  );
}

export default TopBanner;
