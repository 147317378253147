import { useState } from 'react';
import { Button } from '@montugroup/design-system';
import { Box, styled } from '@mui/material';

import DateRangeComp from '@/components/daterange/DateRangeComp';
import MergePatientModal from '@/components/patient/mergePatientModal/mergePatientModal';
import useDownload from '@/hooks/useDownload';
import AuthService from '@/services/authentication.service';

const ActionsContainer = styled(Box)`
  display: flex;
  justify-content: end;
`;

const ButtonContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  gap: theme.spacing(2),
}));

const CalendarWrapper = styled(Box)(({ theme }) => ({
  position: 'absolute',
  zIndex: 1,
  display: 'flex',
  flexDirection: 'column',
  overflow: 'hidden',
  marginTop: theme.spacing(0.4),
  right: theme.spacing(8),
}));

function ViewPatientsActions() {
  const [showExportDateRangePicker, setShowExportDateRangePicker] = useState<boolean>(false);
  const [showMergePatientModal, setShowMergePatientModal] = useState<boolean>(false);
  const { downloadFile } = useDownload();

  const exportAll = async (fromDate: string, toDate: string) => {
    const fileName = `${new Date().getTime()}-montu-patients.csv`;

    await downloadFile(fileName, 'excel/patient/download', {
      method: 'POST',
      responseType: 'blob',
      data: {
        from_date: fromDate,
        to_date: toDate,
      },
    });
  };

  return (
    <ActionsContainer>
      <Box>
        {AuthService.isSuperAdmin() && (
          <>
            <ButtonContainer>
              <Button size="small" onClick={() => setShowMergePatientModal(true)}>
                Merge patients
              </Button>
              <Button size="small" color="info" onClick={() => setShowExportDateRangePicker(true)}>
                Export all
              </Button>
            </ButtonContainer>

            <MergePatientModal onClick={() => setShowMergePatientModal(false)} open={showMergePatientModal} />
          </>
        )}
        {showExportDateRangePicker && (
          <CalendarWrapper>
            <DateRangeComp open closeCalendar={() => setShowExportDateRangePicker(false)} onChange={exportAll} />
          </CalendarWrapper>
        )}
      </Box>
    </ActionsContainer>
  );
}

export default ViewPatientsActions;
