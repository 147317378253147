import { useQuery } from 'react-query';
import axios from 'axios';

import settings from '@/constants/constants';
import USER_ROLES from '@/constants/userRoles';
import AuthService from '@/services/authentication.service';
import { ClinicProps } from '@/types/clinic.types';

const useFetchClinics = () => {
  const { url } = settings;

  const fetchClinics = async () => {
    try {
      const user = AuthService.getUser()?.user;

      if (user?.role_id === USER_ROLES.doctor) {
        const response = await axios.get<ClinicProps[]>(`${url}/clinic/gp/view`);
        return response.data;
      }
      if (AuthService.isAdmin()) {
        const response = await axios.get<ClinicProps[]>(`${url}/clinic`);
        return response.data;
      }

      throw new Error('User is not authorized to fetch clinic data');
    } catch (err) {
      throw new Error('Error fetching clinic data');
    }
  };

  const { data: clinicData = [], isLoading: loading, error } = useQuery(['clinicViews'], fetchClinics);

  return { clinicData, loading, error };
};

export default useFetchClinics;
