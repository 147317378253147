import { ChangeEvent, useState } from 'react';
import { useMutation } from 'react-query';
import SimpleReactValidator from '@crystaldelta/simple-react-validator';

interface FormData {
  email: string;
  password: string;
  remember: boolean;
  loginType: string;
  onBoardKey?: string;
}

function usePharmacistForm(initialState: FormData) {
  const [data, setData] = useState<FormData>(initialState);
  const validator = new SimpleReactValidator();
  const [errors, setErrors] = useState({
    email: validator.message('email', data.email.trim(), 'required|email'),
    password: validator.message('password', data.password, 'required'),
  });

  const handleUserInput = (prop: string) => (e: ChangeEvent<HTMLInputElement>) => {
    setData({ ...data, [e.target.name]: prop === 'checkbox' ? e.target.checked : e.target.value });
  };

  const mutation = useMutation(async (formData: FormData) => {
    const response = await fetch('/api/login', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(formData),
    });
    if (!response.ok) {
      throw new Error('Login failed');
    }
    return response.json();
  });

  const handleSubmit = async () => {
    if (!validator.allValid()) {
      validator.showMessages();
      setErrors({
        email: validator.message('email', data.email, 'required|email'),
        password: validator.message('password', data.password, 'required'),
      });
      return;
    }

    mutation.mutate(data);
  };

  return {
    data,
    errors,
    setErrors,
    handleUserInput,
    validator,
    handleSubmit,
    isLoading: mutation.isLoading,
  };
}

export default usePharmacistForm;
