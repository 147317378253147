import React from 'react';
import { Box, styled, Typography } from '@mui/material';

import ManagedErrorToasts from '@/components/error/ManagedErrorToasts';
import PatientsTable from '@/components/gp/PatientsTable';

const PatientsContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  padding: 0 1rem;
`;

function GPPatients() {
  return (
    <PatientsContainer>
      <ManagedErrorToasts />
      <Typography variant="h4" component="h3">
        Patients
      </Typography>
      <PatientsTable />
    </PatientsContainer>
  );
}

export default GPPatients;
