import { ComponentClass, JSX } from 'react';
import { Location, NavigateFunction, Params, useLocation, useNavigate, useParams } from 'react-router-dom';

type RouteProps = {
  location: Location;
  navigate: NavigateFunction;
  params: Readonly<Params<string>>;
};

export default (Component: ComponentClass<RouteProps>) => {
  // NOTE: `[x: string]: any` is required to support legacy jsx class components (with untyped props)
  function ComponentWithRouterProp(props: JSX.IntrinsicAttributes | { [x: string]: any }) {
    const location = useLocation();
    const navigate = useNavigate();
    const params = useParams();

    return <Component location={location} navigate={navigate} params={params} {...props} />;
  }
  return ComponentWithRouterProp;
};
