import { Box, styled } from '@mui/material';

const PageContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  margin: 0 1.6rem;
`;

export default PageContainer;
