import { ReactNode } from 'react';
import { styled } from '@mui/material';

import { PartnerContainer as Container } from './PortalPartner.styles';

export const ContentContainer = styled(Container)(({ theme }) => ({
  width: '100%',
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  paddingTop: theme.spacing(6),
  paddingBottom: theme.spacing(6),
}));

export type PartnerContentProps = {
  children: ReactNode;
};

function PartnerContent({ children }: PartnerContentProps) {
  return <ContentContainer>{children}</ContentContainer>;
}

export default PartnerContent;
