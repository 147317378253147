import { useEffect, useRef } from 'react';

import settings from '../../constants/constants';

function ZendeskChatWidget() {
  const scriptRef = useRef<HTMLScriptElement | null>(null);

  useEffect(() => {
    if (!scriptRef.current) {
      const script = document.createElement('script');

      script.id = 'ze-snippet';
      script.src = `https://static.zdassets.com/ekr/snippet.js?key=${settings.ZENDESK_CHAT_KEY}`;
      script.async = true;

      document.body.appendChild(script);
      scriptRef.current = script;
    }

    return () => {
      if (scriptRef.current) {
        document.body.removeChild(scriptRef.current);
        scriptRef.current = null;
      }
    };
  }, []);

  return null;
}

export default ZendeskChatWidget;
