import { styled } from '@mui/material';

import MontuIcon from '@/components/common/MontuIcon';
import MontuLogo from '@/components/common/MontuLogo';
import UmedsIcon from '@/components/common/UmedsIcon';
import UmedsLogo from '@/components/common/UmedsLogo';

const MontuLogoWithSx = styled(MontuLogo)({});
const MontuIconWithSx = styled(MontuIcon)({});
const UmedsLogoWithSx = styled(UmedsLogo)({});
const UmedsIconWithSx = styled(UmedsIcon)({});

export const PartnerKey = {
  MONTU: 'montu',
  UMEDS: 'uMeds',
} as const;

const PartnerComponents = {
  [PartnerKey.UMEDS]: {
    name: 'uMeds',
    Logo: UmedsLogoWithSx,
    Icon: UmedsIconWithSx,
  },
  [PartnerKey.MONTU]: {
    name: 'Montu',
    Logo: MontuLogoWithSx,
    Icon: MontuIconWithSx,
  },
} as const;

export function usePortalPartnerBrand() {
  const key = PartnerKey.UMEDS;
  const components = PartnerComponents[key];

  return { key, ...components };
}

export default usePortalPartnerBrand;
