import { AccordionDetails, AccordionSummary, Alert, Box, styled, Typography } from '@mui/material';
import { accordionSummaryClasses } from '@mui/material/AccordionSummary';

import { Button } from '@/ui-library';

export const StyledAccordionSummary = styled(AccordionSummary)(({ theme }) => ({
  minHeight: theme.spacing(12),
  borderRadius: 0,
  margin: 0,
  paddingTop: theme.spacing(5),
  paddingBottom: theme.spacing(5),
  paddingLeft: 0,
  [theme.breakpoints.up('md')]: {
    paddingRight: theme.spacing(5),
  },
  [`&.${accordionSummaryClasses.root}.${accordionSummaryClasses.expanded}`]: {
    minHeight: theme.spacing(12),
  },
  [`& .${accordionSummaryClasses.content}`]: {
    gap: theme.spacing(2),
    margin: 0,
  },
  [`& .${accordionSummaryClasses.content}.${accordionSummaryClasses.expanded}`]: {
    margin: 0,
  },
}));

export const StyledAccordionDetails = styled(AccordionDetails)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(5),
  paddingLeft: 0,
  paddingRight: 0,
  paddingBottom: theme.spacing(5),
  [theme.breakpoints.up('lg')]: {
    flexDirection: 'row',
    gap: theme.spacing(6),
  },
}));

export const Wrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  borderBottom: `1px solid ${theme.palette.divider}`,
}));

export const OrderNumberContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'left',
  marginBottom: theme.spacing(2),
}));

export const OrderDetailsContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(1),
}));

export const OrderStatusContainer = styled(Box)(({ theme }) => ({
  flexDirection: 'row',
  gap: theme.spacing(1),
  marginLeft: theme.spacing(6),
  display: 'none',
  [theme.breakpoints.up('lg')]: {
    display: 'flex',
  },
}));

export const OrderTrackingButtonContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  gap: theme.spacing(1),
  marginTop: theme.spacing(3),
  width: '100%',
  fontSize: '1rem',
}));

export const OrderItemsContainer = styled(Box)(({ theme }) => ({
  borderRadius: theme.spacing(2),
  marginBottom: theme.spacing(6),
  [theme.breakpoints.up('lg')]: {
    background: '#EBEEEF',
    padding: theme.spacing(5),
    marginBottom: theme.spacing(6),
  },
}));

export const PharmacyDetailsContainer = styled(Box)(({ theme }) => ({
  border: '1px',
  borderStyle: 'solid',
  borderColor: '#DEDEE1',
  padding: theme.spacing(5),
  borderRadius: theme.spacing(1),
  marginBottom: theme.spacing(6),
}));

export const SplitOrderPharmacyDetailsContainer = styled(Box)(({ theme }) => ({
  border: '1px',
  borderStyle: 'solid',
  borderColor: '#DEDEE1',
  padding: theme.spacing(5),
  borderRadius: theme.spacing(1),
  marginBottom: theme.spacing(5),
}));

export const OrderSummaryContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column-reverse',
  width: '100%',
  [theme.breakpoints.up('lg')]: {
    width: '70%',
    flexDirection: 'column',
  },
}));

export const FaqContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  [theme.breakpoints.up('lg')]: {
    width: '30%',
    alignItems: 'flex-start',
  },
}));

export const FaqButtonContainer = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  '& > :first-child > *': {
    marginTop: 0,
  },
}));

export const Faq = styled(Box)(({ theme }) => ({
  flexDirection: 'column',
  background: '#F2EFE4',
  padding: theme.spacing(5),
  borderRadius: theme.spacing(1),
  width: '100%',
}));

export const ActionText = styled(Typography)(({ theme }) => ({
  fontWeight: 600,
  padding: theme.spacing(2),
  color: theme.palette.primary.main,
  borderColor: theme.palette.grey[50],
}));

export const FaqButton = styled(Button)(({ theme }) => ({
  marginTop: theme.spacing(2),
}));

export const OrderStatusChipMobile = styled(Box)(({ theme }) => ({
  display: 'flex',
  marginTop: theme.spacing(3),
  [theme.breakpoints.up('lg')]: {
    display: 'none',
  },
}));

export const SplitOrderAlert = styled(Alert)(({ theme }) => ({
  order: 1,
  backgroundColor: theme.palette.info.light,
  marginBottom: theme.spacing(4),
  paddingLeft: theme.spacing(2),
  paddingRight: theme.spacing(2),
  [theme.breakpoints.up('lg')]: {
    marginTop: 0,
    marginBottom: theme.spacing(6),
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
    order: 0,
  },
}));

export const SplitOrderDetailsContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  whiteSpace: 'pre-wrap',
  alignItems: 'start',
  gap: theme.spacing(1),
  marginTop: theme.spacing(3),
  [theme.breakpoints.up('lg')]: {
    flexDirection: 'row',
    alignItems: 'center',
    gap: 0,
    marginTop: 0,
  },
}));

export const SplitOrderStatusChip = styled(Box)(({ theme }) => ({
  marginLeft: 0,
  [theme.breakpoints.up('lg')]: {
    marginLeft: theme.spacing(2),
  },
}));

export const ReceiptButton = styled(Button)(({ theme }) => ({
  marginBottom: theme.spacing(5),
  [theme.breakpoints.up('lg')]: {
    marginBottom: 0,
  },
}));

export const OrderInvoiceButtonContainer = styled(Box)(({ theme }) => ({
  width: '100%',
  [theme.breakpoints.up('lg')]: {
    marginTop: theme.spacing(5),
  },
}));

export const DesktopReceiptButtonContainer = styled(Box)(({ theme }) => ({
  display: 'none',
  [theme.breakpoints.up('lg')]: {
    display: 'block',
  },
}));

export const MobileReceiptButtonContainer = styled(Box)(({ theme }) => ({
  display: 'block',
  [theme.breakpoints.up('lg')]: {
    display: 'none',
  },
}));
