import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Container, styled } from '@mui/material';

import CircleLoader from '@/components/common/circleLoader';
import Error from '@/components/error/Error';
import UnauthenticatedLayout from '@/components/layout/UnauthenticatedLayout';
import settings from '@/constants/constants';
import { AuthService } from '@/services/authentication.service';
import { PaymentService } from '@/services/payment.service';
import { Logger } from '@/utils/logger';

const logger = new Logger('PaymentConfirm');

const ContentContainer = styled(Container)`
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

function PaymentConfirm() {
  const [error, setError] = useState<boolean>(false);

  const navigate = useNavigate();
  const { state, search } = useLocation();

  const orderCode = state?.from?.state?.orderCode || PaymentService.getOrderCode();
  const invoiceToken = PaymentService.getInvoiceToken();

  // Cant use useParams() here as the link from Shopify will end with /payment-confirm/?<our params>
  const params = new URLSearchParams(search);
  const redirect = params.get('redirectTo');

  const loggedInPatient = AuthService.isPatient();

  /**
   * UI Extensions that want to take the patient to a particular page direct to this page with the redirectTo param.
   *
   * Current redirects in place:
   *   redirectTo=refill -> /patient/refill ('Back to refill' button)
   *   redirectTo=profile -> /profile-detail (Address validation extension)
   */
  const handleRedirect = () => {
    let path;
    switch (redirect) {
      case 'refill':
        path = '/patient/refill';
        break;
      case 'home':
        path = '/home';
        break;
      default:
        path = '/profile-detail';
    }

    if (!loggedInPatient) {
      navigate('/login/patient', { state: { from: { pathname: path } } });
    } else {
      navigate(path);
    }
  };

  /**
   * Returning to circuit from a non-invoice flow
   */
  const handleRefill = async () => {
    if (!loggedInPatient) {
      // Unexpected for refill flow - push to redirect and then back here
      navigate('/login/patient', { state: { orderCode, from: { pathname: '/payment-confirm' } } });
    } else if (!orderCode) {
      logger.error('orderCode not found');
      setError(true);
    } else {
      const {
        status,
        isPaid,
        transactionType,
        bankTransferReference,
        error: responseError,
      } = await PaymentService.checkPaymentStatus(orderCode);

      if (isPaid || transactionType === settings.transactionType.BANK) {
        PaymentService.clearOrderCode();
        navigate('/patient/payment-success', { state: { orderCode, transactionType, bankTransferReference } });
      } else {
        if (responseError) {
          logger.error('Error checking payment status', status, responseError);
        } else {
          logger.error('Unknown error checking payment status');
        }
        setError(true);
      }
    }
  };

  const handleInvoice = async () => {
    if (!invoiceToken || !orderCode) {
      logger.error(`${!invoiceToken ? 'invoiceToken' : 'orderCode'} not found`);
      setError(true);
    } else {
      const {
        status,
        isPaid,
        transactionType,
        bankTransferReference,
        user,
        onBoardKey,
        error: responseError,
      } = await PaymentService.checkPaymentStatusForInvoice(invoiceToken, orderCode);
      if (isPaid || transactionType === settings.transactionType.BANK) {
        PaymentService.clearInvoiceToken();
        PaymentService.clearOrderCode();
        const { firstName, email } = user;
        navigate('/payment-success', {
          state: {
            firstName,
            email,
            onBoardKey,
            orderCode,
            transactionType,
            bankTransferReference,
          },
        });
      } else {
        if (responseError) {
          logger.error('Error checking invoice payment status', status, responseError);
        } else {
          logger.error('Unknown error checking invoice payment status');
        }
        setError(true);
      }
    }
  };

  /**
   * Fallback to direct patient somewhere when expected conditions aren't met
   */
  const handleUnexpected = () => {
    logger.warn('Page loaded without orderCode.');
    if (loggedInPatient) {
      navigate('/patient/orders');
    } else {
      navigate('/login/patient', { state: { from: { pathname: '/patient/orders' } } });
    }
  };

  useEffect(() => {
    if (redirect) {
      handleRedirect();
    } else if (orderCode && invoiceToken) {
      handleInvoice();
    } else if (orderCode) {
      handleRefill();
    } else {
      handleUnexpected();
    }
  }, []);

  return (
    <UnauthenticatedLayout>
      {!error && <CircleLoader />}
      {error && (
        <ContentContainer>
          <Error />
        </ContentContainer>
      )}
    </UnauthenticatedLayout>
  );
}

export default PaymentConfirm;
