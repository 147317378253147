import { MgPrescription } from '@montugroup/icons';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import { Box, Skeleton, Stack, styled, Typography, useMediaQuery, useTheme } from '@mui/material';
import { DateTime } from 'luxon';

import CardButton from '@/components/buttons/CardButton';
import OrderMedicationCard from '@/components/card/OrderMedicationCard';
import PageHeading from '@/components/common/PageHeading';
import HelpDeskPopup from '@/components/helpDesk/HelpDeskSelector';
import { MainContainer } from '@/components/layout/LayoutStyledComponents';
import PatientReferralView from '@/components/patientReferral/patientReferralView';
import { FF_ENABLE_WOM_CAMPAIGN } from '@/constants/featureFlags';
import useGetConsultations from '@/hooks/consultations/useGetConsultations';
import useGetGreeting from '@/hooks/patient/useGetGreeting';
import useGetPatientOrderSummary from '@/hooks/patient/useGetPatientOrderSummary';
import useFeatureFlags from '@/hooks/useFeatureFlags';
import useSetDatadogUser from '@/hooks/useSetDatadogUser';

import { AuthService } from '../../services/authentication.service';

const LayoutContainer = styled(Stack)(({ theme }) => ({
  flexDirection: 'column',
  gap: theme.spacing(4),
  [theme.breakpoints.up('md')]: {
    flexDirection: 'row',
  },
}));

export default function PatientHome() {
  const user = AuthService.getUser()?.user;
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const { flags } = useFeatureFlags();

  useSetDatadogUser('patientHome');

  const greeting = useGetGreeting();
  const { data: orderSummary, isLoading } = useGetPatientOrderSummary();

  const {
    consultData,
    isLoading: isConsultLoading,
    isSuccess,
  } = useGetConsultations({
    when: 'upcoming',
    page: {
      size: 1,
    },
  });

  const renderConsultationCard = () => {
    if (isConsultLoading) {
      return (
        <Skeleton
          variant="rectangular"
          width="100%"
          height="7.5rem"
          sx={{
            borderRadius: '8px',
          }}
        />
      );
    }

    if (isSuccess && consultData.length > 0) {
      const consultation = consultData[0];
      const startTime = DateTime.fromISO(consultation.startTime).toFormat('hh:mm');
      const endTime = DateTime.fromISO(consultation.endTime).toFormat('hh:mm a');
      const longDate = DateTime.fromISO(consultation.startTime).toFormat('cccc, dd LLLL');
      return (
        <CardButton
          title="Your upcoming consultation"
          subtitle={longDate}
          datetime={`${startTime} - ${endTime}`}
          icon={<CalendarTodayIcon />}
          accentColor="secondary"
          link="/patient/consultations"
        />
      );
    }

    return (
      <CardButton
        title="Book and view consultations"
        subtitle="View new consultations and review past sessions here"
        icon={<CalendarTodayIcon />}
        accentColor="secondary"
        link="/patient/consultations"
      />
    );
  };

  const orderMedicationCard = isLoading ? (
    <Stack flex={1}>
      <Skeleton
        variant="rectangular"
        width="100%"
        height="100%"
        sx={{
          borderRadius: '8px',
        }}
      />
    </Stack>
  ) : (
    <Stack flex={1}>
      <OrderMedicationCard
        primaryButtonText="Create order"
        primaryButtonLink="/patient/refill"
        {...(orderSummary?.hasOrders && {
          secondaryButtonText: 'View orders',
          secondaryButtonLink: '/patient/orders',
        })}
      />
    </Stack>
  );

  return (
    <Stack direction="column" justifyContent="space-between" height="100vh">
      <MainContainer>
        <Box maxWidth="lg" pb={{ xs: 10, md: 0 }}>
          <Typography component="h1">
            {greeting}
            {user?.first_name && `, ${user.first_name}`}
          </Typography>
          <PageHeading
            component="h2"
            fontWeight={500}
            marginTop={theme.spacing(2)}
            marginRight={theme.spacing(10)}
            lineHeight={theme.spacing(10)}
          >
            You're on your way to better health
          </PageHeading>
          <LayoutContainer mt={{ xs: 6, md: 10 }}>
            {isMobile && orderMedicationCard}
            <Stack gap={theme.spacing(4)} flex={1}>
              <Stack>{renderConsultationCard()}</Stack>
              <Stack>
                <CardButton
                  title="Medicine information"
                  subtitle="Discover details about the products you've been prescribed"
                  icon={<MgPrescription />}
                  accentColor="primary"
                  link="/medicine-information"
                />
              </Stack>
            </Stack>
            {!isMobile && orderMedicationCard}
          </LayoutContainer>
          <Stack pt={4}>
            <HelpDeskPopup />
          </Stack>
        </Box>
      </MainContainer>
      {flags[FF_ENABLE_WOM_CAMPAIGN] && <PatientReferralView />}
    </Stack>
  );
}

PatientHome.displayName = 'PatientHome';
