import { useState } from 'react';
import { Close as CloseIcon } from '@mui/icons-material';
import { Box, Button, Modal, styled, Typography } from '@mui/material';

import { FF_ENABLE_NOTICE_ON_MODAL, FF_WOM_PRICE, FF_WOM_TNC_COPY } from '@/constants/featureFlags';
import useFeatureFlags from '@/hooks/useFeatureFlags';
import useGoogleAnalytics from '@/hooks/useGoogleAnalytics';

import { GoogleAnalyticsEventName } from '../../types';

const ModalContainer = styled(Box)(({ theme }) => ({
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 'calc(100% - 2rem)',
  maxWidth: '340px',
  borderRadius: '4px',
  backgroundColor: theme.palette.background.paper,
  padding: '2rem 1.5rem',
  boxShadow: '0px 11px 15px -7px #00000033',
  [theme.breakpoints.up('md')]: {
    maxWidth: '500px',
    padding: '2rem 1.9rem',
  },
}));

const ModalHeader = styled(Typography)(() => ({
  textAlign: 'center',
  fontSize: '1.5rem',
  padding: '1rem 2rem',
  fontWeight: 600,
  color: '#181B25DE',
}));

const ImgContainer = styled(Typography)(() => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
}));

function PatientLoginModal() {
  const [open, setOpen] = useState(true);
  const { sendGoogleAnalyticsGTagEvent } = useGoogleAnalytics();
  const { flags } = useFeatureFlags();
  const isNoticeEnabled = flags[FF_ENABLE_NOTICE_ON_MODAL];
  const womPrice = flags[FF_WOM_PRICE];
  const priceAfterDiscount = 59 - womPrice;
  const womTncCopy = flags[FF_WOM_TNC_COPY];

  const onClose = (_event: MouseEvent, reason: string) => {
    if (reason === 'backdropClick') {
      sendGoogleAnalyticsGTagEvent(GoogleAnalyticsEventName.LOGIN_MODAL_OUTSIDE_CLICK, {
        event_category: 'login_modal_wom_campaign',
        event_label: 'modal_outside_click',
      });
    }
    setOpen(false);
  };

  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="login-patient-referral-modal"
      aria-describedby="modal used to introduct word of mouth referral to user"
    >
      <ModalContainer>
        <Button
          aria-label="close"
          onClick={() => {
            sendGoogleAnalyticsGTagEvent(GoogleAnalyticsEventName.LOGIN_MODAL_CLOSE_CLICK, {
              event_category: 'login_modal_wom_campaign',
              event_label: 'modal_close_click',
            });
            setOpen(false);
          }}
          variant="text"
          sx={{
            position: 'absolute',
            top: '1rem',
            right: '1rem',
            color: (theme) => theme.palette.grey[500],
          }}
          data-testid="close-modal-button"
        >
          <CloseIcon />
        </Button>
        {!isNoticeEnabled && (
          <ImgContainer>
            <img src="/assets/images/word_of_mouth.png" alt="Circuit" />
          </ImgContainer>
        )}
        <ModalHeader id="refer-modal-title" variant="h4">
          {isNoticeEnabled ? 'Scheduled Maintenance Notice' : 'Share the Care'}
        </ModalHeader>
        <Typography
          textAlign="center"
          marginBottom="1.5rem"
          sx={{
            whiteSpace: 'pre-line',
          }}
        >
          {isNoticeEnabled
            ? 'Please be advised that the Alternaleaf Patient Portal will be unavailable tonight between approximately 11 PM and 1 AM AEST, due to scheduled maintenance. \n\nPatients may not be able to log in to their accounts during this time. \n\nWe apologise for any inconvenience and thank you for your patience. '
            : `Help your friends and family access quality healthcare with a $${womPrice} discount on their initial consultation. Only $${priceAfterDiscount} instead of $59. Login to reveal your unique code.`}
        </Typography>

        <Button
          fullWidth
          sx={{
            margin: '1rem 0',
            boxShadow: '0px 3px 1px -2px #00000033',
            letterSpacing: '1px',
          }}
          onClick={() => {
            sendGoogleAnalyticsGTagEvent(GoogleAnalyticsEventName.LOGIN_MODAL_CONTINUE_CLICK, {
              event_category: 'login_modal_wom_campaign',
              event_label: 'modal_continue_click',
            });
            setOpen(false);
          }}
          variant="contained"
        >
          Continue to login
        </Button>
        {!isNoticeEnabled && (
          <>
            <Typography
              sx={{
                fontSize: '12px',
                textAlign: 'center',
                width: '100%',
                color: '#181B2561',
              }}
            >
              {womTncCopy} Further terms & conditions apply:
              <a
                href="https://www.alternaleaf.com.au/share-terms"
                className="btn"
                style={{
                  textDecoration: 'underline',
                  fontSize: '12px',
                  color: 'inherit',
                  marginLeft: '-0.5rem',
                  lineHeight: '0.7',
                }}
                target="_blank"
                rel="noopener noreferrer"
                onClick={() =>
                  sendGoogleAnalyticsGTagEvent(GoogleAnalyticsEventName.STC_TERMS_CONDITIONS, {
                    event_category: 'stc_terms_conditions',
                    event_label: 'terms_conditions_link',
                  })
                }
              >
                View here
              </a>
            </Typography>
            <Typography
              sx={{
                fontSize: '12px',
                textAlign: 'center',
                width: '100%',
                color: '#181B2561',
              }}
            >
              Our healthcare services may not be suitable for everyone. Speak to one of our health professionals to
              determine whether our services are appropriate. Treatment can affect every person differently, due to
              their personal circumstances.
            </Typography>
          </>
        )}
      </ModalContainer>
    </Modal>
  );
}

export default PatientLoginModal;
