import React from 'react';
import ProductEdit from '../../components/products/productEdit';
import ProductReviews from '../../components/products/productReview';
import { useParams } from 'react-router-dom';

const tabs = {
  PRODUCT_EDIT: 'product_edit',
  PRODUCT_REVIEWS: 'product_review',
};

const ProductDetails = (props) => {
  const params = useParams();
  const [state, setState] = React.useState({
    activeTab: tabs.PRODUCT_EDIT,
  });

  const toggleTab = (activeTab) => {
    setState({ activeTab: activeTab });
  };

  return (
    <React.Fragment>
      <div className="row">
        <div className="col-lg-12 text-center">
          <div className="btn-group" role="group">
            {params.id !== 'new' && (
              <>
                <button
                  type="button"
                  className={state.activeTab === tabs.PRODUCT_EDIT ? 'btn btn-primary' : 'btn btn-outline-primary'}
                  onClick={() => toggleTab(tabs.PRODUCT_EDIT)}
                >
                  Product Details
                </button>
                <button
                  type="button"
                  className={state.activeTab === tabs.PRODUCT_REVIEWS ? 'btn btn-primary' : 'btn btn-outline-primary'}
                  onClick={() => toggleTab(tabs.PRODUCT_REVIEWS)}
                >
                  Product Reviews
                </button>
              </>
            )}
          </div>
        </div>
      </div>
      {state.activeTab === tabs.PRODUCT_EDIT && <ProductEdit {...props} />}
      {state.activeTab === tabs.PRODUCT_REVIEWS && <ProductReviews {...props} product_id={params.id} />}
    </React.Fragment>
  );
};

export default ProductDetails;
