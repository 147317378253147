import React from 'react';
import * as braze from '@braze/web-sdk';
import { Box, Typography } from '@mui/material';
import { green } from '@mui/material/colors';

import { Button } from '@/ui-library';

const ContentCardDefaultStyling = {
  display: 'flex',
  justifyContent: 'center',
  backgroundColor: green['50'],
  borderRadius: '6px',
  border: `1px solid ${green['500']}`,
  py: 4,
  mb: 4,
  width: '80%',
  mx: 'auto',
};

function Classic({ card }: { card: Pick<braze.ClassicCard, 'url' | 'imageUrl' | 'title' | 'linkText'> }) {
  return (
    <Box className="content-card" sx={ContentCardDefaultStyling}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: { xs: 'column', lg: 'row' },
          justifyContent: 'space-between',
          gap: 4,
          alignItems: 'center',
        }}
      >
        {card?.imageUrl && <img alt="braze content card" src={card?.imageUrl} height="60" />}
        <Typography variant="h6" sx={{ color: green['500'] }}>
          {card?.title}
        </Typography>
        <Button
          href={card?.url}
          variant="outlined"
          size="small"
          sx={{ height: '2.25rem', fontSize: '1rem', alignSelf: 'center' }}
        >
          {card?.linkText}
        </Button>
      </Box>
    </Box>
  );
}

function CaptionedImage({ card }: { card: Pick<braze.CaptionedImage, 'url' | 'imageUrl' | 'title' | 'linkText'> }) {
  return (
    <Box
      className="content-card"
      sx={{ ...ContentCardDefaultStyling, flexDirection: { xs: 'column', lg: 'row' }, justifyContent: 'space-around' }}
    >
      <Box sx={{ display: 'flex', justifyContent: 'center' }}>
        <img alt="braze content card" src={card?.imageUrl} height="60" />
      </Box>
      <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <Typography variant="h6" sx={{ color: green['500'] }}>
          {card?.title}
        </Typography>
        <Button href={card?.url} variant="outlined" size="small" sx={{ width: '220px', fontSize: '1rem' }}>
          {card?.linkText}
        </Button>
      </Box>
    </Box>
  );
}

function Banner({ card }: { card: Pick<braze.Banner, 'url' | 'imageUrl' | 'extras'> }) {
  return (
    <Box
      className="content-card"
      sx={{
        display: 'flex',
        justifyContent: 'center',
        maxWidth: '1080px',
      }}
    >
      <a href={card?.url} target="_blank" rel="noreferrer">
        <picture>
          <source media="(min-width: 1024px)" srcSet={card?.imageUrl} />
          {card?.extras?.medium_image && <source media="(min-width: 768px)" srcSet={card.extras.medium_image} />}
          {card?.extras?.small_image && <source media="(min-width: 375px)" srcSet={card.extras.small_image} />}
          <img alt="Braze Content Card Banner" src={card?.imageUrl} width="100%" />
        </picture>
      </a>
    </Box>
  );
}

export type Card = braze.ClassicCard | braze.CaptionedImage | braze.Banner;
type ContentCardProps = {
  card: Card;
};
export default function ContentCard({ card }: ContentCardProps) {
  if (!card) return null;

  if (card instanceof braze.ClassicCard) {
    return <Classic card={card} />;
  }
  if (card instanceof braze.CaptionedImage) {
    return <CaptionedImage card={card} />;
  }
  if (card instanceof braze.Banner) {
    return <Banner card={card} />;
  }

  return null;
}
