import React, { useState } from 'react';
import { Experimental_CssVarsProvider as CssVarsProvider } from '@mui/material/styles';

import { FF_REFUND_NON_REFUND_CREDIT } from '@/constants/featureFlags';
import useFeatureFlags from '@/hooks/useFeatureFlags';
import useShippingPrice from '@/hooks/useShippingPrice';
import montuTheme from '@/theme';

import CardCheckout from '../../../components/payment/eWay/cardCheckout';
import { AuthService } from '../../../services/authentication.service';

import '../../../scss/paymentCheckout.scss';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function PaymentCheckOut(props: any) {
  const {
    history: {
      location: {
        state: { orderData, selectedData, edit },
      },
    },
  } = props;
  const { flags, loading } = useFeatureFlags();

  const [data] = useState({
    orderData,
    selectedData,
    edit,
  });

  const shippingParts = useShippingPrice();

  const loggedIn = AuthService.getUser();

  return (
    <CssVarsProvider theme={montuTheme}>
      <CardCheckout
        orderData={data.orderData}
        selectedData={data.selectedData}
        edit={data.edit}
        user={loggedIn}
        loading={loading}
        flags={flags}
        isFlagOn={flags[FF_REFUND_NON_REFUND_CREDIT]}
        shippingParts={shippingParts}
      />
    </CssVarsProvider>
  );
}

export default PaymentCheckOut;
