import React from 'react';
import { BrandThemeProvider } from '@montugroup/themes';
import { Typography } from '@mui/material';

import PageContainer from '@/components/common/PageContainer';
import ManagedErrorToasts from '@/components/error/ManagedErrorToasts';
import ApprovalDocumentsModal from '@/components/pharmacist/orders/modals/ApprovalDocumentsModal';
import ConfirmDispenseModal from '@/components/pharmacist/orders/modals/ConfirmDispenseModal';
import SplitOrderModal from '@/components/pharmacist/orders/modals/SplitOrderModal';
import UnableToDispenseModal from '@/components/pharmacist/orders/modals/UnableToDispenseModal';
import PharmacistOrdersTable from '@/components/pharmacist/orders/PharmacistOrdersTable';
import PharmacistOrderTableSelector from '@/components/pharmacist/orders/PharmacistOrderTableSelector';
import { PharmacistOrdersProvider } from '@/context/pharmacist/orders/PharmacistOrders';
import { PharmacistOrdersModalsProvider } from '@/context/pharmacist/orders/PharmacistOrdersModals';
import { usePortalPartnerBrand } from '@/hooks/portal-partner-brand';

function PharmacistOrders() {
  const { key } = usePortalPartnerBrand();

  return (
    <BrandThemeProvider variant={key}>
      <PharmacistOrdersProvider>
        <PharmacistOrdersModalsProvider>
          <PageContainer>
            <ManagedErrorToasts />
            <Typography component="h2" variant="h5">
              Orders
            </Typography>
            <PharmacistOrderTableSelector />
            <PharmacistOrdersTable />
            <ApprovalDocumentsModal />
            <UnableToDispenseModal />
            <ConfirmDispenseModal />
            <SplitOrderModal />
          </PageContainer>
        </PharmacistOrdersModalsProvider>
      </PharmacistOrdersProvider>
    </BrandThemeProvider>
  );
}

export default PharmacistOrders;
