/* eslint-disable no-console */
import React from 'react';
import { useParams } from 'react-router-dom';

import DefaultLogInPage from '@/components/auth/login/DefaultLogin';
import PatientLogin from '@/components/auth/login/PatientLogin';
import { LoginType } from '@/components/auth/login/types';
import PharmacistSignIn from '@/components/auth/pharmacist/PharmacistSignIn';
import USER_ROLES from '@/constants/userRoles';

export interface SignInFormData {
  email: string;
  password: string;
  rePassword: string;
  remember: boolean;
}

export function SignInPage() {
  const params = useParams();

  const loginType = (params?.loginType as LoginType) || 'patient';
  const onBoardKey = params?.onBoardKey || null;

  const userRolesKeys = Object.keys(USER_ROLES);
  if (!userRolesKeys.includes(loginType)) {
    return <PatientLogin onBoardKey={onBoardKey} />;
  }

  if (loginType === 'pharmacist') {
    return <PharmacistSignIn />;
  }

  if (loginType === 'patient') {
    return <PatientLogin onBoardKey={onBoardKey} />;
  }

  return <DefaultLogInPage onBoardKey={onBoardKey} loginType={loginType} />;
}

export default SignInPage;
