import { useNavigate } from 'react-router-dom';
import { BrandThemeProvider } from '@montugroup/themes';
import { ChevronLeft } from '@mui/icons-material';
import { IconButton, styled } from '@mui/material';

import AltLogo from '@/components/common/AltLogo';

import { PartnerContainer } from './PortalPartner.styles';

const AlternaleafLogoWithSx = styled(AltLogo)({});

const ParentHeaderContainer = styled(PartnerContainer)(({ theme }) => ({
  paddingTop: theme.spacing(2),
  paddingBottom: theme.spacing(2),
}));

function ParentHeader() {
  const navigate = useNavigate();

  return (
    <ParentHeaderContainer>
      <BrandThemeProvider variant="alternaleaf">
        <IconButton
          disableRipple
          size="small"
          sx={{
            padding: 0,
            '&.MuiButtonBase-root:focus': {
              outline: 'none',
            },
            marginLeft: '-0.75rem',
          }}
          onClick={() => navigate({ pathname: '/home' })}
        >
          <ChevronLeft color="primary" fontSize="large" sx={{ marginRight: 2 }} />
          <AlternaleafLogoWithSx
            fillColor="currentColor"
            sx={{ color: (theme) => theme.palette.primary.main }}
            width="150"
          />
        </IconButton>
      </BrandThemeProvider>
    </ParentHeaderContainer>
  );
}

export default ParentHeader;
