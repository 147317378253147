import { ProductImage } from '@/components/products/detail/types';
import settings from '@/constants/constants';

const unavailableProductImage = `${settings.productImageUrl}Image_Unavailable.png`;

// Internal scope can see real product image
const getProductImageUrl = (imageUrl?: string | null, productImages?: ProductImage[]): string => {
  const image = productImages?.length ? productImages[0].image_url : imageUrl && settings.productImageUrl + imageUrl;

  return image || unavailableProductImage;
};

// External scope should only see white label for medication products
export const getWhiteLabelProductImageUrl = ({ whiteLabelImageUrl }: { whiteLabelImageUrl?: string | null }): string =>
  whiteLabelImageUrl ?? unavailableProductImage;

export default getProductImageUrl;
