import React from 'react';
import { Typography } from '@mui/material';

import ViewPatientsActions from '@/components/admin/viewPatients/ViewPatientsActions';
import ViewPatientsTable from '@/components/admin/viewPatients/ViewPatientsTable';
import PageContainer from '@/components/common/PageContainer';
import ManagedErrorToasts from '@/components/error/ManagedErrorToasts';

function ViewPatients() {
  return (
    <PageContainer>
      <ManagedErrorToasts />
      <Typography component="h2" variant="h5">
        Patients
      </Typography>
      <ViewPatientsActions />
      <ViewPatientsTable />
    </PageContainer>
  );
}

export default ViewPatients;
