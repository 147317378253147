import { Box, Container, Stack, styled } from '@mui/material';

export const AuthPageContainer = styled(Stack)({
  minHeight: '100vh',
  position: 'relative',
});

export const AuthCardContainer = styled(Stack)({
  margin: '0 auto',
  textAlign: 'center',
  maxWidth: '394px',
});

export const Background = styled(Box)(({ theme }) => ({
  position: 'absolute',
  right: 0,
  top: '65%',
  zIndex: -1,
  svg: {
    maxWidth: '100%',
    width: '10rem',
  },
  [theme.breakpoints.up('md')]: {
    top: '35%',
    bottom: 0,
    right: 0,
    svg: {
      maxWidth: '100%',
      width: '20rem',
    },
  },
}));

export const CentreContainer = styled(Container)(({ theme }) => ({
  position: 'absolute',
  top: '40%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  [theme.breakpoints.down('md')]: {
    top: '100px',
    transform: 'translate(-50%, 0)',
  },
}));
