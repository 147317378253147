import { Link, styled } from '@mui/material';

const Paragraph = styled('div')(({ theme }) => ({
  ...theme.typography.body1,
  lineHeight: theme.spacing(6),
  '& p': {
    margin: theme.spacing(3, 0),
  },
  '& ul, & ol': {
    margin: theme.spacing(6, 0),
    paddingLeft: theme.spacing(9),
  },
  '& li': {
    marginBottom: theme.spacing(1),
  },
}));

const FAQConsultationData = [
  {
    id: 2,
    question: 'How often should I see my doctor?',
    content: (
      <Paragraph>
        <p>
          We recommend seeing your Alternaleaf doctor every 6-8 weeks. This allows the doctor to track your progress and
          make sure your treatment plan is working as intended. It’s also an opportunity for you to ask questions, or
          discuss potential changes to your prescription.
        </p>
        <p>
          Learn more about{' '}
          <Link
            href="https://support.alternaleaf.com.au/hc/en-au/articles/10831241604367-How-consultations-work#h_01J8PG39S1XZST9KSM214JWDXT"
            target="_blank"
            rel="noopener"
            color="inherit"
          >
            follow-up consultations{' '}
          </Link>
          .
        </p>
      </Paragraph>
    ),
  },
  {
    id: 3,
    question: 'What if I’m a returning patient?',
    content: (
      <Paragraph>
        <p>
          If you haven’t seen your Alternaleaf doctor in over 8 months, you’ll need to{' '}
          <Link
            href="https://support.alternaleaf.com.au/hc/en-au/articles/34017993658905-Contact-us"
            target="_blank"
            rel="noopener"
            color="inherit"
          >
            contact us
          </Link>{' '}
          before booking in a follow-up consultation. We’ll schedule a nurse call-back to complete an assessment, and
          organise an appointment for you.
        </p>
      </Paragraph>
    ),
  },
  {
    id: 4,
    question: 'How will my consultation be completed?',
    content: (
      <Paragraph>
        <p>Consultations are completed online via a video call through “Coviu”, or over the phone.</p>
        <p>
          Video consultations can be joined by selecting “Start consultation” from above. If you’re not present during
          the scheduled video call, the doctor will then call the phone number listed on your profile in the patient
          portal. Find out more about{' '}
          <Link
            href="https://support.alternaleaf.com.au/hc/en-au/articles/10831241604367-How-consultations-work"
            target="_blank"
            rel="noopener"
            color="inherit"
          >
            how consultations work
          </Link>
          .
        </p>
      </Paragraph>
    ),
  },
  {
    id: 5,
    question: 'Can I reschedule or cancel my consultation?',
    content: (
      <Paragraph>
        <p>
          Consultations can be rescheduled or cancelled up until the scheduled appointment time. To do so, select
          “Manage, reschedule or cancel your booking” from above and choose the relevant option. View detailed
          instructions on how to{' '}
          <Link
            href="https://support.alternaleaf.com.au/hc/en-au/articles/10831470544143-Managing-your-consultations"
            target="_blank"
            rel="noopener"
            color="inherit"
          >
            manage your consultations
          </Link>
          .
        </p>
        <p>
          In order to provide the most up-to-date availability for all patients, we kindly ask that you give as much
          notice as possible. There are currently no cancellation fees.
        </p>
      </Paragraph>
    ),
  },
  {
    id: 6,
    question: 'Do I book in if my medication is out of stock?',
    content: (
      <Paragraph>
        <p>If your medication is out of stock, we can arrange a replacement prescription for you.</p>
        <p>
          This is a one-off prescription for an alternative medication, to help get you through until your regular
          medication is back in stock. View detailed instructions on how to{' '}
          <Link
            href="https://support.alternaleaf.com.au/hc/en-au/articles/10831537698831-Ordering-process#h_01J8PJHJCQDEXT4G9R1SFJYTR8"
            target="_blank"
            rel="noopener"
            color="inherit"
          >
            arrange a replacement prescription
          </Link>
          .
        </p>
      </Paragraph>
    ),
  },
  {
    id: 7,
    question: 'When do I pay for my consultation?',
    content: (
      <Paragraph>
        <p>
          Payment will be processed after you attend your initial nurse or follow up doctor consultation. If you have a
          credit or debit card on file, we’ll deduct the amount automatically and you’ll be emailed a receipt.
          Otherwise, you’ll receive an invoice via email with the option to “Pay now”.
        </p>
        <p>
          You won’t be charged for consultations that didn’t go ahead. This includes if the doctor takes unexpected
          leave, or you accidentally miss your appointment.
        </p>
      </Paragraph>
    ),
  },
  {
    id: 8,
    question: 'Do you offer concession benefits?',
    content: (
      <Paragraph>
        <p>
          The Alternaleaf Concession Program includes a 10% discount on Alternaleaf consultation costs, and potential
          access to a concession medication range, subject to your doctor’s assessment and prescription.
        </p>
        <p>
          To join the Alternaleaf Concession Program,{' '}
          <Link
            href="https://support.alternaleaf.com.au/hc/en-au/articles/34017993658905-Contact-us"
            target="_blank"
            rel="noopener"
            color="inherit"
          >
            contact us
          </Link>{' '}
          and provide a clear photo of your valid concession card.
        </p>
        <p>We offer concession benefits to all holders of a Commonwealth-issued:</p>
        <ul>
          <li>Health Care Card</li>
          <li>Veterans Card (DVA)</li>
          <li>Seniors Health & Pensioners Concession Card</li>
        </ul>
      </Paragraph>
    ),
  },
];

export default FAQConsultationData;
