import { ChevronLeft } from '@mui/icons-material';
import { Alert, Box, Divider, IconButton, Typography, useMediaQuery } from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';

import { ENQUIRY_PRODUCT_TOAST_MESSAGE, ProductEnquiryStatus } from '@/components/patient/enquiryModal/common';
import EnquiryDialog from '@/components/patient/enquiryModal/EnquiryDialog';
import ProductReplacementFound from '@/components/patient/enquiryModal/ProductReplacementFound';
import RequestAlternativeProduct from '@/components/patient/enquiryModal/RequestAlternativeProduct';
import { ProductCardProduct } from '@/components/products/productCard/ProductCard';
import UnavailableProductRefillCard, {
  UnavailableProductData,
  UnavailableProductIssue,
} from '@/components/products/refill/UnavailableProductRefillCard';
import { PageSection } from '@/pages/umeds/order-medication/OrderMedication.styles';

import { PRODUCT_PURCHASE_ISSUE_TEXT } from './util';

export type RequestReplacementDialogProps = {
  open: boolean;
  onClose: () => void;
  product: ProductCardProduct;
  handleRequestAlternativeProduct: () => void;
  productEnquiryStatus: ProductEnquiryStatus;
  handleCheckProductStatus: () => Promise<void>;
};

const AlertInfo = styled(Alert)`
  border-width: 2px;
`;

const ContentDivider = styled(Divider)`
  margin: 1.5rem 0;
`;

export default function RequestReplacementDialog({
  open,
  onClose,
  product,
  handleRequestAlternativeProduct,
  productEnquiryStatus,
  handleCheckProductStatus,
}: RequestReplacementDialogProps) {
  const {
    hasScheduledConsultation,
    rescriptActionDate,
    rescriptRequestDate,
    hasPatientDoctorChanged,
    rescriptCreatedWithoutRequest,
  } = productEnquiryStatus;
  const canRequestRescript = !hasPatientDoctorChanged;
  const { infoRequestAlternate } = PRODUCT_PURCHASE_ISSUE_TEXT;
  const { isOutOfStock, isGenerativeScripting } = product;
  const theme = useTheme();

  const isMobileView = useMediaQuery(theme.breakpoints.down('md'));

  const mappedProduct: UnavailableProductData = {
    ...product,
    is_out_of_stock: product.isOutOfStock,
    reasoning_toggle: product.reasoningToggle,
    is_generative_scripting: product.isGenerativeScripting || false,
    Suppliers: [
      {
        supplier_name: product.supplierName,
      },
    ],
  };

  const issue: UnavailableProductIssue = {
    title: product.isOutOfStock
      ? PRODUCT_PURCHASE_ISSUE_TEXT.outOfStock.title
      : PRODUCT_PURCHASE_ISSUE_TEXT.discontinued.title,
    info: infoRequestAlternate,
    isHighSeverity: true,
  };

  const shouldShowReplacementInfo =
    canRequestRescript && !rescriptActionDate && !hasScheduledConsultation && !rescriptRequestDate;

  return (
    <EnquiryDialog open={open} onClose={onClose}>
      {isMobileView ? (
        <PageSection sx={{ gridArea: 'edit', marginBottom: { xs: 4, md: 6 }, alignItems: 'flex-start' }}>
          <IconButton
            size="small"
            sx={{
              padding: 0,
              '&.MuiButtonBase-root:focus': {
                outline: 'none',
              },
            }}
            onClick={onClose}
            disableRipple
          >
            <ChevronLeft /> &nbsp;
            <Typography variant="body2" color="primary">
              Edit medication
            </Typography>
          </IconButton>
        </PageSection>
      ) : null}
      <Box>
        {isOutOfStock && (canRequestRescript || rescriptActionDate) && !hasScheduledConsultation && (
          <AlertInfo variant="outlined" severity="warning">
            {ENQUIRY_PRODUCT_TOAST_MESSAGE.highDemand}
          </AlertInfo>
        )}
        <UnavailableProductRefillCard
          product={mappedProduct}
          issue={issue}
          handleCheckProductStatus={handleCheckProductStatus}
          showRequestReplacementInfo={shouldShowReplacementInfo}
        />

        {isGenerativeScripting && <AlertInfo>{ENQUIRY_PRODUCT_TOAST_MESSAGE.discontinued}</AlertInfo>}
        {isOutOfStock && !rescriptActionDate && (!canRequestRescript || hasScheduledConsultation) && (
          <AlertInfo>{ENQUIRY_PRODUCT_TOAST_MESSAGE.outOfStock}</AlertInfo>
        )}

        {rescriptCreatedWithoutRequest || rescriptActionDate ? (
          <ProductReplacementFound
            rescriptedProduct={rescriptCreatedWithoutRequest}
            requestActionDate={rescriptActionDate}
            onViewProduct={onClose}
          />
        ) : (
          <>
            {canRequestRescript && !hasScheduledConsultation && (
              <>
                <RequestAlternativeProduct
                  rescriptRequestDate={rescriptRequestDate}
                  handleRequestAlternativeProduct={handleRequestAlternativeProduct}
                />
                <ContentDivider />
                <Typography>
                  <Typography component="span" fontWeight="bold">
                    Need to speak to a doctor?
                  </Typography>{' '}
                  Book a new consultation with one of our partner telehealth medical teams.
                </Typography>
              </>
            )}{' '}
          </>
        )}
      </Box>
    </EnquiryDialog>
  );
}
