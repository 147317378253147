import { Box, Skeleton, Typography } from '@mui/material';

import { OrderDetails } from '@/hooks/order/useGetOrderDetails';
import { Button } from '@/ui-library';
import generateTrackingLink from '@/utils/patient/trackingLink';

import { getOrderStatusChipProps, OrderStatusChip } from '../OrderStatusChip/OrderStatusChip';
import PharmacyOrderDetails, { OrderStage } from '../pharmacy-order-details/PharmacyOrderDetails';

import {
  ActionText,
  OrderTrackingButtonContainer,
  SplitOrderDetailsContainer,
  SplitOrderPharmacyDetailsContainer,
  SplitOrderStatusChip,
} from './OrderHistoryCard.styles';

type SplitOrderDetailsProps = {
  isLoading: boolean;
  orderDetails: OrderDetails[];
};

const lineItemPrice = (price: number | null, quantity: number | null) => {
  if (price === null || quantity === null) {
    return 'N/A';
  }
  return `$${(price * quantity).toFixed(2)}`;
};

function SplitOrderDetailsLoading() {
  return (
    <Box
      sx={(theme) => ({
        flexDirection: 'column',
        border: '1px',
        borderStyle: 'solid',
        borderColor: '#DEDEE1',
        padding: theme.spacing(5),
        borderRadius: theme.spacing(1),
        marginBottom: theme.spacing(4),
      })}
    >
      <Skeleton variant="text" width="30%" height={32} />
      <Skeleton variant="text" width="40%" height={32} />
      <Skeleton variant="text" width="30%" height={32} />

      <Skeleton variant="rectangular" width="100%" height={200} sx={(theme) => ({ marginTop: theme.spacing(4) })} />
    </Box>
  );
}

function SplitOrderDetails(props: SplitOrderDetailsProps) {
  const { orderDetails, isLoading } = props;

  return (
    <>
      {isLoading && (
        <Box>
          <SplitOrderDetailsLoading />
          <SplitOrderDetailsLoading />
        </Box>
      )}

      {!isLoading &&
        orderDetails?.map((order) => {
          const orderStatusChipProps = getOrderStatusChipProps({
            status: order.order_status_id,
            delivered: order.delivered,
          });

          return (
            <SplitOrderPharmacyDetailsContainer key={order.order_code}>
              <Typography variant="body2" fontWeight="bold">
                Items in this order
              </Typography>
              <SplitOrderDetailsContainer>
                <Typography variant="body2">Split order number: </Typography>
                <Box component="span" sx={(theme) => ({ fontWeight: theme.typography.fontWeightBold })}>
                  #{order.order_code}
                </Box>
                <SplitOrderStatusChip>
                  <OrderStatusChip {...orderStatusChipProps} />
                </SplitOrderStatusChip>
              </SplitOrderDetailsContainer>
              <Box
                sx={(theme) => ({
                  flexDirection: 'column',
                  marginTop: theme.spacing(5),
                  marginBottom: theme.spacing(6),
                })}
              >
                {order.OrderProducts?.map((product) => (
                  <Box
                    key={product.id}
                    sx={(theme) => ({
                      display: 'flex',
                      flexDirection: 'column',
                      marginBottom: theme.spacing(1),
                      borderBottom: '1px',
                      borderColor: theme.palette.divider,
                      borderBottomStyle: 'solid',
                      paddingBottom: theme.spacing(2),
                    })}
                  >
                    <Box
                      sx={(theme) => ({
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        marginBottom: theme.spacing(1),
                      })}
                    >
                      <Typography variant="body2">{product.Product.name}</Typography>
                      <Typography variant="body2">{lineItemPrice(product.Product.price, product.quantity)}</Typography>
                    </Box>
                    <Typography variant="body2">Qty: {product.quantity}</Typography>
                  </Box>
                ))}
              </Box>

              <PharmacyOrderDetails
                fullOrderCode={order.order_code}
                stage={orderStatusChipProps.status as OrderStage}
              />

              {order.shipment_tracking_id && order.shipment_carrier && (
                <OrderTrackingButtonContainer>
                  <Button
                    href={generateTrackingLink(order.shipment_carrier, order.shipment_tracking_id)}
                    fullWidth
                    variant="outlined"
                  >
                    <ActionText variant="body1">Track delivery</ActionText>
                  </Button>
                </OrderTrackingButtonContainer>
              )}
            </SplitOrderPharmacyDetailsContainer>
          );
        })}
    </>
  );
}

export default SplitOrderDetails;
