import { useQuery } from 'react-query';

import { getPatientsForGP, PatientsForGPResponse } from '@/services/data.service';
import { TableSortOrder } from '@/types';

export type UseGetPatientsForGPParams = {
  page: number;
  pageSize: number;
  sortingOrder: TableSortOrder;
  filter: string;
};

function useGetPatientsForGP({ page, pageSize, sortingOrder, filter }: UseGetPatientsForGPParams) {
  const { isLoading, data, isError, isSuccess, error } = useQuery<PatientsForGPResponse>({
    queryKey: ['getPatientsForGP', page, pageSize, sortingOrder, filter],
    queryFn: async ({ signal }) => {
      try {
        const response = await getPatientsForGP({ pageSize, page, sortingOrder, filter, signal });
        if (response.status !== 200) {
          throw new Error(`Request failed with status code ${response.status}`);
        }
        return response.data;
      } catch (e) {
        throw new Error(`Failed to fetch doctors availability: ${(e as { message: string }).message}`);
      }
    },
  });

  return { isLoading, data, isError, isSuccess, error };
}

export default useGetPatientsForGP;
