import { useEffect, useState } from 'react';
import { FieldValues } from 'react-hook-form';
import { LocationInput } from '@montugroup/design-system';
import { AlertTitle, Box } from '@mui/material';

import ManualAddressForm from '@/components/order/order-address-form/ManualAddressForm';
import settings from '@/constants/constants';
import { FF_ENABLE_GOOGLE_PLACES_UMEDS } from '@/constants/featureFlags';
import useFeatureFlags from '@/hooks/useFeatureFlags';
import useAddressForm from '@/hooks/user/useAddressForm';
import { IUserProfile } from '@/hooks/user/useGetUserProfile';
import useUpdateUserProfile from '@/hooks/user/useUpdateUserProfile';
import AuthService from '@/services/authentication.service';
import { User } from '@/types';
import { Logger } from '@/utils/logger';

import { AlertResponse } from './PatientProfile';

const logger = new Logger('ShippingAddressForm');

export type Address = {
  addressLine1?: string;
  addressLine2?: string;
  suburb?: string;
  state?: string;
  postcode?: string;
};

type IAlert = {
  severity: 'success' | 'error';
  message?: string;
  messageTitle?: string;
};

function ShippingAddressForm({
  userData,
  getUserState,
}: {
  userData: IUserProfile | undefined;
  getUserState: () => string | null;
}) {
  const { mutate, isLoading } = useUpdateUserProfile();
  const { flags } = useFeatureFlags();
  const isGooglePlacesEnabled = flags[FF_ENABLE_GOOGLE_PLACES_UMEDS];
  const [enableManualAddress, setEnableManualAddress] = useState(false);

  const [alert, setAlert] = useState<IAlert>({ severity: 'success', message: '' });

  const formMethods = useAddressForm();

  const address = formMethods.getValues();

  useEffect(() => {
    if (userData?.user?.address) {
      const [line1, line2] = userData.user.address.split('$$$$') || [];
      const state = getUserState() || '';
      formMethods.setValue('addressLine1', String(line1 || ''));
      formMethods.setValue('addressLine2', String(line2 || ''));
      formMethods.setValue('suburb', String(userData.user.city || ''));
      formMethods.setValue('postcode', String(userData.user.zip_code || ''));
      formMethods.setValue('state', state);
    }
  }, [userData, getUserState]);

  const resetAlert = () => {
    setAlert({ severity: 'success', message: '', messageTitle: '' });
  };

  const handleAddressUpdate = async (updatedAddress: Address) => {
    resetAlert();
    const stateId = userData?.states.find((state) => state.name === updatedAddress.state)?.id;
    const payload = {
      user: {
        address: `${updatedAddress.addressLine1}$$$$${updatedAddress.addressLine2}`,
        city: updatedAddress.suburb,
        state_id: stateId,
        zip_code: updatedAddress.postcode,
      },
    };

    mutate(payload, {
      onSuccess: () => {
        setAlert({ severity: 'success', message: 'Shipping address updated', messageTitle: '' });
        setEnableManualAddress(false);
        const localStorageUser = AuthService.getUser();
        if (localStorageUser && localStorageUser.user) {
          const updatedUser: User = {
            ...localStorageUser.user,
            ...payload.user,
            id: localStorageUser.user.id,
            state_id: payload.user.state_id ?? localStorageUser.user.state_id,
          };
          AuthService.updateUser(updatedUser);
        }
      },
      onError: (error) => {
        setAlert({ severity: 'error', message: 'Something went wrong, please try again', messageTitle: '' });
        logger.error('Failed to update user profile address', error);
      },
    });
  };

  const handleSelectedAddress = async (addressData: Address) => {
    await handleAddressUpdate(addressData);
    formMethods.setValue('addressLine1', String(addressData.addressLine1));
    formMethods.setValue('addressLine2', String(addressData.addressLine2));
    formMethods.setValue('postcode', String(addressData.postcode));
    formMethods.setValue('state', String(addressData.state));
    formMethods.setValue('suburb', String(addressData.suburb));
  };

  const handleResetAddress = () => {
    formMethods.reset();
  };

  const handleManualAddressClick = () => {
    if (alert.message) {
      setAlert({ severity: 'success', message: '', messageTitle: '' });
    }
    setEnableManualAddress(true);
  };

  const handleAlertClose = () => {
    setAlert({ severity: 'success', message: '' });
  };

  const formatAddress = (fullAddress: Address): string => {
    const { addressLine1, addressLine2, suburb, postcode, state } = fullAddress;
    if (!addressLine1 && !addressLine2 && !suburb && !postcode && !state) {
      return '';
    }

    const addressPart = addressLine2 ? `${addressLine2}/${addressLine1}` : addressLine1;
    const parts = [addressPart, suburb, postcode, state].filter(Boolean);

    return parts.join(', ');
  };

  const handleFormError = (errors: FieldValues) => {
    if (errors.addressLine1) {
      setAlert({
        severity: 'error',
        messageTitle: formMethods.additionalErrorStates.addressLine1ValidationMessage.errorMessageTitle,
        message: formMethods.additionalErrorStates.addressLine1ValidationMessage.errorMessage,
      });
    }
    if (errors.addressLine2) {
      setAlert({
        severity: 'error',
        messageTitle: formMethods.additionalErrorStates.addressLine2ValidationMessage.errorMessageTitle,
        message: formMethods.additionalErrorStates.addressLine2ValidationMessage.errorMessage,
      });
    }
  };

  return (
    <Box maxWidth={{ xs: '100%', lg: '66%' }}>
      {isGooglePlacesEnabled && !enableManualAddress ? (
        <Box marginTop={6}>
          <LocationInput
            value={formatAddress(address)}
            name="fullAddress"
            aria-label="fullAddress"
            label="Shipping Address"
            shrinkLabel
            id="location-input"
            googlePlacesApiKey={settings.googlePlacesApiKey}
            onSelectedAddress={async (addressData) => {
              const formattedAddress = [addressData.subpremise, addressData.street_number, addressData.address]
                .filter(Boolean)
                .join(' ');
              const updatedAddress: Address = {
                addressLine1: formattedAddress,
                addressLine2: '',
                state: addressData.state,
                suburb: addressData.suburb || addressData.city,
                postcode: addressData.postcode,
              };

              await handleSelectedAddress(updatedAddress);
            }}
            onReset={handleResetAddress}
            manualAddressFn={handleManualAddressClick}
            disableBorder
            variant="standard"
          />
        </Box>
      ) : (
        <ManualAddressForm
          onSubmit={handleAddressUpdate}
          states={userData?.states || []}
          formMethods={formMethods}
          onError={handleFormError}
          variant="standard"
          buttonProps={{
            sx: {
              width: { xs: '100%', md: 'auto' },
            },
          }}
          isLoading={isLoading}
        />
      )}
      {alert.message && (
        <AlertResponse severity={alert.severity} type={alert.severity} onClose={handleAlertClose}>
          {alert.messageTitle ? <AlertTitle sx={{ fontWeight: 'bold' }}>{alert.messageTitle}</AlertTitle> : null}
          {alert.message}
        </AlertResponse>
      )}
    </Box>
  );
}

export default ShippingAddressForm;
