import { useContext } from 'react';
import { Box } from '@mui/material';

import { BrazeContentCardContext } from '@/App';

import ContentCard from './components/ContentCard';

type Props = {
  displayType: string;
  align?: 'left' | 'center' | 'right';
};

const alignValueMap = {
  left: 'flex-start',
  center: 'center',
  right: 'flex-end',
};

export default function SinglePageContentCard({ displayType, align = 'center' }: Props) {
  const brazeContentCards = useContext(BrazeContentCardContext);
  const contentCard = (brazeContentCards || []).find((card) => card?.extras?.display_type === displayType);

  return contentCard ? (
    <Box display="flex" justifyContent={alignValueMap[align]}>
      <ContentCard card={contentCard} />
    </Box>
  ) : null;
}
