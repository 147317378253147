import { useCallback, useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router';
import { toast } from 'react-toastify';
import { Alert, Box, Typography } from '@mui/material';
import moment from 'moment';

import { BRAZE_CONTENT_CARD } from '@/assets/data/enums';
import SinglePageContentCard from '@/components/braze/SinglePageContentCard';
import { ToastConfirmModal, toastOptions } from '@/components/common/toastConfirm';
import ManagedErrorToasts from '@/components/error/ManagedErrorToasts';
import HelpDeskPopup from '@/components/helpDesk/HelpDeskSelector';
import { OrderSummaryButton } from '@/components/order/order-summary/OrderSummaryButton';
import { OrderSummaryV2 } from '@/components/order/order-summary/OrderSummaryV2';
import { GENERATIVE, OUT_OF_STOCK, ProductEnquiryStatus } from '@/components/patient/enquiryModal/common';
import EnquiryErrorDialog from '@/components/patient/enquiryModal/EnquiryErrorDialog';
import EnquiryLoadingDialog from '@/components/patient/enquiryModal/EnquiryLoadingDialog';
import RequestConfirmationDialog from '@/components/patient/enquiryModal/requstConfirmationDialog/RequestConfirmationDialog';
import { PurchaseStepper } from '@/components/products/purchaseStepper/PurchaseStepper';
import { IncrementProductRefillCard } from '@/components/products/refill/IncrementProductRefillCard';
import ProductCategoryAccordion from '@/components/products/refill/ProductCategoryAccordion';
import ProductRefillCard from '@/components/products/refill/ProductRefillCard';
import { RecommendedDevicesSection } from '@/components/products/refill/RecommendedDevicesSection';
import {
  UnavailableProductIssue,
  UnavailableProductRefillCard,
} from '@/components/products/refill/UnavailableProductRefillCard';
import { FF_ENABLE_ORDER_PHARMACY_ALLOCATION, FF_ENABLE_PREFILL_PRODUCT_QUANTITY } from '@/constants/featureFlags';
import { PrescribedProduct } from '@/hooks/patient/refill/types';
import { ConfirmationPromise, useOrderMedicationController } from '@/hooks/patient/refill/useOrderMedicationController';
import { FORMULATION_ID_DEVICE } from '@/hooks/patient/refill/util';
import useCreatePatientRescriptRequest from '@/hooks/patient/useCreatePatientRescriptRequest';
import useFeatureFlags from '@/hooks/useFeatureFlags';
import {
  getProductPurchaseIssue,
  mapToProductCard,
  mapToProductRefillCardPrescription,
  mapToProductRefillCardProduct,
} from '@/pages/umeds/order-medication/util';
import { AuthService } from '@/services/authentication.service';
import { RescriptRequestReasons } from '@/services/patient.service';
import { fetchProductEnquiryStatus } from '@/services/product.service';
import { numberFormat } from '@/utils/helpers';
import { Logger } from '@/utils/logger';

import { getCheckoutData } from './getCheckoutData';
import { DesktopOnly, DisclaimerRRP, MobileOnlySticky, PageContent, PageSection } from './OrderMedication.styles';
import PatientRefillLoadingSkeleton from './OrderMedicationLoadingSkeleton';
import RequestReplacementDialog from './RequestReplacementDialog';

const logger = new Logger('PatientRefill');

/// /////////////////
// Types
/// /////////////////

interface SelectedProductData {
  quantity_original: number;
  product_name: string;
  repeats: number;
  price: number;
  notAddable: boolean | number;
  remaining_units: number;
  interval: number;
  is_out_of_stock: boolean;
  short_name: string | null;
  is_concession: boolean;
}

interface SelectedProduct {
  id: number;
  data: SelectedProductData & {
    product_id: number;
    quantity: number;
  };
}
interface ProductIssuePair {
  product: PrescribedProduct;
  issue: UnavailableProductIssue | null;
}

/// //////////////
// UTILITY FUNCTIONS
/// //////////////

const generateProductIssuePairs = (products: PrescribedProduct[]): ProductIssuePair[] =>
  products.map((product) => {
    const issue = getProductPurchaseIssue(product, logger);
    return {
      product,
      issue,
    };
  });

// Show PIR button for any device/medication with a valid prescription
export const shouldShowPIR = (product: PrescribedProduct) =>
  product.prescription_id !== undefined && product.prescription_id !== null;

/// ////////////
// Component
/// ////////////

function OrderMedication() {
  /** ***********
   * Hooks
   ************ */

  const { user } = AuthService.getUser()!;
  const createPatientRescriptRequest = useCreatePatientRescriptRequest();
  const { flags } = useFeatureFlags();

  /** ***********
   * Route Params
   ************* */
  const location = useLocation();

  const routeParams = useParams<{ id: string }>();
  const orderId = Number.parseInt(routeParams.id ?? '', 10);
  const patientRefillController = useOrderMedicationController(orderId, user);
  const {
    refillAPIData,
    recommendedDevices,
    isSubmitting,
    selectedProducts,
    hasSelectedAnyProducts,
    creditDiscounts,
    isPageLoading,
    refillApiSucceeded,
  } = patientRefillController.state;
  const {
    getProductQuantity,
    setProductQuantity,
    setInitialProductQuantities,
    submitOrder,
    validateOrder,
    enqueueError,
  } = patientRefillController.actions;

  /** ***********
   * Component State
   ************* */

  const [selectedProductId, setSelectedProductId] = useState<number>();

  const [isCheckProductEnquiryLoading, setIsCheckProductEnquiryLoading] = useState(false);
  const [isEnquiryProductDialogOpen, setIsEnquiryProductDialogOpen] = useState(false);
  const [productEnquiryStatus, setProductEnquiryStatus] = useState<ProductEnquiryStatus | null>();
  const [isRequestConfirmationDialogOpen, setIsRequestConfirmationDialogOpen] = useState(false);
  const [hasEnquiryError, setHasEnquiryError] = useState(false);
  const [isCreateRescriptRequestLoading, setIsCreateRescriptRequestLoading] = useState<boolean>();

  /** *************
   * Derived State
   ************** */

  const checkoutData = getCheckoutData(selectedProducts, creditDiscounts);
  const isDischarge = Boolean(refillAPIData?.patient?.is_discharge);
  const isCheckoutDisabled = isDischarge || isSubmitting || !hasSelectedAnyProducts;
  const selectedProduct = mapToProductCard(
    (refillAPIData?.products || []).find((product) => product.id === selectedProductId),
  );

  // Divide Products into Available and Unavailable
  const productIssuePairs = generateProductIssuePairs(refillAPIData?.products || []);
  const unavailableProductsWithIssues = productIssuePairs.filter((pip) => pip.issue !== null);

  // All Products
  const availableProducts = productIssuePairs.filter((pip) => pip.issue === null).map((pip) => pip.product);

  // Divide into Devices and Medication
  const availProdDevices = availableProducts.filter((x) => x.formulation_id === FORMULATION_ID_DEVICE);
  const availProdNonDevices = availableProducts.filter((x) => x.formulation_id !== FORMULATION_ID_DEVICE);

  // Divide devices into Prescribed and Non-Prescribed
  const prescribedDevices = availProdDevices.filter((x) => Boolean(x.prescription_id));
  const nonPrescribedDevices = availProdDevices.filter((x) => !x.prescription_id);

  // Respond to data being loaded
  useEffect(() => {
    if (typeof refillAPIData === 'undefined') {
      logger.info(`userId=${user!.id} loaded refill page`);
      return;
    }

    localStorage.setItem('moreUserData', JSON.stringify(refillAPIData));

    if (flags[FF_ENABLE_PREFILL_PRODUCT_QUANTITY]) {
      // check if this is the first order, if it is, prefill using the prescription maximum
      const preLoadProducts = refillAPIData.patientHasPurchases
        ? []
        : [...availProdNonDevices, ...prescribedDevices].map((prod) => prod.id);

      // Set selected products first, so that the product rows will have the correct quantity on first non-loading render.
      setInitialProductQuantities(preLoadProducts);
    } else {
      // This is if you click "re-order" from orders page
      const preLoadProducts = location.state?.preLoadProducts;

      // Set selected products first, so that the product rows will have the correct quantity on first non-loading render.
      setInitialProductQuantities(preLoadProducts);
    }
  }, [refillAPIData]);

  /** **********
   * Callbacks
   ************ */

  const handleCheckProductStatus = useCallback(
    (product: PrescribedProduct) => async () => {
      setIsCheckProductEnquiryLoading?.(true);
      setSelectedProductId?.(product.id);
      try {
        const isOutOfStock = product.is_out_of_stock || product.reasoning_toggle;
        const rescriptRequestReason = isOutOfStock ? OUT_OF_STOCK : GENERATIVE;
        const data = await fetchProductEnquiryStatus(
          refillAPIData?.patient.id,
          product.prescription_id,
          product.id,
          rescriptRequestReason,
        );
        setProductEnquiryStatus?.({
          ...data,
          rescriptActionDate: data.rescriptActionDate ? moment(data.rescriptActionDate).format('DD/MM/YYYY') : null,
          rescriptRequestDate: data.rescriptRequestDate ? moment(data.rescriptRequestDate).format('DD/MM/YYYY') : null,
        });
        setIsCheckProductEnquiryLoading?.(false);
        setIsEnquiryProductDialogOpen?.(true);
      } catch {
        setIsCheckProductEnquiryLoading?.(false);
        setHasEnquiryError?.(true);
      }
    },
    [
      refillAPIData,
      setIsCheckProductEnquiryLoading,
      setSelectedProductId,
      setIsEnquiryProductDialogOpen,
      setHasEnquiryError,
    ],
  );

  // Ordering process is currently Find Existing Order => Validate Order => Place Order
  const handleSubmit = async () => {
    try {
      const confirmNotAddableCallback: ConfirmationPromise<SelectedProduct[]> = async (notAddableProducts) =>
        new Promise((resolve) => {
          toast(
            <ToastConfirmModal onConfirm={() => resolve(true)} onCancel={() => resolve(false)}>
              <div className="mx-auto">
                <p>
                  Your refill order contains a medication that was shipped out to you{' '}
                  {notAddableProducts[0].data.notAddable} day(s) ago. Please note that medication can not be dispensed
                  until the designated interval time has elapsed.
                </p>
                {notAddableProducts.map((x) => (
                  <p key={x.id}>- {x.data.product_name}</p>
                ))}
                Would you like to proceed in placing this order?
              </div>
            </ToastConfirmModal>,
            toastOptions,
          );
        });

      await submitOrder({
        confirmNotAddableCallback,
        validateOrderCallback: validateOrder,
      });
    } catch (error: any) {
      enqueueError({
        title: 'Error Submitting Order',
        body: error.message,
      });
    }
  };

  // Determines the order summary data
  const handleCheckout = async () => {
    await handleSubmit();
  };

  const handleRequestAlternativeProduct = () => {
    setIsEnquiryProductDialogOpen(false);
    setIsRequestConfirmationDialogOpen(true);
  };

  const handleOnSendRequest = async () => {
    setIsCreateRescriptRequestLoading(true);

    try {
      // Todo: Can we assume that data.patient is always populated at this point?
      const newPatientRescriptRequest = await createPatientRescriptRequest(refillAPIData!.patient!.id, {
        prescriptionId: selectedProduct!.prescriptionId!,
        productId: selectedProduct!.id,
        rescriptRequestReason: selectedProduct!.isGenerativeScripting
          ? RescriptRequestReasons.Discontinued
          : RescriptRequestReasons.OutOfStock,
      });

      setIsCreateRescriptRequestLoading(false);
      setIsRequestConfirmationDialogOpen(false);
      setIsEnquiryProductDialogOpen(true);
      setProductEnquiryStatus({
        ...(productEnquiryStatus || {}),
        rescriptRequestDate: moment(newPatientRescriptRequest.created_date).format('DD/MM/YYYY'),
      });
    } catch {
      setIsCreateRescriptRequestLoading(false);
      setHasEnquiryError(true);
    }
  };

  /** **********
   * Render Logic
   ************ */

  return (
    <>
      <ManagedErrorToasts />
      <SinglePageContentCard displayType={BRAZE_CONTENT_CARD.PATIENT_REFILL} />
      {isPageLoading && <PatientRefillLoadingSkeleton />}
      {!isPageLoading && (
        <PageContent>
          {refillApiSucceeded && (
            <>
              <PageSection sx={{ gridArea: 'title', marginBottom: (theme) => theme.spacing(4) }}>
                <Typography variant="h5" component="h1">
                  Order medication
                </Typography>
              </PageSection>
              <PageSection sx={{ gridArea: 'steps', marginBottom: 6 }}>
                {flags[FF_ENABLE_ORDER_PHARMACY_ALLOCATION] ? <PurchaseStepper stage="Order" /> : null}
              </PageSection>
              <PageSection sx={{ gridArea: 'products' }}>
                {availProdNonDevices.map((product) => (
                  <ProductRefillCard
                    key={product.id}
                    product={mapToProductRefillCardProduct(product)}
                    prescription={mapToProductRefillCardPrescription(product)}
                    quantity={getProductQuantity(product.id)}
                    setQuantity={setProductQuantity(product.id)}
                  />
                ))}
                {availProdNonDevices.length > 0 ? null : (
                  <Alert variant="outlined" severity="info">
                    You currently have no prescribed medication available
                  </Alert>
                )}

                <RecommendedDevicesSection
                  recommendedDevices={recommendedDevices}
                  prescribedDevices={prescribedDevices}
                  getQuantity={getProductQuantity}
                  setQuantity={setProductQuantity}
                />
                {prescribedDevices.length > 0 ? (
                  <ProductCategoryAccordion
                    label="Prescribed devices"
                    count={0}
                    startCollapsed={prescribedDevices.length === 0}
                  >
                    {prescribedDevices.map((product) => (
                      <IncrementProductRefillCard
                        key={product.id}
                        product={mapToProductRefillCardProduct(product)}
                        prescription={mapToProductRefillCardPrescription(product)}
                        quantity={getProductQuantity(product.id)}
                        setQuantity={setProductQuantity(product.id)}
                      />
                    ))}
                  </ProductCategoryAccordion>
                ) : null}
                {unavailableProductsWithIssues.length === 0 ? null : (
                  <ProductCategoryAccordion
                    label="Can't order now"
                    count={unavailableProductsWithIssues.length}
                    startCollapsed
                  >
                    {unavailableProductsWithIssues.map(
                      (pip) =>
                        pip.issue && (
                          <UnavailableProductRefillCard
                            key={pip.product.id}
                            product={pip.product}
                            issue={pip.issue}
                            handleCheckProductStatus={handleCheckProductStatus(pip.product)}
                            showRequestReplacementOption={shouldShowPIR(pip.product)}
                          />
                        ),
                    )}
                  </ProductCategoryAccordion>
                )}
                <ProductCategoryAccordion label="Other Products" count={0} startCollapsed>
                  {nonPrescribedDevices.map((product) => (
                    <IncrementProductRefillCard
                      key={product.id}
                      product={mapToProductRefillCardProduct(product)}
                      prescription={mapToProductRefillCardPrescription(product)}
                      quantity={getProductQuantity(product.id)}
                      setQuantity={setProductQuantity(product.id)}
                    />
                  ))}
                </ProductCategoryAccordion>
              </PageSection>
              <Box sx={{ gridArea: 'purchase' }}>
                <DesktopOnly>
                  <Box sx={{ position: 'relative', width: '100%' }}>
                    <Box sx={{ position: 'sticky', top: '8rem' }}>
                      <OrderSummaryV2
                        checkoutData={checkoutData}
                        handleCheckout={handleCheckout}
                        disabled={isCheckoutDisabled}
                      />
                    </Box>
                  </Box>
                </DesktopOnly>
                {flags[FF_ENABLE_ORDER_PHARMACY_ALLOCATION] ? (
                  <DisclaimerRRP>
                    <Typography variant="body2">
                      Prices shown here are recommended only. Your dispensing pharmacy may choose to charge less.
                    </Typography>
                  </DisclaimerRRP>
                ) : (
                  <Box
                    sx={(theme) => ({ marginBottom: theme.spacing(10) })}
                  /> /* remove once flag is removed, here as a workaround for the proceed button between both flagged views */
                )}
              </Box>
            </>
          )}
        </PageContent>
      )}
      <EnquiryLoadingDialog
        open={isCheckProductEnquiryLoading}
        onClose={() => setIsCheckProductEnquiryLoading(false)}
      />
      <EnquiryErrorDialog open={hasEnquiryError} onClose={() => setHasEnquiryError(false)} />
      {selectedProduct && selectedProductId && productEnquiryStatus && (
        <RequestReplacementDialog
          open={isEnquiryProductDialogOpen}
          onClose={() => {
            setIsEnquiryProductDialogOpen(false);
          }}
          product={selectedProduct}
          productEnquiryStatus={productEnquiryStatus}
          handleRequestAlternativeProduct={handleRequestAlternativeProduct}
          handleCheckProductStatus={handleCheckProductStatus(selectedProduct as unknown as PrescribedProduct)} // This is yuck, checking if there's a mapping function
        />
      )}
      <RequestConfirmationDialog
        open={isRequestConfirmationDialogOpen}
        onClose={() => setIsRequestConfirmationDialogOpen(false)}
        onSendRequest={handleOnSendRequest}
        loading={isCreateRescriptRequestLoading}
      />
      <DesktopOnly>
        <HelpDeskPopup />
      </DesktopOnly>
      {hasSelectedAnyProducts && (
        <MobileOnlySticky>
          <OrderSummaryButton
            totalNumberOfProducts={checkoutData.totalNumberOfProducts}
            onClick={handleCheckout}
            amount={numberFormat(checkoutData.totalPrice)}
            disabled={isCheckoutDisabled}
          />
        </MobileOnlySticky>
      )}
    </>
  );
}

export default OrderMedication;
