import { useController, UseControllerReturn, useForm, UseFormProps, UseFormReturn } from 'react-hook-form';

import useAddressValidation from './useAddressValidation';

export type UseAddressFormValues = {
  addressLine1: string;
  addressLine2: string;
  suburb: string;
  postcode: string;
  state: string;
};

export type AddressFormReturn = Pick<UseFormReturn<UseAddressFormValues>, 'formState' | 'reset' | 'handleSubmit'> & {
  defaultControllers: {
    controlAddressLine1: UseControllerReturn<UseAddressFormValues, 'addressLine1'>;
    controlAddressLine2: UseControllerReturn<UseAddressFormValues, 'addressLine2'>;
    controlSuburb: UseControllerReturn<UseAddressFormValues, 'suburb'>;
    controlPostcode: UseControllerReturn<UseAddressFormValues, 'postcode'>;
    controlState: UseControllerReturn<UseAddressFormValues, 'state'>;
  };
};

type UseAddressFormProps = UseFormProps<UseAddressFormValues>;

export function useAddressForm(props?: UseAddressFormProps) {
  const { validatePostcode, validateParcelLocker } = useAddressValidation();
  const { control, ...otherForm } = useForm<UseAddressFormValues>({
    ...props,
    defaultValues: {
      addressLine1: '',
      addressLine2: '',
      postcode: '',
      state: '',
      suburb: '',
      ...(props || {}).defaultValues,
    },
  });

  const addressLine1ValidationMessage = validateParcelLocker(otherForm.getValues().addressLine1);
  const addressLine2ValidationMessage = validateParcelLocker(otherForm.getValues().addressLine2);

  const controlAddressLine1 = useController({
    name: 'addressLine1',
    control,
    rules: {
      required: true,
      validate: (value) => validateParcelLocker(value).isValid,
    },
  });

  const controlAddressLine2 = useController({
    name: 'addressLine2',
    control,
    rules: {
      validate: (value) => validateParcelLocker(value).isValid,
    },
  });

  const controlSuburb = useController({
    name: 'suburb',
    control,
    rules: {
      required: true,
    },
  });

  const controlPostcode = useController({
    name: 'postcode',
    control,
    rules: {
      required: true,
      validate: (value) => validatePostcode(value).isValid || validatePostcode(value).errorMessage,
    },
  });

  const controlState = useController({
    name: 'state',
    control,
    rules: {
      required: true,
    },
  });

  const defaultControllers = {
    controlAddressLine1,
    controlAddressLine2,
    controlSuburb,
    controlPostcode,
    controlState,
  };

  return {
    ...otherForm,
    control,
    defaultControllers,
    additionalErrorStates: {
      addressLine1ValidationMessage,
      addressLine2ValidationMessage,
    },
  };
}

export default useAddressForm;
