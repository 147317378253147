import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { datadogRum } from '@datadog/browser-rum';
import { Box, Button, Container, Stack, styled, Typography } from '@mui/material';
import { Experimental_CssVarsProvider as CssVarsProvider } from '@mui/material/styles';

import settings from '@/constants/constants';
import { alternaleafTheme } from '@/theme';

export type ErrorProps = {
  status?: number;
  title?: string;
  message?: string;
  buttons?: React.ReactNode[];
};

const Main = styled(Container)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '2.66rem',
  margin: '1.07rem',
  [theme.breakpoints.up('sm')]: {
    flexDirection: 'row',
    gap: '3.93rem',
  },
}));

const Content = styled(Stack)(({ theme }) => ({
  gap: '0.533rem',
  [theme.breakpoints.up('sm')]: {
    gap: '1.07rem',
    maxWidth: '28rem',
  },
}));

const ImageContainer = styled(Box)(({ theme }) => ({
  '& img': {
    width: '343px',
    [theme.breakpoints.up('sm')]: {
      width: '421px',
    },
  },
}));

const Text = styled(Typography)(({ theme }) => ({
  textAlign: 'center',
  [theme.breakpoints.up('sm')]: {
    textAlign: 'left',
  },
}));

const ButtonContainer = styled(Stack)(({ theme }) => ({
  marginTop: '4rem',
  [theme.breakpoints.up('sm')]: {
    marginTop: '2rem',
  },
}));

const defaultTitle = 'Whoops, something went wrong.';
const defaultMessage =
  'Try reloading the page. If the issue persists, you can visit our support page or try again later.';

interface ImagePathMap {
  [key: string]: () => Promise<string>;
}

export const imagePaths: ImagePathMap = {
  generic: async () => import('../../assets/images/errors/generic.png').then((data) => data.default),
  404: async () => import('../../assets/images/errors/404.png').then((data) => data.default),
  500: async () => import('../../assets/images/errors/500.png').then((data) => data.default),
  505: async () => import('../../assets/images/errors/505.png').then((data) => data.default),
};

function Error({ status, title = defaultTitle, message = defaultMessage, buttons }: ErrorProps) {
  const navigate = useNavigate();
  const [image, setImage] = useState<string | null>(null);

  useEffect(() => {
    datadogRum.addAction('error-boundary', {
      status,
      title,
      message,
    });
  }, [message, status, title]);

  useEffect(() => {
    const loadImage = async () => {
      let img;
      if (!status) {
        img = await imagePaths.generic();
      } else {
        const statusString = status.toString();
        if (Object.keys(imagePaths).includes(statusString)) {
          img = await imagePaths[statusString]();
        } else {
          img = await imagePaths.generic();
        }
      }
      setImage(img);
    };
    loadImage();
  }, [status]);

  const reloadPage = () => {
    navigate(0);
  };

  return (
    <CssVarsProvider theme={alternaleafTheme}>
      <Main>
        {image && (
          <ImageContainer>
            <img src={image} alt="" />
          </ImageContainer>
        )}
        <Content>
          <Text variant="h5" lineHeight="2.13rem" fontFamily="Mier B" fontWeight="700">
            {title}
          </Text>
          <Text variant="body2" fontFamily="Mier B" fontSize="1.067rem">
            {message}
          </Text>
          <ButtonContainer spacing={1}>
            {buttons || (
              <>
                <Button variant="contained" onClick={reloadPage}>
                  Reload page
                </Button>
                <Button variant="outlined" href={settings.support.site.url}>
                  Contact support
                </Button>
              </>
            )}
          </ButtonContainer>
        </Content>
      </Main>
    </CssVarsProvider>
  );
}

export default Error;
