import { Box, Divider, styled, Typography, useMediaQuery, useTheme } from '@mui/material';

import settings from '@/constants/constants';
import { OrderDetails, OrderDetailsProducts } from '@/hooks/order/useGetOrderDetails';

const StyledTypography = styled(Typography)(({ theme }) => ({
  marginBottom: theme.spacing(1),
}));

const OrderBreakdownContainer = styled(Box)(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    backgroundColor: '#EBEEEF', // todo: confirm the semantic color for this hexcode
    padding: theme.spacing(5),
    borderRadius: theme.spacing(2),
  },
}));

const ProductsContainer = styled(Box)(({ theme }) => ({
  marginTop: theme.spacing(2),
}));

const FeesContainer = styled(Box)(({ theme }) => ({
  marginBottom: theme.spacing(2),
  marginTop: theme.spacing(2),
}));

const ProductItemContainer = styled(Box)(({ theme }) => ({
  marginBottom: theme.spacing(2),
}));

const TotalContainer = styled(Box)(({ theme }) => ({
  marginBottom: theme.spacing(2),
  marginTop: theme.spacing(2),
}));

const RefundContainer = styled(Box)(({ theme }) => ({
  marginBottom: theme.spacing(2),
  marginTop: theme.spacing(2),
}));

function orderPriceText(price: string | number) {
  return Number(price).toFixed(2);
}

function orderTotal(dispensingFee: number | undefined, lineItemsTotalPrice: number) {
  return `$${
    dispensingFee && lineItemsTotalPrice > 0
      ? orderPriceText(lineItemsTotalPrice + dispensingFee)
      : orderPriceText(lineItemsTotalPrice)
  }`;
}

export type OrderBreakdownProps = {
  dispensingFee?: number;
  orderCancelled?: boolean;
  lineItems: OrderDetailsProducts[];
  cancelledLineItems: OrderDetailsProducts[];
  lineItemsTotalPrice: number;
  refundedAmount: number;
  totalPrice: number;
};

function getSplitOrderProps(
  orderBreakdownDetails: OrderDetails[],
  dispensingFee?: number,
  orderCancelled = false,
): OrderBreakdownProps {
  const lineItems: OrderDetailsProducts[] = [];
  const cancelledLineItems: OrderDetailsProducts[] = [];

  orderBreakdownDetails.forEach((order) => {
    if (!order.OrderProducts) return;
    if (
      order.order_status_id === settings.orderStatus.CANCELLED ||
      order.order_status_id === settings.orderStatus.REFUNDED
    ) {
      cancelledLineItems.push(...order.OrderProducts);
    } else {
      lineItems.push(...order.OrderProducts);
    }
  });

  const allSplitOrdersCancelled = orderBreakdownDetails.every(
    (order) =>
      order.order_status_id === settings.orderStatus.CANCELLED ||
      order.order_status_id === settings.orderStatus.REFUNDED,
  );

  const baseOrderTotalPrice = Number(orderBreakdownDetails[0].total_price ?? 0);

  // if all split orders are cancelled, the refund amount will be the total base price
  const refundedAmount = allSplitOrdersCancelled
    ? baseOrderTotalPrice
    : cancelledLineItems.reduce((total, product) => total + Number(product.price ?? 0), 0);

  return {
    totalPrice: baseOrderTotalPrice,
    lineItems,
    cancelledLineItems,
    lineItemsTotalPrice: lineItems.reduce((total, product) => total + Number(product.price ?? 0), 0),
    refundedAmount,
    orderCancelled,
    dispensingFee,
  };
}

export const getOrderBreakdownProps = ({
  dispensingFee,
  orderDetails,
  orderCancelled,
  splitOrder = false,
}: {
  dispensingFee?: number;
  orderDetails: OrderDetails[];
  orderCancelled?: boolean;
  splitOrder?: boolean;
}): OrderBreakdownProps => {
  if (splitOrder && orderDetails.length > 1) {
    return getSplitOrderProps(orderDetails, dispensingFee, orderCancelled);
  }

  const order = orderDetails[0];

  const {
    OrderProducts = [],
    total_price = 0, // eslint-disable-line camelcase
  } = order;

  const totalPrice = Number(total_price) || 0;

  return {
    lineItems: orderCancelled ? [] : OrderProducts,
    cancelledLineItems: orderCancelled ? OrderProducts : [],
    totalPrice,
    refundedAmount: orderCancelled ? totalPrice : 0,
    lineItemsTotalPrice: OrderProducts.reduce((total, product) => total + Number(product.price ?? 0), 0),
    orderCancelled,
    dispensingFee,
  };
};

export function OrderBreakdown({
  dispensingFee,
  orderCancelled,
  lineItems,
  cancelledLineItems,
  lineItemsTotalPrice,
  refundedAmount,
  totalPrice,
}: OrderBreakdownProps) {
  const theme = useTheme();
  const isLargeView = useMediaQuery(theme.breakpoints.up('md'));

  return (
    <OrderBreakdownContainer>
      <Typography
        variant="subtitle2"
        fontWeight="bold"
        lineHeight={isLargeView ? 1.3 : 1.6}
        sx={{ marginBottom: isLargeView ? 3 : 2 }}
      >
        Items
      </Typography>
      <ProductsContainer>
        {lineItems.length > 0 &&
          lineItems.map(({ id, Product, quantity, price }) => (
            <ProductItemContainer key={id}>
              <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                <StyledTypography
                  variant="body2"
                  sx={{
                    maxWidth: {
                      xs: '200px',
                      sm: 'none',
                    },
                  }}
                >
                  {Product.name}
                </StyledTypography>
                <StyledTypography variant="body2" fontWeight="bold">
                  ${orderPriceText(price ?? '0')}
                </StyledTypography>
              </Box>
              <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                <StyledTypography variant="body2">Qty: {quantity}</StyledTypography>
              </Box>
            </ProductItemContainer>
          ))}

        {cancelledLineItems.length > 0 &&
          cancelledLineItems.map(({ id, Product, quantity, price }) => (
            <ProductItemContainer key={id}>
              <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                <StyledTypography
                  variant="body2"
                  sx={{
                    maxWidth: {
                      xs: '200px',
                      sm: 'none',
                    },
                  }}
                >
                  {Product.name}
                </StyledTypography>
                <StyledTypography variant="body2" fontWeight="bold">
                  -${orderPriceText(price ?? '0')}
                </StyledTypography>
              </Box>
              <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                <StyledTypography variant="body2">Qty: {quantity}</StyledTypography>
              </Box>
            </ProductItemContainer>
          ))}
      </ProductsContainer>
      <Divider />
      {dispensingFee ? (
        <FeesContainer>
          <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <StyledTypography variant="body2">Shipping fee</StyledTypography>
            <StyledTypography variant="body2" fontWeight="bold">
              {orderCancelled ? `-$${orderPriceText(dispensingFee)}` : `$${orderPriceText(dispensingFee)}`}
            </StyledTypography>
          </Box>
          <Box>
            <Typography variant="body2">(inclusive of GST)</Typography>
          </Box>
        </FeesContainer>
      ) : null}

      <Divider />
      {orderCancelled || refundedAmount ? (
        <>
          <RefundContainer>
            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
              <Typography variant="body2">Refund</Typography>
              <Typography variant="body2" fontWeight="bold">
                {orderCancelled ? `-$${orderPriceText(totalPrice)}` : `-$${orderPriceText(refundedAmount)}`}
              </Typography>
            </Box>
          </RefundContainer>
          <Divider />
        </>
      ) : null}
      <TotalContainer>
        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <Typography variant="subtitle1">Total</Typography>
          <Typography variant="subtitle1" fontWeight="bold">
            {orderCancelled ? 0 : orderTotal(dispensingFee, lineItemsTotalPrice)}
          </Typography>
        </Box>
      </TotalContainer>
    </OrderBreakdownContainer>
  );
}

export default OrderBreakdown;
