import React, { useEffect, useRef, useState } from 'react';
import { NavLink } from 'react-router-dom';
import { Box, List, ListItem, ListItemText, styled, Typography, useMediaQuery, useTheme } from '@mui/material';

import { AuthService } from '../../../services/authentication.service';

interface MoreMenuProps {
  moreMenu: { id: string | number; name: string; link: string }[];
  menuLength: number;
}

const capitalizeFirstLetter = (text: string) => text.charAt(0).toUpperCase() + text.slice(1).toLowerCase();

const MoreMenuContainer = styled(Box)(({ theme }) => ({
  paddingLeft: theme.spacing(4),
  backgroundColor: theme.palette.primary.main,
  position: 'relative',
}));

const MoreMenuItem = styled(Box)(({ theme }) => ({
  paddingLeft: theme.spacing(2),
  listStyle: 'none',
  backgroundColor: theme.palette.primary.main,
}));

const MoreMenuList = styled(List)(({ theme }) => ({
  position: 'absolute',
  backgroundColor: theme.palette.primary.main,
  zIndex: 1,
  width: '75%',
  [theme.breakpoints.up('md')]: {
    width: '16rem',
  },
}));

function MoreMenuV2({ moreMenu }: MoreMenuProps) {
  const [showMoreItem, setShowMoreItem] = useState(false);

  const theme = useTheme();
  const isMobileView = useMediaQuery(theme.breakpoints.down('md'));
  const moreMenuRef = useRef<HTMLDivElement>(null);

  const toggleMoreMenu = () => {
    setShowMoreItem((prev) => !prev);
  };

  const handleMenuMouseEnter = () => {
    if (!isMobileView) {
      setShowMoreItem(true);
    }
  };

  const handleMenuMouseLeave = (event: React.MouseEvent) => {
    const relatedTarget = event.relatedTarget as Node;
    if (moreMenuRef.current && !moreMenuRef.current.contains(relatedTarget)) {
      setShowMoreItem(false);
    }
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (moreMenuRef.current && !moreMenuRef.current.contains(event.target as Node)) {
        setShowMoreItem(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <MoreMenuContainer ref={moreMenuRef} onMouseEnter={handleMenuMouseEnter} onMouseLeave={handleMenuMouseLeave}>
      {AuthService.isAdmin() && (
        <MoreMenuItem title="More">
          <Typography
            {...(isMobileView ? { onClick: toggleMoreMenu } : {})}
            sx={{
              cursor: 'pointer',
              color: 'primary.contrastText',
            }}
          >
            More
          </Typography>
          {showMoreItem && (
            <MoreMenuList>
              {moreMenu.map((menu) => (
                <ListItem key={`sub-menu-${menu.id}`} component={NavLink} to={menu.link}>
                  <ListItemText primary={capitalizeFirstLetter(menu.name)} sx={{ color: 'primary.contrastText' }} />
                </ListItem>
              ))}
            </MoreMenuList>
          )}
        </MoreMenuItem>
      )}
    </MoreMenuContainer>
  );
}

export default MoreMenuV2;
